import { Component, OnInit } from "@angular/core";
import { PosBackOfficeService } from "../providers/pos-back-office.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from "@angular/forms";
import { LocalStorageService } from 'ngx-store';


@Component({
  selector: "app-menu-master",
  templateUrl: "./menu-master.component.html",
  styleUrls: ["./menu-master.component.scss"]
})
export class MenuMasterComponent implements OnInit {
  menuName: any;
  textAlign: string;
  constructor(private fb: FormBuilder, public service: PosBackOfficeService, public ls: LocalStorageService) { }
  groupSelected: Boolean = false;
  buttonClick: Boolean = true;
  i: Number;
  selectMenuItemGroup: boolean;
  menuItemMaster: FormGroup;
  menuItemMasterModal: FormGroup;
  showDetails: Boolean;
  showMenuName: Boolean;
  menuGroupArray: any;
  showItemGroup: Boolean;
  showItem: Boolean;
  menuItemGroupModal: FormGroup;
  showGroup: Boolean = false;
  brandData = [];
  branchData = [];
  showItemDetails: boolean;
  showDetails1: boolean;
  menuItemGroups: [];
  categories: [];
  formSubmitAttempt: Boolean;
  menuDependencies: any = {};

  MenuList = [];

  indexOfMenu = 0;

  listOfGroup = [];

  categoryId;
  categoryName = "";

  ngOnInit() {
    this.getMenu();
    this.menuCRUD("get");
    this.categoriesCRUD("get");
    this.menuItemGroupCRUD("get");
    this.menuItemMaster = this.fb.group({
      brand_name: ["", Validators.required],
      branch_id: [""],
      menuGroup: [""],
      take_out: [false],
      car_service: [false],
      staff_meal: [false],
      dine_in: [false],
      catering: [false]
    });
    this.menuItemGroupModal = this.fb.group({
      brand_id: [""],
      menu_name: [""],
      menu_price: [0],
      category_id: [""],
      menuItemGroup: "",
      menu_group_ids: this.fb.array([])
      // array1: this.fb.array([])
    });
    this.inputAlignment();
  }

  inputAlignment() {
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  binding(data) {
    this.menuItemGroupModal.patchValue({
      brand_id: data.brand_id
    });
  }
  addGroup() {
    if (
      this.menuItemGroupModal.value.menuItemGroup !== "" &&
      this.menuItemGroupModal.value.menuItemGroup !== null
    ) {
      console.log("patchValue", this.menuItemGroupModal.value.menuItemGroup);
      if (this.menuItemGroupModal.value.menuItemGroup === "item") {
        (<FormArray>this.menuItemGroupModal.get("array1")).push(
          this.controls()
        );
        this.showItemGroup = false;
        this.showItem = true;
      } else {
        (<FormArray>this.menuItemGroupModal.get("menu_group_ids")).push(
          this.controls()
        );
        this.showItemGroup = true;
        this.showItem = false;
      }
      this.popUpOff1();
      this.showGroup = true;
    } else {
      this.service.openSnackBar("Please select menu item group");
    }
  }

  controls(): FormGroup {
    return this.fb.group({
      menu_group_id: [""]

    });
  }

  menuItemGroup(value) {
    this.menuGroupArray = value;
    this.showGroup = true;
  }

  popUp() {
    document.getElementById("id01").style.display = "block";
  }

  popUpOff() {
    document.getElementById("id01").style.display = "none";
  }

  popUp1() {
    document.getElementById("id02").style.display = "block";
  }

  popUpOff1() {
    document.getElementById("id02").style.display = "none";
  }

  addItems() {
    console.log(this.menuItemGroupModal.value.menu_group_ids.length);
    // this.menuItemGroupModal.value.menu_group_ids.length

    // this.menuItemGroupModal.get('menu_price').disable()
    this.selectMenuItemGroup = true;
    this.popUp1();
  }

  viewDetails(menu, index) {
    console.log("menu", menu)
    this.menuName = menu.menu_name;
    this.indexOfMenu = index;
    this.listOfGroup = [];

    const id = this.MenuList[this.indexOfMenu].menu_id;

    this.service.Menu("get", "", `?id=${id}&menuGroup=true`).subscribe(data => {
      this.listOfGroup = data.body;
    });

    this.showItemDetails = true;
  }

  viewItemDetails() {
    this.showDetails1 = true;
  }

  addMenu() {
    if (this.showMenuName) {
      this.popUp();
    } else {
      this.service.openSnackBar("Please Select Branch");
    }
  }
  getMenu() {
    this.service.Menu("get").subscribe(
      result => {
        this.MenuList = result.body;
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  categoryAdd(category) {
    this.categoryId = category.category_id;
    this.categoryName = category.category_name;
  }

  createMenuData() {
    if (
      this.menuItemGroupModal.value.menu_price === 0 ||
      this.menuItemGroupModal.value.menu_price == null
    ) {
      if (this.groupSelected === false) {
        return this.service.openSnackBar("Select menu price or group details");
      }
    }
    const i = this.menuItemGroupModal.value.menu_price;

    if (i && this.menuItemGroupModal.value.menu_name) {
      this.buttonClick = false;
      return this.createMenu();
    }

    if (
      this.menuItemGroupModal.value.brand_id !== "" &&
      this.menuItemGroupModal.value.brand_id !== null
    ) {
      if (
        this.menuItemGroupModal.value.category_id !== "" &&
        this.menuItemGroupModal.value.category_id !== null
      ) {
        if (
          this.menuItemGroupModal.value.menu_name !== "" &&
          this.menuItemGroupModal.value.menu_name !== null
        ) {
          if (this.selectMenuItemGroup === true) {
            this.buttonClick = false;
            return this.createMenu();
          } else {
            this.service.openSnackBar("Please add menu item group");
          }
        } else {
          this.service.openSnackBar("Please enter menu name");
        }
      } else {
        this.service.openSnackBar("Please select category name");
      }
    } else {
      this.service.openSnackBar("Please select brand name");
    }
  }
  async createMenu() {
    delete this.menuItemGroupModal.value.menuItemGroup;

    const menu_group_ids = [];

    this.menuItemGroupModal.value.menu_group_ids.map(result => {
      menu_group_ids.push(result.menu_group_id);
    });

    this.menuItemGroupModal.value.menu_group_ids = menu_group_ids;

    const categoryUpdate: any = this.menuDependencies;

    this.menuDependencies = {
      ...this.menuDependencies,
      ...this.menuItemGroupModal.value
    };
    this.menuDependencies.category_name = this.categoryName;

    delete this.menuDependencies.category_id;

    this.service.menuCategory("post", this.menuDependencies).subscribe(
      resultParent => {
        const get: any = resultParent.body.menu_id;
        this.menuDependencies.category_id = resultParent.body.category_id;

        this.service.Menu("post", this.menuDependencies).subscribe(result => {
          this.getMenu();
          this.createMenu1();
          const menu_id = result.body.menu_id;

          get.push(menu_id);
          categoryUpdate.category_id = this.menuDependencies.category_id;
          categoryUpdate.menu_id = get;
          categoryUpdate.branch_id = this.menuDependencies.branch_id;

          this.service
            .menuCategory("put", categoryUpdate)
            .subscribe(updateData => {
              this.service.openSnackBar("Menu created");
              this.popUpOff();
              this.menuItemMaster.reset();
              this.branchData = [];
              this.buttonClick = true;
            });
        });
      },
      error => {
        this.buttonClick = true;
      }
    );
  }
  createMenu1() {
    this.branchData = [];
    this.menuItemMaster.reset();
    this.menuItemGroupModal.reset();
    this.menuItemGroupModal.setControl("menu_group_ids", this.fb.array([]));
  }
  async menuItemGroupCRUD(rule, payload = "", query = "") {
    this.service.MenuItemGroup(rule, payload, query).subscribe(
      result => {
        if (rule === "get") {
          this.menuItemGroups = result.body;
        }
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  async categoriesCRUD(rule, payload = "", query = "") {
    this.service.menuCategoryCatalog(rule, payload, query).subscribe(
      result => {
        if (rule === "get") {
          this.categories = result.body;
        }
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  async menuCRUD(rule, payload = "", query = "", index = 1) {
    this.service.brands(rule, payload, query).subscribe(
      result => {
        if (rule === "get") {
          this.brandData = result.body;
        }
        if (rule === "get1") {
          this.branchData = result.body;
        }
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  reset() {
    this.menuItemMaster.patchValue({
      catering: false,
      staff_meal: false,
      dine_in: false,
      car_service: false,
      take_out: false
    });
  }

  match(data) {
    this.showMenuName = true;
    const {
      branch_id = false,
      take_out = false,
      dine_in = false,
      car_service = false,
      catering = false,
      staff_meal = false
    } = data;
    this.menuDependencies = {
      branch_id,
      take_out,
      dine_in,
      car_service,
      catering,
      staff_meal
    };
    this.menuItemMaster.patchValue({
      branch_id: data.branch_id,
      take_out: data.take_out,
      dine_in: data.dine_in,
      car_service: data.car_service,
      catering: data.catering,
      staff_meal: data.staff_meal
    });
  }
  updateBranchData() {
    this.service
      .updateBranchDetails(this.menuItemMaster.value)
      .subscribe(result => { });
  }

  isFieldValid(field: string) {
    return (
      (!this.menuItemMaster.get(field).valid &&
        this.menuItemMaster.get(field).touched) ||
      (this.menuItemMaster.get(field).untouched && this.formSubmitAttempt)
    );
  }
  selected() {
    this.groupSelected = true;
    this.menuItemGroupModal.patchValue({
      menu_price: +"0"
    });
  }
}
