import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
@Component({
  selector: 'app-inventory-category',
  templateUrl: './inventory-category.component.html',
  styleUrls: ['./inventory-category.component.scss']
})
export class InventoryCategoryComponent implements OnInit {
  inventoryCategory: FormGroup;
  inventoryCategoryEdit: FormGroup;
  brandData: any;
  editInvCategoryData: any;
  formSubmitted: Boolean;
  invCategory: any;
  dataNotFound: Boolean;
  p: any;
  categoryID: any;
  pT6: string;
  expectBrand: Boolean;
  categoryId: any;
  brandId: any;
  showPagination: boolean;
  constructor(private fb: FormBuilder, public service: PosBackOfficeService, public ls: LocalStorageService) {}

  ngOnInit() {
    this.inventoryCategory = this.fb.group({
      brand_id: ['', Validators.required],
      category_name_english: ['', Validators.required],
      category_name_arabic: ['', Validators.required],
      category_code: ['', [Validators.required, Validators.pattern('^[A-Z]{1,50}$')]]
    });
    this.inventoryCategoryEdit = this.fb.group({
      brand_id: [''],
      category_id: [''],
      category_name_english: ['', Validators.required],
      category_name_arabic: ['', Validators.required],
      category_code: ['', [Validators.required, Validators.pattern('^[A-Z]{1,50}$')]]
    });
    this.getBrandDetails();
    this.getInventCategory('');
  }
  patchman1(){
    const one =this.inventoryCategory.get('category_name_english').value;
    this.inventoryCategory.patchValue({'category_name_english':one.replace(/^ +/gm, '')});
  }
  getBrandDetails() {
    this.service.getBrandDetails().subscribe(result => {
      this.brandData = result.body.sort(function(a, b) {
        return a.brand_id - b.brand_id;
      });
    });
  }

  saveData() {
    const supplierArray = [];
    this.expectBrand = false;
    this.invCategory.forEach(element => {
      element.data.forEach(element => {
        if (element.brand_id === this.inventoryCategory.value.brand_id) {
          this.expectBrand = true;
        }
      });
    });
    if (this.expectBrand === true) {
      this.invCategory.forEach(element => {
        element.data.forEach(data => {
          if (data.brand_id === this.inventoryCategory.value.brand_id) {
            supplierArray.push(data);
          }
        });
      });
    } else {
      this.saveInventoryCategoryData();
      return;
    }

      if (!supplierArray.some(r => (r.category_name_english).toUpperCase().replace(/\s/g, '')
      == (this.inventoryCategory.value.category_name_english).toUpperCase().replace(/\s/g, '') )) {
        if (!supplierArray.some(r => (r.category_name_arabic).toUpperCase().replace(/\s/g, '')
        == (this.inventoryCategory.value.category_name_arabic).toUpperCase().replace(/\s/g, '') )) {
          if (!supplierArray.some(r => (r.category_code).toUpperCase().replace(/\s/g, '')
           == (this.inventoryCategory.value.category_code).toUpperCase().replace(/\s/g, '') )) {
            this.saveInventoryCategoryData();
            } else {
              this.service.openSnackBar('Category code  already exists!');
            }
          } else {
            this.service.openSnackBar('Category arabic name  already exists!');
          }
        } else {
          this.service.openSnackBar('Category name  already exists!');
        }
  }

  saveInventoryCategoryData(){
    const brandID = this.inventoryCategory.value.brand_id;
    this.service.saveCategoryInventory(this.inventoryCategory.value).subscribe(res =>{
      this.getInventCategory('');
      this.service.openSnackBar('Data Added!');
      this.dataNotFound = false;
      this.inventoryCategory.reset();
      this.inventoryCategory.patchValue({brand_id: brandID});
      }, error => {
        console.log(error);
        this.service.openSnackBar(error.error.message);
      });
  }

  getInventCategory(query) {
    this.service.getCategoryInventory(`?id=${query}`).subscribe(
      res => {
        this.invCategory = res.body;
        if (this.invCategory.length > 10) {
          this.showPagination = true;
        } else {
          this.showPagination = false;
        }
        this.categoryID = '';
      },
      error => {
        console.log(error);
      }
    );
  }

  editInvCategory(data) {
    this.editInvCategoryData = data;
    this.categoryID = data.category_id;
    this.brandId = data.brand_id;
    this.pT6 = 'pt6';
    this.inventoryCategoryEdit.patchValue(data);
  }

  updateCategory(payload) {
   const supplierArrayUpdate = [];
    if((this.editInvCategoryData.category_name_english).toUpperCase() 
    == (payload.category_name_english).toUpperCase() 
      && this.editInvCategoryData.category_name_arabic == payload.category_name_arabic) {
        this.updateInventCategoryData(payload);
        return;
      } else {
        this.invCategory.forEach(element => {
          element.data.forEach(data => {
            if (data.brand_id === payload.brand_id) {
              if (data.category_id !== payload.category_id) {
                supplierArrayUpdate.push(data);
              }
            }
          });
        });
      }
      if (supplierArrayUpdate.length > 0) {
        if (!supplierArrayUpdate.some(r => (r.category_name_english).toUpperCase().replace(/\s/g, '')
         === (payload.category_name_english).toUpperCase().replace(/\s/g, ''))) {
            if (!supplierArrayUpdate.some(r => (r.category_name_arabic).toUpperCase().replace(/\s/g, '')
             === (payload.category_name_arabic).toUpperCase().replace(/\s/g, '') )) {
              if (!supplierArrayUpdate.some(r => (r.category_code).toUpperCase().replace(/\s/g, '')
               == (payload.category_code).toUpperCase().replace(/\s/g, '') )) {
                this.updateInventCategoryData(payload);
                } else {
                  this.service.openSnackBar('Category code  already exists!');
                }
              } else {
                this.service.openSnackBar('Category arabic name  already exists!');
              }

          } else {
            this.service.openSnackBar('Category name already exists!');
          }
      } else {
        this.updateInventCategoryData(payload);
      }
  }

  updateInventCategoryData(payload){
    this.service.updateCategoryInventory(payload).subscribe(res => {
      this.categoryID = '';
      this.getInventCategory('');
      this.service.openSnackBar('Data Updated!');
    }, error => {
      console.log(error);
    });
  }

  deleteCategory(data) {
    this.categoryId = data.category_id;
    this.brandId = data.brand_id;
    this.popUp();
  }

  deleteCategoryModal() {
    this.service
      .deleteCategoryInventory(`?category_id=${this.categoryId}&brand_id=${this.brandId}`)
      .subscribe(
        res => {
          this.getInventCategory('');
          this.service.openSnackBar('Data Deleted!');
          this.popUpOff();
        },
        error => {
          this.service.openSnackBar(error.error.message);
        }
      );
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  isFieldValid(field: string) {
    return (
      (!this.inventoryCategory.get(field).valid && this.inventoryCategory.get(field).touched) ||
      (this.inventoryCategory.get(field).untouched && this.formSubmitted)
    );
  }
}
