import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { MatDatepickerInputEvent } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
import { empty } from 'rxjs';
@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.scss']
})
export class SalesReportComponent implements OnInit {
  @ViewChild('myCanvas')
  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;
  public chartType = 'line';
  public chartData: any = [];
  public chartLabels: any = ['19/08', '20/08', '21/08', '22/08', '23/08'];
  public chartColors: any = [];
  public finalChartData: any = [];
  brands: any;
  branches: any;
  salesReport: FormGroup;
  enableBranch: Boolean = false;
  showReport: Boolean = false;
  defaultQuery;
  saleData: any;
  showAvailable = true;
  roleList = [{ name: 'Waiter' }, { name: 'Cashier' }];
  user;
  userList;
  carService;
  maxToDate = new Date();
  maxFromDate = new Date();
  minToDate: any;
  catering;
  takeOut;
  dineIn;
  staffMeal;
  showGroupDropDown: Boolean = false;
  groupTotal;
  groupedData;
  showGroup;
  fromdate;
  todate;
  tableTileParam;
  permenantSaleData: any;
  fullQuery;
  delivery: any;
  tableTileData: string;
  totalAmount: number = 0;
  totalDiscount: number = 0;
  totalNet: number = 0;
  finalSaleData: any;
  r1: any;
  onlineresorce: any[];
  queryParams = {
    brandId: 0,
    branchId: 0,
    segment: 0,
    userName: 0,
    onlineType: 0,
  };
  constructor(
    public service: PosBackOfficeService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private adapter: DateAdapter<any>,
    public ls: LocalStorageService
  ) { }

  ngOnInit() {
    this.salesReport = this.fb.group({
      brand: '',
      branch: '',
      fromDate: [new Date().toISOString().slice(0, 10)],
      toDate: [new Date().toISOString().slice(0, 10)],
      userReport: [''],
      onlineOrder: [''],
      segmentReport: ['']
    });
    this.dateAdapter();
    this.getBrand();
    this.chartData = [{ data: [65, 59, 80, 81, 56, 55, 40], label: 'Sales', fill: false }];

    this.chartColors = [
      {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderColor: ' gray'
      }
    ];
    this.dateValidation();
    this.onlineSourceApi();
    this.sortDate('fromdate');
    this.showGroupDropDown = false;

  }

  dateAdapter() {
    this.adapter.setLocale('en-GB');
  }

  getBranch(brand) {
    this.salesReport.patchValue({
      branch: ''
    });
    this.enableBranch = true;
    this.service.Branches(`?brand_id=${brand}`).subscribe(r => {
      this.branches = r.body;

    });
    this.sortDate('brand');
  }

  getBrand() {
    this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;

      },
      error => {
        console.log('error', error);
      }
    );
  }

  getSegments() {
    this.service.getBranchDetails(`?id=${this.salesReport.value.branch}${this.salesReport.value.branch=='null'?'&brand='+this.salesReport.value.brand:''}`).subscribe(r => {
      this.carService = r.body[0].car_service;
      this.catering = r.body[0].catering;
      this.takeOut = r.body[0].take_out;
      this.dineIn = r.body[0].dine_in;
      this.staffMeal = r.body[0].staff_meagroupDatal;
      this.delivery = r.body[0].delivery;
    });
  }
  onlineSourceApi() {
    let abc = [];
    this.service.onlineorder().subscribe(res => {
      console.log(res.body.onlinetypes)
      res.body.onlinetypes.forEach(ele => {
        console.log(ele);
        if (ele.online_status == true) {
          abc.push(ele)
        }
      })
      this.onlineresorce = abc;
    });
  }

  getUserDetails(a) {
    this.user = 'manager';
    this.service
      .getUserListForManager(
        `?role=${this.user}&branch_id=${this.salesReport.value.branch}&brand_id=${this.salesReport.value.brand}`
      )
      .subscribe(res => {
        this.finalSaleData = [];
        this.userList = res;
        this.finalSaleData = this.saleData;
        // this.userList.map(e => {
        //   this.saleData.map(r => {
        //     // console.log("r.user_name=>",r.user_name)
        //     // console.log("e.userName=>",e.userName)
        //     if (r.user_id == e._id) {
        //       console.table[r.user_id, r.user_name]
        //       console.table[e._id, e.userName]
        //       r.user_name = e.userName;
        //     } else {
        //       r.user_name ='NA';
        //     }
        //   });
        // });
      });
  }

  groupData(data) {
    this.tableTileParam = data;
    if (data == 'transaction_date') {
      this.tableTileData = 'Date';
    } else {
      this.tableTileData = 'User';
    }
    this.sortDate(data,'group');
    const groupUser = this.salesReport.value.userReport || 'null';
    const groupSegment = this.salesReport.value.segmentReport || 'null';
    const queryBranch = this.salesReport.value.branch || 'null';
    this.service
      .reportData(
        `?groupBy=${data}&brand_id=${this.salesReport.value.brand}&branch_id=${queryBranch}&fromDate=${this.fromdate}&toDate=${this.todate}&segment=${groupSegment}&user=${groupUser}`
      )
      .subscribe(r => {
        this.groupedData = r.body.groups;
        this.userList.forEach(element => {
          this.groupedData.map(r => {
            if (r.group_id == element._id) {
              r.group = element.userName;
            }
          });
        });
        this.groupTotal = {
          dineIn: r.body.dine_in_total,
          takeOut: r.body.take_out_total,
          carService: r.body.car_service_total,
          staffMeal: r.body.staff_meal_total,
          catering: r.body.catering_total,
          delivery: r.body.delivery_total,
          dineInCnt: r.body.dine_in_count,
          takeOutCnt: r.body.take_out_count,
          carServiceCnt: r.body.car_service_count,
          staffMealCnt: r.body.staff_meal_count,
          cateringCnt: r.body.catering_count,
          deliveryCnt: r.body.deliver_count
        };

        this.showGroup = true;
      });
  }

  dateValidation() {
    this.minToDate = this.salesReport.value.fromDate;
    this.maxFromDate = this.salesReport.value.toDate;
  }

  sortDate(value,group?) {
    console.log("value", value);

    this.dateValidation();
    // this.showGroup = false;
    this.fromdate =
      this.datePipe.transform(this.salesReport.value.fromDate, 'yyyy-MM-dd') ||
      this.datePipe.transform(this.salesReport.value.toDate, 'yyyy-MM-dd') ||
      '';
    this.todate =
      this.datePipe.transform(this.salesReport.value.toDate, 'yyyy-MM-dd') || this.fromdate || '';
    let query = '?'
    const queryUser = this.salesReport.value.userReport || 'null';
    const queryonlineOrder = this.salesReport.value.onlineOrder || 'null';
    const querySegment = this.salesReport.value.segmentReport || 'null';
    const queryBranch = this.salesReport.value.branch || 'null';
    console.log("brand", this.salesReport.value)
    if (value == 'brand' || this.salesReport.value.brand) {
      this.queryParams.brandId = 1;
      query = query + `filterbyBrand=${this.queryParams.brandId !== 0 ? 1 : 0}&brandId=${this.salesReport.value.brand}&`
    }
    if ((value == 'branch' || this.salesReport.value.branch)&&(this.salesReport.value.branch != "null")) {
      this.queryParams.branchId = 1;
      query = query + `filterbyBranch=${this.queryParams.branchId != 0 ? 1 : 0}&branchId=${queryBranch}&`
    }else{
      this.queryParams.branchId = 0;
      query = query + `filterbyBranch=${this.queryParams.branchId != 0 ? 1 : 0}&branchId=${queryBranch}&`
    }
    if ((value == 'segment' || this.salesReport.value.segmentReport)&&(this.salesReport.value.segmentReport != "null")) {
      this.queryParams.segment = 1;
      query = query + `filterbySegment=${this.queryParams.segment != 0 ? 1 : 0}&segment=${querySegment}&`
    }else{
      this.queryParams.segment = 0;
      query = query + `filterbySegment=${this.queryParams.segment != 0 ? 1 : 0}&segment=${querySegment}&`
    }
    if ((value == 'user' || this.salesReport.value.userReport)&&(this.salesReport.value.userReport != "null")) {
      this.queryParams.userName = 1;
      query = query + `filterbyUserName=${this.queryParams.userName != 0 ? 1 : 0}&userName=${queryUser}&`
    }
    else{
        this.queryParams.userName = 0;
        query = query + `filterbyUserName=${this.queryParams.userName != 0 ? 1 : 0}&userName=${queryUser}&`
    }
    if ((value == 'onlinetype' || this.salesReport.value.onlineOrder)&&(this.salesReport.value.onlineOrder != "null")) {
      this.queryParams.onlineType = 1;
      query = query + `filterbyOnlineType=${this.queryParams.onlineType}&onlineType=${queryonlineOrder}&`
    }else{
      this.queryParams.onlineType = 0;
      query = query + `filterbyOnlineType=${this.queryParams.onlineType}&onlineType=${queryonlineOrder}&`
    }
    if (value == 'fromdate' || value=='todate' || this.salesReport.value.fromDate && this.salesReport.value.toDate) {
      query = query + `fromDate=${this.fromdate}&toDate=${this.todate}&`
    }
    if(( this.salesReport.value.userReport == "")||(this.salesReport.value.segmentReport == "")){
          this.showGroupDropDown = false;
    }else{
      this.showGroupDropDown = true;

    }

    query = `${query}`
    console.log("query", query.slice(0, -1))
    this.fullQuery = query.slice(0, -1)

    const defaultQuery = `?brand_id=${this.salesReport.value.brand}&branch_id=${queryBranch}`;
    // this.fullQuery = `${defaultQuery}&sDate=${this.fromdate}&eDate=${this.todate}&user=${queryUser}&role=${queryRole}&segment=${querySegment}`;
    // this.fullQuery = `?filterbyBrand=${this.queryParams.brandId !== 0 ? 1 : 0}&brandId=${this.salesReport.value.brand}&filterbyBranch=${this.queryParams.branchId != 0 ? 1 : 0}&branchId=${queryBranch}&fromDate=${this.fromdate}&toDate=${this.todate}&filterbySegment=${this.queryParams.segment != 0 ? 1 : 0}&segment=${querySegment}&filterbyUserName=${this.queryParams.userName != 0 ? 1 : 0}&userName=${queryUser}&filterbyOnlineType=${this.queryParams.onlineType}&onlineType=${queryonlineOrder}`
    if(group!='group'){
      this.getReport();
      }
  }

  detailedReport(data) {
    this.saleData = this.permenantSaleData;
    if (this.tableTileParam == 'transaction_date') {
      let dateParts = data.split('/');
      let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      let transaction_date = this.datePipe.transform(dateObject, 'yyyy-MM-dd');
      this.saleData = this.saleData.filter(function (list) {
        return list.transaction_date == transaction_date + 'T00:00:00.000Z';
      });
    }
    if (this.tableTileParam == 'user_name') {
      this.saleData = this.saleData.filter(function (list) {
        return list.user_name == data;
      });
    }
    if (this.tableTileParam == 'payment_type') {
      if ((data = 'false')) {
        this.saleData = this.saleData.filter(function (list) {
          return list.payment_type == false;
        });
      } else {
        this.saleData = this.saleData.filter(function (list) {
          return list.payment_type == true;
        });
      }
    }
    this.findTotal(this.saleData);
  }

  getReport() {
    console.log('####################################### called')
    this.showAvailable = true;
        // this.saleData = [];
    this.service.getSalesData(this.fullQuery).subscribe(r1 => {
      // console.log("2nd",r1);
      // response=r1.body
      // let a={
      //   salesdata:response
      // }
      // console.log("$$$$$$$",a.salesdata)
      // console.log("2nd $$$$$$$",JSON.stringify(a.salesdata))
      this.totalAmount = 0;
      this.totalDiscount = 0;
      this.totalNet = 0;
      this.showReport = true;
      this.saleData = r1.body;
      console.log("this.saleData",this.saleData)
      // console.table([this.saleData.userName])
      if (r1.body.length > 0) {
        this.showAvailable = false;
        this.permenantSaleData = r1.body;
        this.findTotal(this.saleData);
      }
      this.getSegments();
      this.getUserDetails(this.saleData);
    });

  }

  getBranchName(data) {
    let returnBranch = 'NA';
    const queryBranch = this.branches.filter(res => res.branch_id == data);
    if (queryBranch.length !== 0) {
      returnBranch = queryBranch[0].branch_name;
    }
    return returnBranch;
  }

  findTotal(data) {
    this.totalAmount = data.reduce(function (cnt, o) {
      return cnt + o.total_cost;
    }, 0);
    this.totalDiscount = data.reduce(function (cnt, o) {
      return cnt + o.discount_amount;
    }, 0);
    this.totalNet = data.reduce(function (cnt, o) {
      return cnt + (o.total_cost - o.discount_amount);
    }, 0);
  }

  print(divId): void {
    let printContents, popupWin;
    printContents = document.getElementById(divId).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Sales Report</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }
}
