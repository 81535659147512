import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pos-daily-sales',
  templateUrl: './daily-sales.component.html',
  styleUrls: ['./daily-sales.component.scss']
})
export class DailySalesComponent implements OnInit {
  @Input() salesName;
  @Input() arabicName;
  @Input() dailySales;
  @Input() KD = "KD";
  @Input() customClass;
  constructor() { }

  ngOnInit() {
  }

}
