import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { LocalStorageService } from 'ngx-store';
import { PosBackOfficeService } from '../providers/pos-back-office.service';

@Component({
  selector: 'app-stock-report',
  templateUrl: './stock-report.component.html',
  styleUrls: ['./stock-report.component.scss']
})
export class StockReportComponent implements OnInit {
  brands: any;
  branches: any;
  stockReport: FormGroup;
  enableBranch;
  dates;
  stockdata:any;
  brandID:any;
  branchID:any;
  p:any;
  showPrint:boolean=false;
  systemDate:string;
  maxDate = new Date();
  maxFromDate = new Date();


   constructor(
    public service: PosBackOfficeService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private adapter: DateAdapter<any>,
    public ls: LocalStorageService
  ) {}

  ngOnInit() {
    this.stockReport = this.fb.group({
      brand: '',
      branch: '',
      date: [new Date().toISOString().slice(0, 10)],

    });
    this.getBrand();
    this.dateAdapter();
    this.systemDate = this.datePipe.transform(this.maxDate, 'yyyy-MM-dd HH:mm');

  }
 
  dateAdapter() {
    this.adapter.setLocale('en-GB');
  }
  
  getBranch(brand) {
    this.stockReport.patchValue({
      branch: ''
    });
    this.enableBranch = true;
    this.service.Branches(`?brand_id=${brand}`).subscribe(r => {
      this.branches = r.body;
    });
  }

  getBrand() {
    this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;
      },
      error => {
        console.log('error', error);
      }
    );
  }

  getStock() {
    this.dates = this.datePipe.transform(this.stockReport.value.date, 'yyyy-MM-dd');
    this.service.getStockReport(`?brand_id=${this.stockReport.value.brand}&branch_id=${this.stockReport.value.branch}&date=${this.dates}`).subscribe((res: any) => {
      this.stockdata = res.body;
      this.brandID = this.stockReport.value.brand;
      this.branchID = this.stockReport.value.branch;
    },
    error => {
      this.service.openSnackBar(error.error.message);
    });
  }  


  getBranchName(data) {
    let returnBranch = 'NA';
    const queryBranch = this.branches.filter(res => res.branch_id == data);
    if (queryBranch.length !== 0) {
      returnBranch = queryBranch[0].branch_name;
    }
    return returnBranch;
  }
  getBrandName(data) {
    let returnBrand = 'NA';
    const queryBrand = this.brands.filter(res => res.brand_id == data);
    if (queryBrand.length !== 0) {
      returnBrand = queryBrand[0].brand_name;
    }
    return returnBrand;
  }
  print() {
    setTimeout(() => {
      this.printReceipt('stock_print');
    }, 500);
    this.showPrint = true;
  }
  printReceipt(div): void {
    let printContents, popupWin;
    printContents = document.getElementById(div).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Sales Report</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

}