import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
@Component({
  selector: 'app-online-setup',
  templateUrl: './online-setup.component.html',
  styleUrls: ['./online-setup.component.scss']
})
export class OnlineSetupComponent implements OnInit {
  onlineSetUp: FormGroup;
  brands: any;
  branches: any;
  formAttemptSubmit: boolean;
  populateData: any;
  showOnlineData: boolean;
  showUpdateButton: boolean;
  branchID: any;
  disableInput: boolean;
  showType: boolean;
  onlinetype: any;
  onlinePaymentSource: FormGroup;
  data: any;
  branchId: any;
  p: number = 1;
  onlineSourcePayment: any;
  show: boolean;
  onlineId: any;
  onlinePayment: FormGroup;
  radioValue: any;
  constructor(private fb: FormBuilder, public service: PosBackOfficeService,
    public ls: LocalStorageService) { }

  ngOnInit() {
    this.onlineSetUp = this.fb.group({
      // brand_id: ['', Validators.required],
      // branch_id: ['', Validators.required],
      online_type: this.fb.array([]),
      // online_type_english: ['', Validators.required],
    });
    this.onlinePaymentSource = this.fb.group({
      onlineTypeEng: ['', Validators.required],
      paymentTypeEng: ['', Validators.required],
      paymentTypeArabic: ['', Validators.required]
    })
    this.onlinePayment = this.fb.group({
      paymentEnglish: [''],
      paymentArabic: [''],
    })
    this.getBrand();
    this.addOnlineData();
    this.getOnlineSetup();
    this.onlineSource()
  }
  patchman() {
    const one = this.onlineSetUp.get('brandName').value;
    this.onlineSetUp.patchValue({ 'brandName': one.replace(/^ +/gm, '') })
  }
  addOnlineData() {
    (<FormArray>this.onlineSetUp.get('online_type')).push(this.onlineList());
  }
  // addPaymentType() {
  //   (<FormArray>this.onlineSetUp.get('online_payment_type')).push(this.paymentList());
  //   console.log((<FormArray>this.onlineSetUp.get('online_payment_type')).length)
  // }
  onlineList() {
    return this.fb.group({
      online_type_english: ['', Validators.required],
      online_type_arabic: ['', Validators.required],
      online_status: [true]
    });
  }
  // paymentList() {
  //   return this.fb.group({
  //     payment_type_english: ['', Validators.required],
  //     payment_type_arabic: ['', Validators.required]
  //   });
  // }

  addOnlineDataEdit(data) {
    const control = new FormGroup({
      online_type_english: new FormControl(data.online_type_english, Validators.required),
      online_type_arabic: new FormControl(data.online_type_arabic, Validators.required),
      online_status: new FormControl(data.online_status),
      // brand_id: new FormControl(data.brand_id),
      // branch_id: new FormControl(data.branch_id),
      onlinetype_id: new FormControl(data.onlinetype_id)

    });

    (<FormArray>this.onlineSetUp.get('online_type')).push(control);
  }
  // addOnlinePaymentEdit(data) {
  //   const control = new FormGroup({
  //     payment_type_english: new FormControl(data.payment_type_english, Validators.required),
  //     payment_type_arabic: new FormControl(data.payment_type_arabic, Validators.required),
  //     brand_id: new FormControl(data.brand_id),
  //     branch_id: new FormControl(data.branch_id),
  //     onlinePayment_id:new FormControl(data.onlinePayment_id)
  //   });
  //   (<FormArray>this.onlineSetUp.get('online_payment_type')).push(control);
  // }
  getBrand() {
    this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;
      },
      error => {
        console.log('error', error);
      }
    );
  }

  removeOnline(i, data) {
    const x = (<FormArray>this.onlineSetUp.get('online_type'));
    if (data.value.onlinetype_id) {
      if (x.length > 1) {
        this.service.removeOnlineType(`?onlinetype_id=${data.value.onlinetype_id}`).subscribe(r => {
          (<FormArray>this.onlineSetUp.get('online_type')).removeAt(i);
          this.getOnlineSetup();
        }, error => {
          console.log('error', error);
          this.service.openSnackBar(error.error.message);
        });

      } else {
        this.service.openSnackBar('Atleast select one online type');
      }
    } else {
      console.log('data2');
      if (x.length > 1) {
        (<FormArray>this.onlineSetUp.get('online_type')).removeAt(i);
      } else {
        this.service.openSnackBar('Atleast select one online type');
      }

    }

  }

  // removePaymentType(i,data) {
  // const x = (<FormArray>this.onlineSetUp.get('online_payment_type'));
  // if (x.length > 1) {
  //   this.service.removeOnlinePayement(`?onlinePayment_id=${data.value.onlinePayment_id}`).subscribe(r => {
  //   (<FormArray>this.onlineSetUp.get('online_payment_type')).removeAt(i);
  //   this.getOnlineSetup(true);
  //   }, error => {
  //     console.log('error', error);
  //   });

  // } else {
  //   this.service.openSnackBar('Atleast select one ');
  // }
  // }

  getBranch() {
    this.service.Branches().subscribe(r => {
      this.branches = r.body;
    }, error => {
      console.log('error', error);
    });
  }

  onlineTypeList(e) {
    if (e.keyCode === 13) {
      this.addOnlineData();
    }
  }
  // paymentTypeList(e) {
  //   if (e.keyCode === 13) {
  //     this.addPaymentType();
  //   }
  // }
  addOnlineTypeList() {
    this.addOnlineData();
  }

  // addPaymentTypeList() {
  //   this.addPaymentType();
  // }

  submitOnlineSetup() {
    // if (this.onlineSetUp.get('online_type_english')) {
    this.service.saveOnlineDeliveryData(this.onlineSetUp.value).subscribe(res => {
      this.getOnlineSetup();
      this.onlineSetUp.reset();
      this.service.openSnackBar('Data Saved!');
      // (<FormArray>this.onlineSetUp.get('online_payment_type')).controls = [];
      (<FormArray>this.onlineSetUp.get('online_type')).controls = [];
      this.addOnlineData();
      // this.addPaymentType();
      this.onlineSource();
    },
      error => {
        console.log('error', error);
        if (error.status == 500) {
          this.service.openSnackBar('Please Select Brand and Branch');
        }
        if (error.error.body.errors[0].message === 'branch_id must be unique') {
          this.service.openSnackBar('Branch name already exist');
        }
      });
    // }else{
    //   this.service.openSnackBar('Please Enter Online Source');
    // }
  }
  selectonlineSource(data) {
    this.data = data;
    console.log("this.data", this.data)
    // ?orderby=onlinetype&onlinetype_id=10
    this.service.getonlinesourcePayment(`?orderby=onlinetype&onlinetype_id=${data.onlinetype_id}`).subscribe(res => {
      console.log("res get call", res)
      this.onlineSourcePayment = res.body;
      this.show = true;
    })
    console.log("data", data)
  }
  radioButton(card) {
    console.log(card)
    this.radioValue=card
  }
  paymentSubmit() {
    const payload = {
      // brand_id: this.data.brand_id,
      // branch_id: this.data.branch_id,
      payment_type_english: this.onlinePaymentSource.value.paymentTypeEng,
      payment_type_arabic: this.onlinePaymentSource.value.paymentTypeArabic,
      onlinetype_id: this.data.onlinetype_id,
      online_source_payment_type:this.radioValue
    }
    if (this.onlinePaymentSource.valid) {
      this.service.postPayment(payload).subscribe(res => {
        console.log("res 123", res);
        this.onlinePaymentSource.reset();
        this.selectonlineSource(this.data);
      });
    } else {
      this.service.openSnackBar('Please fill all the feilds')
    }
  }
  editPayment(data) {
    console.log("data123", data, "data", data.onlinesource_payment_id);
    this.onlineId = data.onlinesource_payment_id;
    this.onlinePayment.patchValue({ paymentEnglish: data.payment_type_english, paymentArabic: data.payment_type_arabic });
    console.log("this.onlinePayment", this.onlinePayment)
  }
  updatePayment() {
    console.log("hi");
    const payload = {
      payment_type_english: this.onlinePayment.value.paymentEnglish,
      payment_type_arabic: this.onlinePayment.value.paymentArabic,
      onlinesource_payment_id: this.onlineId
    }
    console.log("payload", payload)
    this.service.updateonlinesourcePayment(payload).subscribe(res => {
      console.log("res for update", res)
      this.selectonlineSource(this.data);
      this.onlineId = ''
    })
  }
  deletePayment(data) {
    console.log("hi", data);
    const query = `?onlinesource_payment_id=${data.onlinesource_payment_id}`
    this.service.deleteonlinesourcePayment(query).subscribe(res => {
      console.log("res1234567", res);
      this.selectonlineSource(this.data);
    })

  }
  updateOnlineSetup() {
    console.log("this.onlineSetUp.value", this.onlineSetUp.value)
    this.service.updateOnlineData(this.onlineSetUp.value).subscribe(res => {
      this.getOnlineSetup();
      this.showUpdateButton = false;
      this.service.openSnackBar('Data Updated!');
      this.onlineSetUp.reset();
      // (<FormArray>this.onlineSetUp.get('online_payment_type')).controls = [];
      (<FormArray>this.onlineSetUp.get('online_type')).controls = [];
      this.addOnlineData();
      // this.addPaymentType();
      this.disableInput = false;
    });
  }

  getSavedOnlineDetails() {
    this.onlineSource();
    this.showType = true;
    this.getOnlineSetup();
  }
  onlineSource() {
    this.service.getOnlineDeliverySource().subscribe(res => {
      this.onlinetype = res.body.onlinetypes;
      console.log("this.onlinetype", this.onlinetype)
    });
  }

  getOnlineSetup() {
    this.service.getOnlineDeliveryData().subscribe(res => {
      console.log("res", res)
      this.populateData = res.body.onlinetypes;
      console.log("this.populateData", this.populateData)
      // if (this.populateData.length > 0) {
      //   this.showOnlineData = true;
      // }
    });
  }

  edit(data) {
    console.log(data);
    // (<FormArray>this.onlineSetUp.get('online_payment_type')).controls = [];
    (<FormArray>this.onlineSetUp.get('online_type')).controls = [];
    console.log("dataedit==", data)
    this.getBranch();
    this.showUpdateButton = true;
    // data.onlinetypes.forEach(element => {
    this.addOnlineDataEdit(data);
    // });
    // data.onlinepaymenttypes.forEach(element => {
    //   this.addOnlinePaymentEdit(element);
    // });
    this.disableInput = true;
  }
  
  deleteDeliveryData(data) {
    this.popUp();

  }

  deleteOnlineModal() {
    this.service.deleteOnlineData().subscribe(res => {
      this.getOnlineSetup();
      this.popUpOff();
      this.service.openSnackBar('Data Deleted!');
    });
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  cancelOnlineSetup() {
    this.onlineSetUp.reset();
    // (<FormArray>this.onlineSetUp.get('online_payment_type')).controls = [];
    (<FormArray>this.onlineSetUp.get('online_type')).controls = [];
    this.addOnlineData();
    // this.addPaymentType();
    this.showUpdateButton = false;
    this.disableInput = false;
  }

  isFieldValid(field: string) {
    return (
      (this.onlineSetUp.get(field).invalid && this.onlineSetUp.get(field).touched) ||
      (this.onlineSetUp.get(field).untouched && this.formAttemptSubmit)
    );
  }
}
