import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MatDatepickerInputEvent } from '@angular/material';
import { LocalStorageService } from 'ngx-store';
import { PosBackOfficeService } from '../providers/pos-back-office.service';

@Component({
  selector: 'app-wastage-summary',
  templateUrl: './wastage-summary.component.html',
  styleUrls: ['./wastage-summary.component.scss']
})
export class WastageSummaryComponent implements OnInit {
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  wastageSummary:FormGroup;
  systemDate: string;
  brands: any;
  branches: any;
  brandName: any;
  supplierName: any;
  maxDate = new Date();
  maxToDate = new Date();
  maxFromDate = new Date();
  minToDate: any;
  fromdate;
  todate;
  wastageReport:any = [];
  p:any;
  p1:any;
  showReports:boolean=true;
  showReport:boolean=false;
  showPrint:boolean=false;
  showPrint1:boolean=false;
  selectedwastage:any;
  showOrderDetails:boolean=false;
  totalAmountItem:any;
  totalQuantityItem:any;
  branchName:any;
  totalitemAmount:any;
  totalitemPrice:any;
  totalitemQuantity:any;
  itemName:any;
  itemtotal:any;
  itemunitprice:any;
  
  
  constructor(
    private fb: FormBuilder,
    private adapter: DateAdapter<any>,
    public service: PosBackOfficeService,
    private datePipe: DatePipe,
    public ls: LocalStorageService
  ) { }
  
    ngOnInit() {
      this.wastageSummary = this.fb.group({
        fromDate: [new Date().toISOString().slice(0, 10)],
        toDate: [new Date().toISOString().slice(0, 10)],
        brand: '',
        branch: ''
      });
      this.dateAdapter();
      this.getBrand();
      this.systemDate = this.datePipe.transform(this.maxDate, 'yyyy-MM-dd HH:mm');
      this.dateValidation();
    }

    getBranch(brand, brandName) {
      this.brandName = brandName;
      this.service.Branches(`?brand_id=${brand}`).subscribe(r => {
        this.branches = r.body;
      });
    }
    getBranchName(data) {
      let returnBranch = 'NA';
      const queryBranch = this.branches.filter(res => res.branch_id == data);
      if (queryBranch.length !== 0) {
        returnBranch = queryBranch[0].branch_name;
      }
      return returnBranch;
    }
  
    getBrand() {
      this.service.brands('get').subscribe(
        result => {
          this.brands = result.body;
        },
        error => {
          console.log('error', error);
          this.service.openSnackBar(error.error.message);
        }
      );
    }
    dateValidation() {
      this.minToDate = this.wastageSummary.value.fromDate;
      this.maxFromDate = this.wastageSummary.value.toDate;
    }
    dateAdapter() {
      this.adapter.setLocale('en-GB');
    }
    getSum(index, array): any {
      let sum = 0;
      let calcArray = array;
      if (calcArray) {
        console.log("calcArray", calcArray)
        for (let i = 0; i < calcArray.length; i++) {
          sum += parseFloat(calcArray[i][index]);
        }
        console.log('sum', sum);
        return sum;
      }
    }
    
    getwastageitem() {
      this.itemunitprice = 0;
      this.itemtotal = 0;
      let sum1 =0;
      let sum2 =0;
      this.service.getwastagegrv(`?filterbyBrand=1&brand_id=${this.wastageSummary.value.brand}&filterbybranch=1&branch_id=${this.wastageSummary.value.branch}&fromdate=${this.fromdate}&todate=${this.todate}`).subscribe(
        res => {
          this.wastageReport = res.body;
          this.branchName =this.wastageSummary.value.branch;
          this.showReports = false;
          this.showReport = true;
          for (var i=0; i<res.body.length; i++){
            for(var j=0; j<res.body[i].item.length; j++){
              sum1 += parseFloat(res.body[i].item[j].average_price)
              sum2 += parseFloat(res.body[i].item[j].total_amount)
              this.itemunitprice = sum1;
              this.itemtotal = sum2;
              
            }
          }
       
  
        },
        error => {
          console.log(error.error.message);
          this.service.openSnackBar(error.error.message);
        }
      );
    }
   
    sortDate() {
      this.dateValidation();
        this.fromdate =
        this.datePipe.transform(this.wastageSummary.value.fromDate, 'yyyy-MM-dd') ||
        this.datePipe.transform(this.wastageSummary.value.toDate, 'yyyy-MM-dd') ||
        '';
      this.todate =
        this.datePipe.transform(this.wastageSummary.value.toDate, 'yyyy-MM-dd') || this.fromdate || '';  
  }
  print() {
    setTimeout(() => {
      this.printReceipt('wastage_list_print');
    }, 500);
    this.showPrint = true;
  }
  printReceipt(div): void {
    let printContents, popupWin;
    printContents = document.getElementById(div).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Sales Report</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }
}