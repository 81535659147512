import { Component, OnInit } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
import { MatDatepickerInputEvent } from '@angular/material';
import { DatePipe } from '@angular/common';
import { query } from '@angular/core/src/render3';

@Component({
  selector: 'app-foodcost',
  templateUrl: './foodcost.component.html',
  styleUrls: ['./foodcost.component.scss']
})
export class FoodcostComponent implements OnInit {
  brand: any;
  branch: any;
  brandID:any;
  branchID:any;
  selectedBrand: any;
  selectedBranch: any;
  foodcost: any;
  fromdate;
  todate;
  itemactual:any;
  itemideal:any;
  itemvariance:any;
  p:any;
  p1:any;
  foodCostForm:FormGroup;
  maxDate = new Date();
  todayDate;
  showPrint1: boolean = false;
  printArray: any = [];
  formSubmitted;
    // maxFromDate = new Date();
  constructor( private fb: FormBuilder,
    public service: PosBackOfficeService,
    private datePipe: DatePipe,
    public ls: LocalStorageService) { }
  
foodcostArray:any=[{
  item_code:233,
  item_name:"apple",
  unit_price:33,
  opening_stock:22,
  stock_in:12,
  stock_out:2,
  wastage:3,
  Closing_Stock:12,
  Actual_weight:12,
  Actual_cost:12,
  Ideal_weight:23,
  Ideal_cost:12,
  Variance_weigth:12,
  Variance_cost:23
},{
  item_code:233,
  item_name:"apple",
  unit_price:33,
  opening_stock:22,
  stock_in:12,
  stock_out:2,
  wastage:3,
  Closing_Stock:12,
  Actual_weight:12,
  Actual_cost:12,
  Ideal_weight:23,
  Ideal_cost:12,
  Variance_weigth:12,
  Variance_cost:23

}
]

  ngOnInit() {
this.foodCostForm =this.fb.group({
  fromDate:["",Validators.required],
  toDate:["",Validators.required],
  brandname:["",Validators.required],
  branchname:["",Validators.required]
})

this.brandData()
this.foodcost=[]
const time = new Date();
  }
  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  
  isFieldValid(field: string) {
    return (
      (!this.foodCostForm.get(field).valid && this.foodCostForm.get(field).touched) ||
      (this.foodCostForm.get(field).untouched && this.formSubmitted)
    );
  }
  brandData() {
    this.service.getBrandDetails().subscribe(result => {
      this.brand = result.body;
      console.log("brand",this.brand )
    });
  }
  getBranch(brand) {
    this.selectedBrand=brand
    this.service.Branches(`?brand_id=${brand.brand_id}`).subscribe(r => {
      this.branch = r.body;
      console.log("b",this.branch )
    });
  }

  selectBranch(data){
    this.foodcost=[]
    this.selectedBranch=data
    this.filterByDate()
  }

  filterByDate() {
    console.log("ffffffffff",this.foodCostForm.value)
    if((this.foodCostForm.value.fromDate != "") && (this.foodCostForm.value.toDate != "")){
      this.fromdate = this.datePipe.transform(this.foodCostForm.value.fromDate, 'yyyy-MM-dd');
      this.todate = this.datePipe.transform(this.foodCostForm.value.toDate, 'yyyy-MM-dd');
      let fromdate = this.datePipe.transform(this.foodCostForm.value.fromDate,"yyyy-MM-dd");
      let todate = this.datePipe.transform(this.foodCostForm.value.toDate,"yyyy-MM-dd");
      this.foodCostForm.patchValue({ fromDate: fromdate });
      this.foodCostForm.patchValue({ toDate: todate });
  
      let f_date = new Date(this.foodCostForm.value.fromDate);
      let t_date = new Date(this.foodCostForm.value.toDate);
      console.log("f_datef_date",f_date)
      f_date.setUTCHours(0, 0, 0, 0);
      t_date.setUTCHours(23, 59, 59, 59);
  
      // let limit = 0;
      // let offset = 0;
      this.getfoodcost(f_date.toISOString(),t_date.toISOString())
    }

  }

  getfoodcost(fromdate,todate){
    let query=`?brand_id=${this.selectedBrand.brand_id}&branch_id=${this.selectedBranch.branch_id}&fromdate=${fromdate}&todate=${todate}`
    this.service.foodcost(query).subscribe(res => {
      console.log("----foodcost",res)
      for(let i=0;i<res.body.length;i++){
        for(let j=0; j<res.body[i].item.length;j++){
        if (res.body[i].item[j].openingstock == undefined) {
          res.body[i].item[j].openingstock = 0;
        }

        if (res.body[i].item[j].stockin == undefined) {
          res.body[i].item[j].stockin = 0;
        }

        if (res.body[i].item[j].totalStockOut == undefined) {
          res.body[i].item[j].totalStockOut = 0;
        }

        if (res.body[i].item[j].totalwastageqty == undefined) {
          res.body[i].item[j].totalwastageqty = 0;
        }
        
        if (res.body[i].item[j].closing == undefined) {
          res.body[i].item[j].closing = 0;
        }

        if (res.body[i].item[j].actualstockquantity == undefined) {
          res.body[i].item[j].actualstockquantity = 0;
        }
        
        if (res.body[i].item[j].actualstockquantitycost == undefined) {
          res.body[i].item[j].actualstockquantitycost = 0;
        }

        if (res.body[i].item[j].idealstockqty == undefined) {
          res.body[i].item[j].idealstockqty = 0;
        }

        if (res.body[i].item[j].idealstockprice == undefined) {
          res.body[i].item[j].idealstockprice = 0;
        }
      
        if (res.body[i].item[j].varianceweight == undefined || res.body[i].item[j].varianceweight == null) {
          res.body[i].item[j].varianceweight = 0;
        }
        if (res.body[i].item[j].variancecost == undefined || res.body[i].item[j].variancecost == null) {
          res.body[i].item[j].variancecost = 0;
        }
        if (res.body[i].item[j].stockcount == undefined || res.body[i].item[j].stockcount == null) {
          res.body[i].item[j].stockcount = 0;
        }
        if (res.body[i].item[j].varianceqty == undefined || res.body[i].item[j].varianceqty == null) {
          res.body[i].item[j].varianceqty = 0;
        }
      }
        
      }
      console.log("777",res.body)
      this.foodcost=res.body
      let sum1=0;
      let sum2=0;
      let sum3=0;

      for (var i=0; i<res.body.length; i++){
        for(var j=0; j<res.body[i].item.length; j++){     
          sum1 += parseFloat(res.body[i].item[j].actualstockquantitycost)
          sum2 += parseFloat(res.body[i].item[j].idealstockprice)
          sum3 += parseFloat(res.body[i].item[j].variancecost)
          this.itemactual = sum1;
          this.itemideal = sum2;
          this.itemvariance = sum3;
         
          
        }
      }
      this.brandID = this.foodCostForm.value.brandname;
      this.branchID = this.foodCostForm.value.branchname;
    });
  }
  getSum(index, array): number {
    let sum = 0;
    let calcArray = array;
    if (calcArray) {
      console.log("calcArray", calcArray)
      for (let i = 0; i < calcArray.length; i++) {
        sum += calcArray[i][index];
      }
      console.log('sum', sum);
      return sum;
    }
  }
  getSums(data): number {
    let sum = 0;
    let calcArray = this.foodcost;
    if (calcArray) {
      console.log("calcArray", calcArray)
      for (let i = 0; i < calcArray.length; i++) {
        sum += calcArray[i].percent[data]
      }
      console.log('sum', sum);
      return sum;
    }
  
  }
  getBranchName(data) {
    let returnBranch = 'NA';
    const queryBranch = this.branch.filter(res => res.branch_id == data);
    if (queryBranch.length !== 0) {
      returnBranch = queryBranch[0].branch_name;
    }
    return returnBranch;
  }
  getBrandName(data) {
    let returnBrand = 'NA';
    const queryBrand = this.brand.filter(res => res.brand_id == data);
    if (queryBrand.length !== 0) {
      returnBrand = queryBrand[0].brand_name;
    }
    return returnBrand;

  }
  print1() {
    const today = Date.now();
    this.todayDate = this.datePipe.transform(today, "dd-MM-yyyy HH:mm");
    setTimeout(() => {
      this.printReceipt1('food_cost');
    }, 1000);
    this.showPrint1 = true;
  }
  printReceipt1(div): void {
    let printContents, popupWin;
    printContents = document.getElementById(div).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Food Cost Report</title>
          <style type="text/css" media="print">
          @page { size: landscape; }
        </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
    let payload = {
      printer_name: this.ls.get("manager_printer"),
      printer_data: printContents,
    };
    this.printArray.push(payload);
    this.service.printer(this.printArray).subscribe(
      res => {
        this.printArray = [];
      },
      err => {
        this.printArray = [];
        console.log("error", err);
      });
  }

  print() {
    const today = Date.now();
    this.todayDate = this.datePipe.transform(today, "dd-MM-yyyy HH:mm");
    setTimeout(() => {
      this.printReceipt('food_cost_average');
    }, 1000);
    this.showPrint1 = true;
  }
  printReceipt(div): void {
    let printContents, popupWin;
    printContents = document.getElementById(div).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Food Cost Report</title>
          <style type="text/css" media="print">
          @page { size: portait; }
        </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
    let payload = {
      printer_name: this.ls.get("manager_printer"),
      printer_data: printContents,
    };
    this.printArray.push(payload);
    this.service.printer(this.printArray).subscribe(
      res => {
        this.printArray = [];
      },
      err => {
        this.printArray = [];
        console.log("error", err);
      });
  }

}

