import { Component, OnInit, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { ConcatSource } from 'webpack-sources';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {
  imagedata: String = '';
  textAlign: string;
  rowChange: any;
  rowOrder: any;
  logo: any = './../../assets/images/uploadimage.jpg';
  backupBrand: any;
  noMatchingResults: boolean;
  brandLogo = [];
  brandData1: any;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) {}
  brand: FormGroup;
  brandUpdate: FormGroup;
  i: Number;
  showAvailable: Boolean = true;
  brandData: any;
  showPdfErrorMsg: Boolean;
  formSubmitAttempt: Boolean;
  showUpdateButton: Boolean;
  showSubmitButton: Boolean = true;
  brandId: any;
  index: number;
  brandIdDelete: any;
  brandIdEdit: '';
  p: Number = 1;
  public uploader: FileUploader = new FileUploader({});

  ngOnInit() {
    this.brandUpdate = this.fb.group({
      brand_name:  ['',[Validators.required]],   
      brand_details: ['',[Validators.required]],
      brand_slogan: [''],
      brand_name_arabic: ['', [Validators.required]],
      image_id: [''],
      footer:['', Validators.required],
      phone_no: ['', Validators.pattern('^[0-9/]{0,20}$')]
    });

    this.brand = this.fb.group({
      brandName:  ['',[Validators.required]],
      description: ['',[Validators.required]],
      brand_slogan: [''],
      brand_name_arabic: ['', [Validators.required]],
      image_id: ['', [Validators.required]],
      footer:['', Validators.required],
      phone_no: ['', [Validators.pattern('^[0-9/]{0,50}$')]]
    });
    this.getBrandDetails();
    this.inputAlignment();
  }

patchman(){
  const one =this.brand.get('brandName').value;
  this.brand.patchValue({'brandName':one.replace(/^ +/gm, '')})
  const two =this.brand.get('description').value;
  this.brand.patchValue({'description':two.replace(/^ +/gm, '')})
  const three =this.brand.get('brand_slogan').value;
  this.brand.patchValue({'brand_slogan':three.replace(/^ +/gm, '')})

}
  inputAlignment() {
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }

  saveBrand() {
    if (!this.brandData.some(
        r =>
          r.brand_name.toUpperCase().replace(/\s/g, '') ==
          this.brand.value.brandName.toUpperCase().replace(/\s/g, '')
      )
    ) {
      if (
        !this.brandData.some(
          r =>
            r.brand_name_arabic.replace(/\s/g, '') ==
            this.brand.value.brand_name_arabic.replace(/\s/g, '')
        )
      ) {
        this.save();
      } else {
        this.service.openSnackBar('Brand arabic name already exists');
      }
    } else {
      this.service.openSnackBar('Brand name already exists');
    }
  }

  save() {
    const data = {
      brand_name: this.brand.value.brandName,
      brand_details: this.brand.value.description,
      brand_slogan: this.brand.value.brand_slogan,
      image_id: this.brand.value.image_id,
      brand_name_arabic: this.brand.value.brand_name_arabic,
      phone_no: this.brand.value.phone_no,
      footer:this.brand.value.footer
    };

    this.service.saveBrandDetails(data).subscribe(
      result => {
        this.getBrandDetails();
        this.service.openSnackBar('Brand Added!');
        this.brand.reset();
        this.imagedata = '';
        this.logo = './../../assets/images/uploadimage.jpg';
      },
      error => {
        this.service.openSnackBar(error.error.message);
        if (error.error.body.errors[0].message === 'brand_name_arabic must be unique') {
          this.service.openSnackBar('Already brand arabic name exists');
        }
        // }
      }
    );
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.showPdfErrorMsg = false;
    } else {
      this.showPdfErrorMsg = true;
    }
    const FileSize = file.size / 1024 / 1024;

    if (FileSize > 0.5) {
      return this.service.openSnackBar(`File size exceeds 500KB`);
    }
    this.imagedata = file.name;
    const formData = new FormData();
    formData.append('file', file);
    this.service.upload(formData).subscribe(
      result => {
        this.brand.patchValue({ image_id: result.body.image_id });
        this.service.getImage(result.body.image_id).subscribe((r: any) => {
          this.logo = r.body[0].image_data;
        });
      },
      error => error
    );
  }

  public onFileSelected1(event: EventEmitter<File[]>, i) {
    const file: File = event[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.showPdfErrorMsg = false;
    } else {
      this.showPdfErrorMsg = true;
    }
    const FileSize = file.size / 1024 / 1024;

    if (FileSize > 0.5) {
      return this.service.openSnackBar(`File size exceeds 500KB`);
    }
    this.imagedata = file.name;
    const formData = new FormData();
    formData.append('file', file);
    this.service.upload(formData).subscribe(
      result => {
        this.brandUpdate.patchValue({ image_id: result.body.image_id });
        this.service.getImage(result.body.image_id).subscribe((r: any) => {
          this.brandData[i].logo = r.body[0].image_data;
        });
      },
      error => error
    );
  }

  getBrandDetails() {
    this.service.getBrandDetails().subscribe(result => {
      this.brandData=result.body
      // this.brandData = result.body.sort(function(a, b) {
      //   return a.brand_id - b.brand_id;
      // });
      this.brandData.map(element => {
        this.service.getImage(element.image_id).subscribe((r: any) => {
          if (r.body.length !== 0) {
            element.logo = r.body[0].image_data;
          }
        });
      });
      this.backupBrand = result.body;
      if (this.brandData.length > 0) {
        this.showRegistrationData(this.backupBrand);
        this.showAvailable = false;
      } else {
        this.showAvailable = true;
      }
    });
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }
  edit(index, item) {
    console.log("item",item)
    this.brandData1 = item;
    this.brandIdEdit = item.brand_id;
    this.index = index;
    this.brandId = item.brand_id;
    this.brandUpdate.patchValue({
      brand_name: item.brand_name,
      brand_details: item.brand_details,
      brand_slogan: item.brand_slogan,
      image_id: item.image_id,
      brand_name_arabic: item.brand_name_arabic,
      phone_no: item.phone_no,
      footer : item.footer
    });
  }

  deleteBrand(data) {
    this.brandIdDelete = data.brand_id;
    this.popUp();
  }

  deleteBrandModal() {
    this.service.deleteBrandDetails(this.brandIdDelete).subscribe(
      result => {
        if(result.status){
            this.getBrandDetails();
            this.service.openSnackBar('Brand Deleted!');
            this.popUpOff();
        }
      },
      error => {
        this.service.openSnackBar(error.error.message);
        this.popUpOff();
      }
    );
  }

  updateBrandData() {
    const arrayData = [];
    if (
      this.brandData1.brand_name == this.brandUpdate.value.brand_name &&
      this.brandData1.brand_name_arabic == this.brandUpdate.value.brand_name_arabic
    ) {
      return this.updateBrand();
    } else {
      this.brandData.map(res => {
        if (res.brand_id !== this.brandData1.brand_id) {
          arrayData.push(res);
        }
      });
    }

    if (
      !arrayData.some(
        r =>
          r.brand_name.toUpperCase().replace(/\s/g, '') ==
          this.brandUpdate.value.brand_name.toUpperCase().replace(/\s/g, '')
      )
    ) {
      if (
        !arrayData.some(
          r =>
            r.brand_name_arabic.toUpperCase().replace(/\s/g, '') ==
            this.brandUpdate.value.brand_name_arabic.toUpperCase().replace(/\s/g, '')
        )
      ) {
        this.updateBrand();
      } else {
        this.service.openSnackBar('Brand arabic name already exists');
      }
    } else {
      this.service.openSnackBar('Brand name already exists');
    }
  }

  updateBrand() {
    const updateBrand = {
      brand_name: this.brandUpdate.value.brand_name,
      brand_details: this.brandUpdate.value.brand_details,
      brand_id: this.brandId,
      brand_slogan: this.brandUpdate.value.brand_slogan,
      image_id: this.brandUpdate.value.image_id,
      brand_name_arabic: this.brandUpdate.value.brand_name_arabic,
      phone_no: this.brandUpdate.value.phone_no,
      footer:this.brandUpdate.value.footer
    };
    this.service.updateBrandDetails(updateBrand).subscribe(
      result => {
        this.service.openSnackBar('Brand Updated!');
        const response = result.body[1];
        this.getBrandDetails();
        this.brandIdEdit = '';
        this.brand.reset();
        this.imagedata = '';
      },
      error => {
        if(error.error.message){
          return this.service.openSnackBar(error.error.message);
        }
        if (error.error.body.errors[0].message === 'brand_name must be unique') {
         return this.service.openSnackBar('Already brand name exists');
        }
        else if (error.error.body.errors[0].message === 'brand_name_arabic must be unique') {
          return this.service.openSnackBar('Already brand arabic name exists');
        }
        
        
      }
    );
  }

  isFieldValid(field: string) {
    return (
      (!this.brand.get(field).valid && this.brand.get(field).touched) ||
      (this.brand.get(field).untouched && this.formSubmitAttempt)
    );
  }

  showRegistrationData(data) {
    if (data.length > 10) {
      this.noMatchingResults = false;
    } else {
      if (data.length === 0) {
        this.noMatchingResults = true;
      } else {
        this.noMatchingResults = false;
      }
    }
  }

  applyFilter(filter: String) {
    this.brandData = this.backupBrand.filter(item => {
      if (
        item.brand_name
          .toString()
          .toLowerCase()
          .indexOf(filter.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
    this.showRegistrationData(this.brandData);
  }
}
