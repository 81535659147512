import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-agent-performance',
  templateUrl: './agent-performance.component.html',
  styleUrls: ['./agent-performance.component.scss']
})
export class AgentPerformanceComponent implements OnInit {
  avaAgent: any;
  allusers: any;
  order_fromDate: any;
  order_toDate: any;
  agent: FormGroup;
  agentDetails: any;
  selectedAgent: any;
  obj: { h: number; m: number; s: number; };
  avgTime: string;

  constructor(private fb: FormBuilder, public service: PosBackOfficeService,
    public ls: LocalStorageService, public datePipe: DatePipe) { }

  ngOnInit() {
    this.agent = this.fb.group({
      fromDate: [''],
      toDate: [''],
      agentName: ['']
    })
    this.agent.patchValue({ fromDate: new Date(), toDate: new Date() })
    this.getAllUsers();
    this.getData();
  }
  getAllUsers() {
    this.service.getAgentUsers().subscribe(res => {
      this.allusers = res;
      this.avaAgent = this.allusers.filter(function (res) {
        return res.role == 'cc_agent'
      })
    })
  }
  getData() {
    console.log("this.agent.value.fromDate", this.agent.value)
    let sDate = this.datePipe.transform(this.agent.value.fromDate, 'yyyy-MM-dd')
    let eDate = this.datePipe.transform(this.agent.value.toDate, 'yyyy-MM-dd')
    let payload = {
      fromDate: sDate + 'T00:00:00',
      toDate: eDate + 'T23:59:59'
    }
    console.log("payload", payload)
    let querry;
    if (this.selectedAgent) {
      querry = `?fromDate=${payload.fromDate}&toDate=${payload.toDate}&user=${this.selectedAgent}`
    } else {
      querry = `?fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    }
    console.log("querry",querry)
    this.service.getAgentPerformance(querry).subscribe(res => {
      console.log("res", res)
      this.agentDetails = res.body;
      this.agentDetails.forEach((element,index)=>{
        console.log("element",element)
        var hours = Math.floor(element.avgTalktime / (60 * 60));

        var divisor_for_minutes = element.avgTalktime % (60 * 60);
        var minutes = Math.floor(divisor_for_minutes / 60);

        var divisor_for_seconds = divisor_for_minutes % 60;
        var seconds = Math.ceil(divisor_for_seconds);

        this.obj = {
          "h": hours,
          "m": minutes,
          "s": seconds
        };
        this.avgTime = this.obj.h + ':' + this.obj.m + ':' + this.obj.s
        this.agentDetails[index]['talktime'] = this.avgTime;
        console.log(this.obj.h + ':' + this.obj.m + ':' + this.obj.s)
        return this.obj;

      })
      console.log("agent details")
    })

  }

  fromDate(event) {
    console.log("event", event.value.toISOString());
    this.getData()
  }
  toDate(event) {
    console.log("event", event.value.toISOString());
    this.getData();
  }
  selectAgent(event) {
    console.log("event", event)
    this.selectedAgent = event
    if (event) {
      this.getData()
    }
    // let querry = `?fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    // this.service.getAgentPerformance(querry).subscribe(res => {
    //   console.log("res", res)
    //   this.agentDetails = res.body;
    //   console.log("agent details")
    // })
  }
  clearFilter(){
    this.agent.patchValue({
      fromDate: new Date(),
      toDate:new Date(),
      agentName:''
    })
    this.getData();
  }
}
