import { Component, OnInit } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss']
})
export class DiscountComponent implements OnInit {
  brands = [];
  discounts = [];
  deleteQuery: any;
  i: Number;
  p: Number = 1;
  deleteId: any;
  deleteRule: any;
  discount: FormGroup;
  updateModal: FormGroup;
  formSubmitAttempt: Boolean;
  textAlign: string;
  discountID: '';
  showAvailable: Boolean = true;
  brandName: any;
  discountData: any;
  brnad_id: any;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) { }

  ngOnInit() {
    this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;
      },
      error => {
        console.log('error', error);
      }
    );
    this.discountCRUD('get');

    this.discount = this.fb.group({
      brand_name: ['', Validators.required],
      brand_id: [],
      discount_name: ['', Validators.required],
      discount_percentage: ['', [Validators.min(0), Validators.max(100)]],
      discount_price: ['', [Validators.min(0)]],
      discount_type: ['', Validators.required],
      discount_status: [true]
    });

    this.updateModal = this.fb.group({
      discount_id: [''],
      brand_name: ['', Validators.required],
      discount_name: ['', Validators.required],
      discount_percentage: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      discount_price: ['', [Validators.required, Validators.pattern('^[0-9.]*')]],
      discount_type: ['', Validators.required],
      discount_status: [true]
    });
    this.inputAlignment();
  }
  patchman1() {
    const one = this.discount.get('discount_name').value;
    this.discount.patchValue({ 'discount_name': one.replace(/^ +/gm, '') });
  }
  inputAlignment() {
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }

  binding(data) {
    console.log("data",data)
    this.discount.patchValue({
      brand_id: data.brand_id
    });
    this.brnad_id = data.brand_id
    console.log("patch value",this.discount)
  }

  discountPost(rule, body, query) {
    this.brandName = this.discount.value.brand_name;
    console.log("rule", rule, "body", body, "query", query)
    const arrayData = [];
    this.discounts.map(res => {
      if (res.brand_name == this.discount.value.brand_name) {
        arrayData.push(res);
      }
    });
    console.log("this.discounts",this.discounts)
    if (
      !arrayData.some(
        r =>
          (r.discount_name).toUpperCase().replace(/\s/g, '') ==
          (this.discount.value.discount_name).toUpperCase().replace(/\s/g, '')
      )
    ) {
      this.discountCRUD(rule, body, query);
    } else {
      this.service.openSnackBar('Discount name already exists');
    }
  }
  discountPut(rule, body, query, index) {
    const arrayData = [];
    if (this.discountData.discount_name == this.updateModal.value.discount_name) {
      return this.discountCRUD(rule, body, query, index);
    } else {
      this.discounts.map(res => {
        if (res.brand_name == this.discountData.brand_name) {
          if (res.discount_id !== this.discountData.discount_id) {
            arrayData.push(res);
          }
        }
      });
    }
    if (
      !arrayData.some(
        r =>
          (r.discount_name).toUpperCase().replace(/\s/g, '') ==
          (this.updateModal.value.discount_name).toUpperCase().replace(/\s/g, '')
      )
    ) {
      this.discountCRUD(rule, body, query, index);
    } else {
      this.service.openSnackBar('Discount name already exists');
    }
  }
  discountCRUD(rule, payload = '', query = '', index = 1) {
    console.log("this.discounts",rule,"payload",payload,"query",query,"index",index)
    this.service.Discount(rule, payload, query).subscribe(
      result => {
        if (rule === 'get') {
          this.discounts = result.body;
          if (this.discounts.length > 0) {
            this.showAvailable = false;
          } else {
            this.showAvailable = true;
          }
        }
        if (rule === 'delete') {
          this.discountCRUD('get');
          this.service.openSnackBar('Discount Deleted!');
          this.popUpOff();
        }
        if (rule === 'post') {
          this.discount.reset();
          this.discountCRUD('get');
          this.discount.patchValue({ brand_name: this.brandName });
          this.discount.patchValue({ brand_id: this.brnad_id });
          this.discount.patchValue({ discount_status: true });
          this.service.openSnackBar('Discount Added!');
        }
        if (rule === 'put') {
          const response = result.body[1];
          this.service.openSnackBar('Discount Updated!');
          this.discountCRUD('get');
          this.discountID = '';
        }
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  keyUpPercentage1(value) {
    if (value !== '' && value !== null) {
      this.updateModal.patchValue({ discount_price: 0 });
    } else {
      this.updateModal.patchValue({ discount_price: '' });
    }
  }

  keyUpPrice1(value) {
    if (value !== '' && value !== null) {
      this.updateModal.patchValue({ discount_percentage: 0 });
    } else {
      this.updateModal.patchValue({ discount_percentage: '' });
    }
  }

  keyUpPercentage(value) {
    if (value !== '' && value !== null) {
      this.discount.patchValue({ discount_price: 0 });
    } else {
      this.discount.patchValue({ discount_price: '' });
    }
  }

  keyUpPrice(value) {
    if (value !== '' && value !== null) {
      this.discount.patchValue({ discount_percentage: 0 });
    } else {
      this.discount.patchValue({ discount_percentage: '' });
    }
  }

  deleteDiscount(rule, id, query) {
    this.deleteRule = rule;
    this.deleteId = id;
    this.deleteQuery = query;
    this.popUp();
  }

  deleteDiscountModal() {
    this.discountCRUD(this.deleteRule, this.deleteId, this.deleteQuery);
  }

  edit(data, index) {
    this.discountData = data;
    this.discountID = data.discount_id;
    this.updateModal.patchValue({
      discount_id: data.discount_id,
      brand_name: data.brand_name,
      discount_name: data.discount_name,
      discount_type: data.discount_type,
      discount_percentage: data.discount_percentage,
      discount_price: data.discount_price,
      discount_status: data.discount_status
    });
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  isFieldValid(field: string) {
    return (
      (!this.discount.get(field).valid && this.discount.get(field).touched) ||
      (this.discount.get(field).untouched && this.formSubmitAttempt)
    );
  }
}
