import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray,
} from "@angular/forms";
import { PosBackOfficeService } from "../providers/pos-back-office.service";
import { LocalStorageService, CookiesStorageService } from "ngx-store";
@Component({
  selector: "app-complaints",
  templateUrl: "./complaints.component.html",
  styleUrls: ["./complaints.component.scss"],
})
export class ComplaintsComponent implements OnInit {
  complainteForm: FormGroup;
  formSubmitted;
  brand;
  branch;
  complaints: any = [];
  brandIdDelete: any;
  complaintIdDelete: any;
  complaintUpdateForm: FormGroup;
  subCategory: FormGroup;
  showbtn: boolean = false;
  subcomplaintId: any;
  subcomplaint: any;
  showSubcategory: boolean;
  editSuuCat: FormGroup;
  editSubCat: FormGroup;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) { }

  ngOnInit() {
    this.complainteForm = this.fb.group({
      complaint_category_english: ["", [Validators.required]],
      complaint_category_arabic: ["", [Validators.required]],
      complaint_description_english: ["", [Validators.required]],
      complaint_description_arabic: ["", [Validators.required]],
    });

    this.complaintUpdateForm = this.fb.group({
      updatecomplaint_category_english: ["", [Validators.required]],
      updatecomplaint_category_arabic: ["", [Validators.required]],
      updatecomplaint_description_english: ["", [Validators.required]],
      updatecomplaint_description_arabic: ["", [Validators.required]],
    });
    this.subCategory = this.fb.group({
      brand: '',
      subCategoryEnglish: '',
      subCategoryArabic: '',
    })
    this.editSubCat = this.fb.group({
      subCat: '',
      subcatEng: '',
      subcatAra: ''
    })
    this.brandData();
    this.getComplaints();
  }

  isFieldValid(field: string) {
    return (
      (!this.complainteForm.get(field).valid &&
        this.complainteForm.get(field).touched) ||
      (this.complainteForm.get(field).untouched && this.formSubmitted)
    );
  }

  brandData() {
    this.service.getBrandDetails().subscribe((result) => {
      this.brand = result.body.sort(function (a, b) {
        return a.brand_id - b.brand_id;
      });
    });
  }
  getBranch(brand) {
    // this.selectedBrand=brand
    this.service.Branches(`?brand_id=${brand.brand_id}`).subscribe((r) => {
      this.branch = r.body;
      console.log("b", this.branch);
    });
  }

  getComplaints = () => {
    // const query = `?branch_id=${this.complainteForm.value.branch_name}&brand_id=${this.complainteForm.value.brand_name}`;
    this.service.getComplaints().subscribe((res) => {
      this.complaints = res.body;
      console.log('this.complaints', this.complaints)
      this.resetEdit();
    });
  };

  createComplaint = () => {
    if (this.complainteForm.valid) {
      // const query = `?branch_id=${this.complainteForm.value.branch_name}&brand_id=${this.complainteForm.value.brand_name}`;
      const payload = {
        // brand_id: this.complainteForm.value.brand_name,
        // branch_id: this.complainteForm.value.branch_name,
        complaint_category_english: this.complainteForm.value
          .complaint_category_english,
        complaint_category_arabic: this.complainteForm.value
          .complaint_category_arabic,
        complaint_description_english: this.complainteForm.value
          .complaint_description_english,
        complaint_description_arabic: this.complainteForm.value
          .complaint_description_arabic,
      };
      this.service.addComplaint(payload).subscribe((res) => {
        if (res.status) {
          this.service.openSnackBar("Complaint added successfully");
          this.complainteForm.controls.complaint_category_english.reset();
          this.complainteForm.controls.complaint_category_arabic.reset();
          this.complainteForm.controls.complaint_description_arabic.reset();
          this.complainteForm.controls.complaint_description_english.reset();
          this.getComplaints();
        }
      });
    } else {
      this.service.openSnackBar("Please fill all the fields");
    }
  };

  removeComplaint = (id) => {
    this.complaintIdDelete = id;
    this.popUp();
  };

  resetEdit = () => {
    this.complaints.map((complaint) => {
      return (complaint["edit"] = false);
    });
  };

  confirmRemove = () => {
    const query = `?complaint_id=${this.complaintIdDelete}`;
    this.service.deleteComplaint(query).subscribe((res) => {
      if (res.status) {
        this.service.openSnackBar("Complaint deleted successfully");
        this.popUpOff();
        this.getComplaints();
      }
    });
  };

  updateComplaint = (id) => {
    const payload = {
      complaint_id: id,
      complaint_category_english: this.complaintUpdateForm.value
        .updatecomplaint_category_english,
      complaint_category_arabic: this.complaintUpdateForm.value
        .updatecomplaint_category_arabic,
      complaint_description_english: this.complaintUpdateForm.value
        .updatecomplaint_description_english,
      complaint_description_arabic: this.complaintUpdateForm.value
        .updatecomplaint_description_arabic,
    };
    this.service.updateComplaint(payload).subscribe((res) => {
      if (res.status) {
        this.service.openSnackBar("Complaint updated successfully");
        this.getComplaints();
      }
    });
  };
  updateSubComplaint = (id) => {
    const payload = {
      subcomplaint_id:id,
      subcomplaint_english:this.editSubCat.value.subcatEng,
      subcomplaint_arabic: this.editSubCat.value.subcatAra
  }
  console.log("payload",payload)
    this.service.updateSubComplaint(payload).subscribe((res) => {
      if (res.status) {
        this.service.openSnackBar("Sub-Complaint updated successfully");
        this.getsubcomplaint(this.subcomplaintId);
      }
    });
  };

  popUp() {
    document.getElementById("id01").style.display = "block";
  }

  popUpOff() {
    document.getElementById("id01").style.display = "none";
  }

  editComplaint = (id, data) => {
    console.log(id, data)
    this.complaintUpdateForm.patchValue({
      updatecomplaint_category_english: data.complaint_category_english,
      updatecomplaint_category_arabic: data.complaint_category_arabic,
      updatecomplaint_description_english: data.complaint_description_english,
      updatecomplaint_description_arabic: data.complaint_description_arabic,
    });
    console.log("this.complaintUpdateForm",this.complaintUpdateForm)
    this.complaints.map((complaint) => {
      if (complaint.complaint_id == id) {
        return (complaint["edit"] = true);
      } else {
        return (complaint["edit"] = false);
      }
    });
  };
  editSubComplaint(id, data) {
    console.log("data", id,data)
    this.editSubCat.patchValue({
      subCat: data.complaint.complaint_category_english,
      subcatEng: data.subcomplaint_english,
      subcatAra: data.subcomplaint_arabic
    })
    console.log("editSubCat",this.editSubCat)
    this.subcomplaint.map((subcomplaint) => {
      console.log("subcomplaint",subcomplaint)
      if (subcomplaint.subcomplaint_id == id) {
        return (subcomplaint["edit"] = true);
      } else {
        return (subcomplaint["edit"] = false);
      }
    })
    console.log("this.subcomplaint",this.subcomplaint)
  }

  complaint() {
    console.log("subCategory", this.subCategory.value.brand);
    this.showbtn = true;
    this.showSubcategory = true;
    this.subcomplaintId = this.subCategory.value.brand
    this.getsubcomplaint(this.subcomplaintId);
  }

  showSubcomplaints(data) {
    console.log(data.complaint_id)
    this.subcomplaintId = data.complaint_id
    this.getsubcomplaint(this.subcomplaintId);
    this.showSubcategory = true;
  }

  subComplaint() {
    const payload = {
      complaint_id: this.subCategory.value.brand,
      subcomplaint_english: this.subCategory.value.subCategoryEnglish,
      subcomplaint_arabic: this.subCategory.value.subCategoryArabic
    }
    this.showSubcategory = true;
    this.service.cresteSubcomplaint(payload).subscribe(res => {
      console.log("subcomplaint res", res)
      if (res.status) {
        this.subcomplaintId = this.subCategory.value.brand;
        this.showSubcategory = true;
        this.getsubcomplaint(this.subcomplaintId);
        this.subCategory.reset();
      }
    })
  }

  getsubcomplaint(id) {
    console.log("id", id)
    this.service.getCreatedSubComplaint(id).subscribe(res => {
      console.log("res", res)
      if (res.status) {
        this.subcomplaint = res.body;
      }
    })
  }
  deleteSubcomplaints(data) {
    this.service.deletesubCompaint(data.subcomplaint_id).subscribe(res => {
      console.log("delete res", res);
      this.getsubcomplaint(this.subcomplaintId)
    })
  }
}
