import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray,
  FormGroupName
} from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
import { AnimationKeyframesSequenceMetadata } from '@angular/animations';
@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent implements OnInit {
  textAlign: string;
  rowChange: any;
  backupBranch: any[];
  segmentList = [];
  noMatchingResults: boolean;
  branchData1: any;
  changeValue: any = {};
  value: any = {};
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) {
    this.segmentList = this.service.segmentForm;
  }

  brandName = '';
  i: Number;
  branch: FormGroup;
  branchModal: FormGroup;
  formSubmitAttempt: Boolean;
  formSubmitAttempt1: Boolean;
  brand = [];
  branchData = [];
  branchId: any;
  branchID: '';
  p: Number = 1;
  showAvailable: Boolean = true;

  ngOnInit() {
    this.branch = this.fb.group({
      branch_name: ['', Validators.required],
      branch_location: ['', Validators.required],
      brand_id: ['', Validators.required],
      branch_name_arabic: ['', Validators.required],
      take_out: [false],
      car_service: [false],
      staff_meal: [false],
      dine_in: [false],
      catering: [false],
      delivery: [false],
      branch_status: [true]
      // restaurant_type: ['', Validators.required]
    });
    this.branchModal = this.fb.group({
      branch_name: ['', Validators.required],
      branch_location: ['', Validators.required],
      branch_name_arabic: ['', Validators.required],
      brand_name: [''],
      branch_id: [''],
      take_out: [false],
      car_service: [false],
      staff_meal: [false],
      dine_in: [false],
      catering: [false],
      delivery: [false],
      branch_status: ['']
      // restaurant_type: ['', Validators.required]
    });
    this.brandDetails();
    this.getBranches();
    this.inputAlignment();
  }
  patchman1() {
    const one = this.branch.get('branch_name').value;
    this.branch.patchValue({ 'branch_name': one.replace(/^ +/gm, '') })
    const two = this.branch.get('branch_location').value;
    this.branch.patchValue({ 'branch_location': two.replace(/^ +/gm, '') })


  }

  inputAlignment() {
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  reset() {
    this.branch.reset();
    const key = {
      branch_name: '',
      branch_location: '',
      brand_id: '',
      branch_name_arabic: '',
      branch_status: true,
      // restaurant_type: '',
      ...this.service.segments
    };
    this.branch.setValue(key);
  }

  submitBranch() {
    const arrayData = [];
    this.branchData.map(res => {
      if (res.brand_id == this.branch.value.brand_id) {
        console.log('res.brand_id', res.brand_id);

        arrayData.push(res);
      }
    });
    console.log('arrayData', arrayData);
    if (
      !arrayData.some(
        r =>
          r.branch_name.toUpperCase().replace(/\s/g, '') ==
          this.branch.value.branch_name.toUpperCase().replace(/\s/g, '')
      )
    ) {
      if (
        !arrayData.some(
          r =>
            r.branch_name_arabic.replace(/\s/g, '') ==
            this.branch.value.branch_name_arabic.replace(/\s/g, '')
        )
      ) {
        this.submit();
      } else {
        this.service.openSnackBar('Branch arabic name already exists');
      }
    } else {
      this.service.openSnackBar('Branch name already exists');
    }
  }

  submit() {
    const formData = this.branch.value;
    if (
      formData.take_out == true ||
      formData.car_service == true ||
      formData.staff_meal == true ||
      formData.dine_in == true ||
      formData.catering == true ||
      formData.delivery == true
    ) {
      console.log('this.branch.value', this.branch.value);
      this.service.saveBranchDetails(this.branch.value).subscribe(
        result => {
          let payload: any = {
            brand_id: result.body.brand_id,
            branch_id: result.body.branch_id,
            transaction_date: new Date().toISOString().slice(0, 10)
          };
          this.service.openSnackBar('Branch Details Submitted');
          this.service.transactionDate(payload).subscribe(r => { });
          this.getBranches();
          this.reset();
          this.branch.patchValue({ branch_status: true });
        },
        error => {
          console.log('error', error);
          this.service.openSnackBar(error.error.message);
        }
      );
    } else {
      this.service.openSnackBar('Please select at least one segment');
    }
    this.formSubmitAttempt = true;
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  brandDetails() {
    this.service.getBrandDetails().subscribe(
      result => {
        this.brand = result.body;
      },
      error => {
        console.log('error', error);
      }
    );
  }

  getBranches() {
    this.service.getBranchDetails(`?raw=true`).subscribe(
      result => {
        this.branchData = result.body;
        this.backupBranch = result.body;
        this.showRegistrationData(this.branchData);
      },
      error => {
        console.log('error', error);
      }
    );
  }

  // checkChangedValue = () => {
  //   let isfieldChanged = false;
  //   const resEdit = {
  //     branch_name: this.branchData1.branch_name ? this.branchData1.branch_name : "",
  //     branch_location: this.branchData1.branch_location ? this.branchData1.branch_location : "",
  //     branch_name_arabic: this.branchData1.branch_name_arabic ? this.branchData1.branch_name_arabic : "",
  //     brand_name: this.branchData1.brand.brand_name ? this.branchData1.brand.brand_name : "",
  //     branch_id: this.branchData1.branch_id ? this.branchData1.branch_id : "",
  //     take_out: this.branchData1.take_out,
  //     car_service: this.branchData1.car_service,
  //     staff_meal: this.branchData1.staff_meal,
  //     dine_in: this.branchData1.dine_in,
  //     catering: this.branchData1.catering,
  //     delivery: this.branchData1.delivery,
  //     branch_status: this.branchData1.branch_status,
  //   }
  //   const formEdit = {
  //     branch_name: this.branchModal.value.branch_name ? this.branchModal.value.branch_name : "",
  //     branch_location: this.branchModal.value.branch_location ? this.branchModal.value.branch_location : "",
  //     branch_name_arabic: this.branchModal.value.branch_name_arabic ? this.branchModal.value.branch_name_arabic : "",
  //     brand_name: this.branchModal.value.brand_name ? this.branchModal.value.brand_name : "",
  //     branch_id: this.branchModal.value.branch_id ? this.branchModal.value.branch_id : "",
  //     take_out: this.branchModal.value.take_out ? this.branchModal.value.take_out : "",
  //     car_service: this.branchModal.value.car_service ,
  //     staff_meal: this.branchModal.value.staff_meal,
  //     dine_in: this.branchModal.value.dine_in,
  //     catering: this.branchModal.value.catering,
  //     delivery: this.branchModal.value.delivery,
  //     branch_status: this.branchModal.value.branch_status,
  //   }
  //   console.log(this.branchModal.value)
  //   console.log(
  //     "response value",
  //     JSON.stringify(resEdit)
  //   );
  //   console.log("form value",
  //     JSON.stringify(formEdit))
  //   if (JSON.stringify(resEdit) === JSON.stringify(formEdit)) {
  //     console.log("sss");
  //     isfieldChanged = false;
  //     return isfieldChanged;
  //   } else {
  //     isfieldChanged = true;
  //     return isfieldChanged;
  //   }
  // }

  editBranch = async (index, data) => {
    this.branchData1 = data;


    console.log(data)

    this.branchID = data.branch_id;
    if (data.brand.brand_name) {
      this.brandName = data.brand.brand_name;
    }
    this.branchModal.patchValue({
      branch_name: data.branch_name,
      brand_name: data.brand.brand_name,
      branch_location: data.branch_location,
      take_out: data.take_out,
      staff_meal: data.staff_meal,
      catering: data.catering,
      delivery: data.delivery,
      dine_in: data.dine_in,
      car_service: data.car_service,
      branch_id: data.branch_id,
      // restaurant_type: data.restaurant_type,
      branch_status: data.branch_status,
      branch_name_arabic: data.branch_name_arabic
    });
    this.branchModal.controls['brand_name'].disable();
  }

  changeEditStatus(status, array) {
    for (let i = 0; i < array.length; i++) {
      array[i].edit = status;
    }
    return array;
  }
  updateBranchData(i) {
    const arrayData = [];
    if (
      this.branchData1.branch_name == this.branchModal.value.branch_name &&
      this.branchData1.branch_name_arabic == this.branchModal.value.branch_name_arabic
    ) {
      return this.updateData(i);
    } else {
      this.branchData.map(res => {
        if (res.brand_id == this.branchData1.brand_id) {
          if (res.branch_id !== this.branchData1.branch_id) {
            arrayData.push(res);
          }
        }
      });
    }
    if (
      !arrayData.some(
        r =>
          r.branch_name.toUpperCase().replace(/\s/g, '') ==
          this.branchModal.value.branch_name.toUpperCase().replace(/\s/g, '')
      )
    ) {
      if (!arrayData.some(r => r.branch_name_arabic == this.branchModal.value.branch_name_arabic)) {
        this.updateData(i);
      } else {
        this.service.openSnackBar('Branch arabic name already exists');
      }
    } else {
      this.service.openSnackBar('Branch name already exists');
    }
  }
  updateData(index) {
    this.changeValue = {}
    if (this.branchData1.branch_location == this.branchModal.value.branch_location) {
    } else {
      this.changeValue.branch_location = this.branchModal.value.branch_location
    }
    if (this.branchData1.branch_name == this.branchModal.value.branch_name) {
    } else {
      this.changeValue.branch_name = this.branchModal.value.branch_name
    }
    if (this.branchData1.branch_name_arabic == this.branchModal.value.branch_name_arabic) {
    } else {
      this.changeValue.branch_name_arabic = this.branchModal.value.branch_name_arabic
    }
    if (this.branchData1.branch_id == this.branchModal.value.branch_id) {
    } else {
      this.changeValue.branch_id = this.branchModal.value.branch_id
    }
    if (this.branchData1.take_out == this.branchModal.value.take_out) {
    } else {
      this.changeValue.take_out = this.branchModal.value.take_out
    }
    if (this.branchData1.car_service == this.branchModal.value.car_service) {
    } else {
      this.changeValue.car_service = this.branchModal.value.car_service
    }
    if (this.branchData1.staff_meal == this.branchModal.value.staff_meal) {
    } else {
      this.changeValue.staff_meal = this.branchModal.value.staff_meal
    }
    if (this.branchData1.dine_in == this.branchModal.value.dine_in) {
    } else {
      this.changeValue.dine_in = this.branchModal.value.dine_in
    }
    if (this.branchData1.catering == this.branchModal.value.catering) {
    } else {
      this.changeValue.catering = this.branchModal.value.catering
    }
    if (this.branchData1.delivery == this.branchModal.value.delivery) {
    } else {
      this.changeValue.delivery = this.branchModal.value.delivery
    }
    if (this.branchData1.branch_status == this.branchModal.value.branch_status) {
    } else {
      this.changeValue.branch_status = this.branchModal.value.branch_status
    }
    this.changeValue.branch_id = this.branchModal.value.branch_id
    console.log(this.changeValue)



    this.service.updateBranchDetails(this.changeValue).subscribe(
      result => {
        if (result.status) {
          if (result.body.message) {
            return this.service.openSnackBar(result.body.message);
          }
          this.service.openSnackBar('Branch Updated!');
          this.getBranches();
          this.branchID = '';
        }

      },
      error => {
        console.log('error', error);
      }
    );
  }

  deleteData(data) {
    this.branchId = data.branch_id;
    this.popUp();
  }

  deleteBranch() {
    this.service.deleteBranchDetails(this.branchId).subscribe(
      result => {
        if (result.status) {
          if (result.body == 1) {
            this.getBranches();
            this.service.openSnackBar('Branch Deleted!');
            this.popUpOff();
          }
          if (result.body.message) {
            this.service.openSnackBar('Order already taken for the branch');
            this.popUpOff();
          }
        }

      },
      error => {
        this.service.openSnackBar(error.error.message);
        this.popUpOff();
      }
    );
  }

  isFieldValid(field: string) {
    return (
      (!this.branch.get(field).valid && this.branch.get(field).touched) ||
      (this.branch.get(field).untouched && this.formSubmitAttempt)
    );
  }

  isFieldValid1(field: string) {
    return (
      (!this.branchModal.get(field).valid && this.branchModal.get(field).touched) ||
      (this.branchModal.get(field).untouched && this.formSubmitAttempt1)
    );
  }

  showRegistrationData(data) {
    if (data.length > 0) {
      this.showAvailable = false;
      this.noMatchingResults = false;
      if (data.length > 5) {
      } else {
        if (data.length === 0) {
          this.noMatchingResults = true;
        } else {
          this.noMatchingResults = false;
        }
      }
    } else {
      this.showAvailable = true;
    }
  }

  applyFilter(filter: String) {
    this.branchData = this.backupBranch.filter(item => {
      if (
        item.branch_name
          .toString()
          .toLowerCase()
          .indexOf(filter.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
    this.showRegistrationData(this.branchData);
  }
}
