import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
@Component({
  selector: 'app-sales-summary',
  templateUrl: './sales-summary.component.html',
  styleUrls: ['./sales-summary.component.scss']
})
export class SalesSummaryComponent implements OnInit {
  @ViewChild('myCanvas')
  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;
  public chartType = 'line';
  public chartData: any = [];
  public chartLabels: any = ['20-08-2019', '19-08-2019', '18-08-2019', '17-08-2019', '16-08-2019'];
  public chartColors: any = [];
  public finalChartData: any = [];
  brands: any;
  branches: any;
  salesReport: FormGroup;
  enableBranch: Boolean = false;
  showAvailable = true;
  showGroup: boolean;
  maxDate = new Date();
  fromdate: string;
  todate: string;
  fullQuery: string;
  brandReport: any;
  reportByDate: any;
  raw: any;
  maxToDate = new Date();
  maxFromDate = new Date();
  minToDate: any;
  grandTotal: number;
  grandTotal1: number;
  dineInTotal: number;
  takeOutTotal: number;
  deliveryTotal: number;
  carServiceTotal: number;
  cateringTotal: number;
  staffMealTotal: number;
  dineInTotal1: number;
  takeOutTotal1: number;
  deliveryTotal1: number;
  carServiceTotal1: number;
  cateringTotal1: number;
  staffMealTotal1: number;
  showPrint: boolean;
  showPrint1: boolean;
  systemDate: any;
  summaryGraph: any = [];
  loading: Boolean = false;

  constructor(
    public service: PosBackOfficeService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private adapter: DateAdapter<any>,
    public ls: LocalStorageService
  ) {}

  ngOnInit() {
    this.salesReport = this.fb.group({
      brand: 'All Brands',
      branch: '',
      fromDate: [new Date().toISOString().slice(0, 10)],
      toDate: [new Date().toISOString().slice(0, 10)]
    });
    this.getBrand();
    this.getReport(1);
    this.getReportByDate(1);
    this.dateAdapter();
    const date = new Date();
    this.systemDate = this.datePipe.transform(date, 'dd-MM-yyyy HH:mm');
    this.chartData = [{ data: [65, 59, 80, 81, 56], label: 'Sales', fill: false, lineTension: 0}];

    this.chartColors = [
      {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderColor: ' gray'
      }
    ];
    this.summaryGraphReport();
    this.dateValidation();
  }
  dateValidation() {
    this.minToDate = this.salesReport.value.fromDate;
    this.maxFromDate = this.salesReport.value.toDate;
  }
  getBranch(brand) {
    console.log("brand clicked")
    this.salesReport.patchValue({
      branch: ''
    });
    this.enableBranch = true;
    this.service.Branches(`?brand_id=${brand}`).subscribe(r => {
      this.branches = r.body;
      this.summaryGraphReport()
    });
  }

  getBrand() {
    console.log("getBrand")
    this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;
      },
      error => {
        console.log('error', error);
      }
    );
  }

  sortDate() {
    console.log("sortDate")
    this.dateValidation();
    let sDate: any = this.datePipe.transform(this.salesReport.value.fromDate, 'yyyy-MM-dd');
    let eDate: any = this.datePipe.transform(this.salesReport.value.toDate, 'yyyy-MM-dd');
    this.getReport(
      this.raw,
      this.salesReport.value.brand,
      this.salesReport.value.branch,
      sDate,
      eDate
    );
    this.getReportByDate(
      this.raw,
      this.salesReport.value.brand,
      this.salesReport.value.branch,
      sDate,
      eDate
    );

    this.summaryGraphReport()
  }

  getReport(raw?: any, brand_id?: any, branch_id?: any, sDate1?: any, eDate1?: any) {
    let sDate: any = this.datePipe.transform(this.salesReport.value.fromDate, 'yyyy-MM-dd');
    let eDate: any = this.datePipe.transform(this.salesReport.value.toDate, 'yyyy-MM-dd');
    this.raw = raw;
    this.service
      .getBrandSalesReport(
        `?raw=${raw}&brand_id=${brand_id}&branch_id=${branch_id}&sDate=${sDate}&eDate=${eDate}`
      )
      .subscribe(r => {
        this.brandReport = r.body;
        this.findTotal(this.brandReport);
      });
  }

  getReportByDate(raw?: any, brand_id?: any, branch_id?: any, sDate1?: any, eDate1?: any) {
    let sDate: any = this.datePipe.transform(this.salesReport.value.fromDate, 'yyyy-MM-dd');
    let eDate: any = this.datePipe.transform(this.salesReport.value.toDate, 'yyyy-MM-dd');
    this.raw = raw;
    this.service
      .getBrandSalesReportByDate(
        `?raw=${raw}&brand_id=${brand_id}&branch_id=${branch_id}&sDate=${sDate}&eDate=${eDate}`
      )
      .subscribe(r => {
        this.reportByDate = r.body;
        this.findTotalByDate(this.reportByDate);
      });
  }

  findTotal(data) {
    console.log("findTotal")
    this.dineInTotal = data.reduce(function(cnt, o) {
      return cnt + (o.segment.dine_in == undefined ? 0 : o.segment.dine_in.total);
    }, 0);
    this.takeOutTotal = data.reduce(function(cnt, o) {
      return cnt + (o.segment.take_out == undefined ? 0 : o.segment.take_out.total);
    }, 0);
    this.deliveryTotal = data.reduce(function(cnt, o) {
      return cnt + (o.segment.delivery == undefined ? 0 : o.segment.delivery.total);
    }, 0);
    this.carServiceTotal = data.reduce(function(cnt, o) {
      return cnt + (o.segment.car_service == undefined ? 0 : o.segment.car_service.total);
    }, 0);
    this.cateringTotal = data.reduce(function(cnt, o) {
      return cnt + (o.segment.catering == undefined ? 0 : o.segment.catering.total);
    }, 0);
    this.staffMealTotal = data.reduce(function(cnt, o) {
      return cnt + (o.segment.staff_meal == undefined ? 0 : o.segment.staff_meal.total);
    }, 0);
  }

  findTotalByDate(data) {
    console.log("findTotalByDate")
    this.dineInTotal1 = data.reduce(function(cnt, o) {
      return cnt + (o.segment.dine_in == undefined ? 0 : o.segment.dine_in.total);
    }, 0);
    this.takeOutTotal1 = data.reduce(function(cnt, o) {
      return cnt + (o.segment.take_out == undefined ? 0 : o.segment.take_out.total);
    }, 0);
    this.deliveryTotal1 = data.reduce(function(cnt, o) {
      return cnt + (o.segment.delivery == undefined ? 0 : o.segment.delivery.total);
    }, 0);
    this.carServiceTotal1 = data.reduce(function(cnt, o) {
      return cnt + (o.segment.car_service == undefined ? 0 : o.segment.car_service.total);
    }, 0);
    this.cateringTotal1 = data.reduce(function(cnt, o) {
      return cnt + (o.segment.catering == undefined ? 0 : o.segment.catering.total);
    }, 0);
    this.staffMealTotal1 = data.reduce(function(cnt, o) {
      return cnt + (o.segment.staff_meal == undefined ? 0 : o.segment.staff_meal.total);
    }, 0);
  }
  dateAdapter() {
    this.adapter.setLocale('en-GB');
  }

  printSalesSummary() {
    setTimeout(() => {
      this.printReceipt('sale_summary_print');
    }, 1000);
    this.showPrint1 = true;
  }
  printReceipt(div): void {
    let printContents, popupWin;
    printContents = document.getElementById(div).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Sales Report</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  printSalesByDate() {
    setTimeout(() => {
      this.printReceipt1('sale_by_date_print');
    }, 1000);
    this.showPrint = true;
  }
  printReceipt1(div): void {
    let printContents, popupWin;
    printContents = document.getElementById(div).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Sales Report</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  netReportBrand() {
    console.log("netReportBrand")
    this.getReport(1);
    this.getReportByDate(1);
    this.salesReport.patchValue({
      branch: 'All Branches'
    });
    this.enableBranch = false;
  }

  netReportBranch() {
    console.log("netReportBranch")
    this.getReport(
      2,
      this.salesReport.value.brand,
      '',
      this.salesReport.value.fromDate,
      this.salesReport.value.toDate
    );
    this.getReportByDate(
      2,
      this.salesReport.value.brand,
      '',
      this.salesReport.value.fromDate,
      this.salesReport.value.toDate
    );
  }
  summaryGraphReport() {
    this.chartLabels = [];
    this.chartData[0].data = [];
    let query=''
    let sDate: any = this.datePipe.transform(this.salesReport.value.fromDate, 'yyyy-MM-dd');
    let eDate: any = this.datePipe.transform(this.salesReport.value.toDate, 'yyyy-MM-dd');

    let f_date = new Date(sDate);
    let t_date = new Date(eDate);

    f_date.setUTCHours(0,0,0,0);
    t_date.setUTCHours(23,59,59,59)

    let from= f_date.toISOString();
    let to= t_date.toISOString();
    console.log('f_date.toISOString()',from,'f_date.toISOString()',to)

    if(this.salesReport.value.brand == 'All Brands'){
      query=`?fromdate=${from}&todate=${to}`
    }else{
      query=`?fromdate=${from}&todate=${to}&filterByBrand=1&filterBranch=0&brand_id=${this.salesReport.value.brand}`
    }


    // console.log("brandsssss",this.salesReport.value.brand)
    // console.log("branchhhhh",this.salesReport.value.branch)
    // console.log("fromdate",this.salesReport.value.fromDate)
    // console.log("branchhhhh",this.salesReport.value.toDate)
    this.service.dashboardSalesSummary('').subscribe(r => {
      this.summaryGraph = r.body;
      this.summaryGraph.forEach(e => {
        this.chartData[0].data.push(e.total);
        this.chartLabels.push(this.datePipe.transform(e.transaction_date.slice(0, 10), 'dd-MM'));
      });
      console.log("summaryGraph",this.summaryGraph)
      console.log("this.chartData",this.chartData);
      console.log("chartLabels",this.chartLabels)
      this.loading = true;
    });
  }
}
