import { Component, OnInit } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';

import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import {
  CookiesStorageService,
  LocalStorageService,
  SessionStorageService,
  SharedStorageService
} from 'ngx-store';
import { DateAdapter } from '@angular/material';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-discount-report',
  templateUrl: './discount-report.component.html',
  styleUrls: ['./discount-report.component.scss']
})
export class DiscountReportComponent implements OnInit {
  discountReport: FormGroup;
  discountReports:any;
  brands: any;
  p: any;
  branches: any;
  enableBranch: Boolean = false;
  showReport: Boolean = false;
  defaultQuery;
  items;
  maxFromDate = new Date();
  maxToDate = new Date();
  minToDate: any;
  fromdate;
  todate;
  queryParams = {
    Brand: 0,
    Branch: 0
  };
  totalAmount:any;
  totalDiscount:any;
  totalnetAmount:any;
  showPrint:boolean = false;
  branchName:any;
  brandName:any;
  systemDate: string;
  supplierName: any;
  maxDate = new Date();
  constructor(private fb: FormBuilder, private datePipe: DatePipe, public service: PosBackOfficeService, public ls: LocalStorageService, private adapter: DateAdapter<any>) { }

  ngOnInit() {
  	this.discountReport = this.fb.group({
  		brand: [''],
  	  branch: [''],
      fromDate: [new Date().toISOString().slice(0, 10)],
      toDate: [new Date().toISOString().slice(0, 10)]
  	})
    this.systemDate = this.datePipe.transform(this.maxDate, 'yyyy-MM-dd HH:mm');
  	this.getBrand();
    this.dateAdapter();
    this.dateValidation();

  }
  dateAdapter() {
    this.adapter.setLocale('en-GB');
  }
  dateValidation() {
    this.minToDate = this.discountReport.value.fromDate;
    this.maxFromDate = this.discountReport.value.toDate;
  }
  getBranch(brand) {
    this.fromdate = this.datePipe.transform(this.discountReport.value.fromDate, 'yyyy-MM-dd');
    this.todate = this.datePipe.transform(this.discountReport.value.toDate, 'yyyy-MM-dd');
  	this.discountReport.patchValue({
  	  'branch':''
  	})
  	this.enableBranch = true;
    this.service.Branches(`?brand_id=${brand}`).subscribe(r => {
      this.branches = r.body;
    });
    this.getReport(
      this.defaultQuery = `?filterbyBrand=${this.queryParams.Brand}&brandId=${this.discountReport.value.brand}&filterbyBranch=${this.queryParams.Branch}&branchId=${this.discountReport.value.branch}&fromDate=${this.fromdate}&toDate=${this.todate}&discount=1`
      );
    
  }

  getBrand() {

  	 this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;
      },
      error => {
        console.log('error', error);
      });
      
  }
 
  findTotal(data) {
    this.totalAmount = data.reduce(function(cnt, o) {
      return cnt + o.total_cost;
    }, 0);
    this.totalDiscount = data.reduce(function(cnt, o) {
      return cnt + o.discount_amount;
    }, 0);
    this.totalnetAmount = data.reduce(function(cnt, o) {
      return cnt + o.net_cost;
    }, 0);
   
  }
  getBranchName(data) {
    let returnBranch = ''
    const queryBranch = this.branches.filter(res => res.branch_id == data )
    if(queryBranch.length !== 0) {
      console.log('queryBranch', queryBranch)
      returnBranch =  queryBranch[0].branch_name ;
    }
    return returnBranch;
  }
  getBrandName(data) {
    let returnBrand = 'NA'
    const queryBrand = this.brands.filter(res => res.brand_id == data )
    if(queryBrand.length !== 0) {
      console.log('queryBrand', queryBrand)
      returnBrand =  queryBrand[0].brand_name ;
    }
    return returnBrand;
  }
  
  getReport(value) {
    this.fromdate = this.datePipe.transform(this.discountReport.value.fromDate, 'yyyy-MM-dd');
    this.todate = this.datePipe.transform(this.discountReport.value.toDate, 'yyyy-MM-dd');
    const queryBrand = this.discountReport.value.brand || '';
    const queryBranch = this.discountReport.value.branch || '';
    let query = '?'
    
    if (value == 'brand' || this.discountReport.value.brand) {
      this.queryParams.Brand = 1;
      query = query + `filterbyBrand=${this.queryParams.Brand !== 0 ? 1 : 0}&brandId=${queryBrand}&`
    }
    if (value == 'branch' || this.discountReport.value.branch) {
      this.queryParams.Branch = 1;
      query = query + `filterbyBranch=${this.queryParams.Branch != 0 ? 1 : 0}&branchId=${queryBranch}&`
    }
    
    query = `${value}`
    console.log("query", query.slice(0, -1))
    this.defaultQuery = `?filterbyBrand=${this.queryParams.Brand}&brandId=${this.discountReport.value.brand}&filterbyBranch=${this.queryParams.Branch}&branchId=${queryBranch}&fromDate=${this.fromdate}&toDate=${this.todate}&discount=1`;
    if (!query) {
      query = this.defaultQuery;
    }
    this.discountReports = [];
    this.service.getSalesData(query).subscribe(r => {
      if (r.body) {
        this.discountReports = r.body;
        if (this.discountReports.length > 0) {
          this.showReport = true;
        } else {
          this.showReport = false;
        }
        this.findTotal(this.discountReports);
        this.showReport = true;
      }
    });
  }
  
  filterByDate() {
    this.brandName = this.discountReport.value.brand;
    this.branchName = this.discountReport.value.branch;
    this.fromdate =
      this.datePipe.transform(this.discountReport.value.fromDate, 'yyyy-MM-dd') ||
      this.datePipe.transform(this.discountReport.value.toDate, 'yyyy-MM-dd') ||
      '';
    this.todate =
      this.datePipe.transform(this.discountReport.value.toDate, 'yyyy-MM-dd') || this.fromdate || '';
    this.getReport(
      this.defaultQuery = `?filterbyBrand=${this.queryParams.Brand}&brandId=${this.discountReport.value.brand}&filterbyBranch=${this.queryParams.Branch}&branchId=${this.discountReport.value.branch}&fromDate=${this.fromdate}&toDate=${this.todate}&discount=1`
      );
    
  }
  print() {
    setTimeout(() => {
      this.printReceipt('discount_print');
    }, 500);
    this.showPrint = true;
  }
  printReceipt(div): void {
    let printContents, popupWin;
    printContents = document.getElementById(div).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Sales Report</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

}
