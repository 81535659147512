import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-customer-report',
  templateUrl: './customer-report.component.html',
  styleUrls: ['./customer-report.component.scss']
})
export class CustomerReportComponent implements OnInit {
  customer: FormGroup;
  allusers: any;
  brands: any;
  areaName: string;
  areas: any;
  areaid: any;
  blocksToDisplay: any;
  typeOfCustomer: any;
  brandId: any;
  segment: any;
  blockId: any;
  fDate: any;
  eDate: any;
  customerReport: any;
  p: Number = 1;

  constructor(private fb: FormBuilder, public service: PosBackOfficeService,
    public ls: LocalStorageService, public datePipe: DatePipe) { }

  ngOnInit() {
    this.customer = this.fb.group({
      fromDate: [''],
      toDate: [''],
      brand: [''],
      segment: [''],
      area: [''],
      block: ['']
    })
    this.customer.patchValue({ fromDate: new Date(), toDate: new Date() })
    this.getAllUsers();
    this.getBrand();
    this.getAreas();
    this.fromDate();
    this.toDate();
  }
  getAllUsers() {
    this.service.getAgentUsers().subscribe(res => {
      this.allusers = res;
    })
  }
  fromDate() {
    console.log("date", this.datePipe.transform(this.customer.value.fromDate, 'yyyy-MM-dd'))
    let f_date = this.datePipe.transform(this.customer.value.fromDate, 'yyyy-MM-dd')
    console.log("fdate", f_date + 'T' + '00:00:00')
    this.fDate = f_date + 'T' + '00:00:00.00'
    // this.fDate.setUTCHours(0,0,0,0);
    console.log(this.fDate)
    this.getCustomerDetail()
  }
  toDate() {
    console.log("date", this.datePipe.transform(this.customer.value.toDate, 'yyyy-MM-dd'))
    let e_Date = this.datePipe.transform(this.customer.value.toDate, 'yyyy-MM-dd')
    console.log("fdate", this.eDate + 'T' + '23:59:59.59')
    this.eDate = e_Date + 'T' + '23:59:59.59'
    console.log(this.eDate)
    this.getCustomerDetail()
  }
  selectBrand(brand) {
    console.log(brand)
    this.brandId = brand.brand_id;
    this.getCustomerDetail()
  }
  ccsegment(name) {
    console.log(name)
    this.segment = name;
    this.getCustomerDetail()
  }
  getAreas = () => {
    console.log("english", this.ls.get('language'));
    if (this.ls.get('language') == 'en') {
      this.areaName = 'area_name_english_ASC'
    } else {
      this.areaName = 'area_name_arabic_ASC'
    }
    const quey = `?area_id=all&sort=${this.areaName}`;
    this.service.getAreas(quey).subscribe((area) => {
      this.areas = area.body;
      console.log(this.areas)
    });
  };
  selectArea(area) {
    console.log('entered', area)
    console.log('blockid', this.blockId)
    this.blockId = ''
    if (area == 'allAreas') {
      this.areaid = 'allAreas'
    } else if (area.area_id) {
      this.areaid = area.area_id
    } else {
      this.areaid = area
    }
    // .globalarea.area_id
    const query = `?area_id=${this.areaid}&sort=blocks_ASC`;
    console.log("query", query)
    this.service.getBlocksbyAreaid(query).subscribe(res => {
      console.log("res", res)
      this.blocksToDisplay = res.body;
      console.log("this.blocksToDisplay", this.blocksToDisplay)
    })
    this.getCustomerDetail()
  }


  getBrand() {
    this.service.brands('get').subscribe(result => {
      this.brands = result.body;
      console.log("this.brands", this.brands)
    },
      error => {
        console.log('error', error);
      }
    );
  }
  customerType(value) {
    console.log(value)
    this.typeOfCustomer = value
    this.getCustomerDetail()
  }
  selectBlock(value) {
    console.log(value)
    this.blockId = value.block_id;
    this.getCustomerDetail()
  }
  getCustomerDetail() {
    let query = '?'
    if (this.typeOfCustomer) {
      query = query + `customerType=${this.typeOfCustomer}&`
    }
    if (this.brandId) {
      query = query + `brandid=${this.brandId}&`
    }
    if (this.fDate) {
      query = query + `fromdate=${this.fDate}&`
    }
    if (this.eDate) {
      query = query + `todate=${this.eDate}&`
    }
    if (this.segment) {
      query = query + `segment=${this.segment}&`
    }
    if (this.areaid) {
      if (this.areaid != 'allAreas') {
        query = query + `areaid=${this.areaid}&`
      }
      if (this.areaid == 'allAreas') {
        query = query + `area=${this.areaid}&`
      }
    }

    if (this.blockId) {
      query = query + `blockid=${this.blockId}&`
    }
    query = `${query}`

    console.log("query", query.slice(0, -1))
    // query = `?customerType=${this.typeOfCustomer}&brandid=${this.brandId}&fromdate=${this.fDate}&todate=${this.eDate}&segment=${this.segment}&areaid=${this.areaid}&blockid=${this.blockId}`
    this.service.getCustomerReport(query.slice(0, -1)).subscribe(res => {
      console.log("res", res)
      this.customerReport = res.body;
    })
  }
  clearFilter() {
    this.typeOfCustomer = '';
    this.brandId = '';
    this.fDate = '';
    this.eDate = '';
    this.segment = '';
    this.areaid = '';
    this.blockId = '';
    this.fromDate();
    this.toDate();
    this.getCustomerDetail()
  }

}
