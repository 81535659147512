import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import {
  FormBuilder,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.scss']
})
export class PrinterComponent implements OnInit {
  printCategory: FormGroup;
  printerEdit: FormGroup;
  formSubmitAttempt: Boolean;
  printerDetails: any;
  brand: any;
  branch: any;
  printId: '';
  printerData: any;
  payload: any;
  p: Number = 1;
  showAvailable: Boolean = true;
  editPrintData: any;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) {}

  ngOnInit() {
    this.printCategory = this.fb.group({
      printer_name: ['', Validators.required],
      printer_ip: ['', Validators.required],
      printer_description: ['', Validators.required],
      printer_status: [true]
    });
    this.printerEdit = this.fb.group({
      printer_name: ['', Validators.required],
      printer_ip: ['', Validators.required],
      printer_description: ['', Validators.required],
      printer_status: '',
      printer_id: ''
    });
    this.brandData();
    this.printDetails('get');
    // this.branchData();
  }
  patchman1(){
    const one =this.printCategory.get('printer_name').value;
    this.printCategory.patchValue({'printer_name':one.replace(/^ +/gm, '')})
    const two =this.printCategory.get('printer_ip').value;
    this.printCategory.patchValue({'printer_ip':two.replace(/^ +/gm, '')})
    const three =this.printCategory.get('printer_ip').value;
    this.printCategory.patchValue({'printer_ip':three.replace(/^ +/gm, '')})
    const four =this.printCategory.get('printer_description').value;
    this.printCategory.patchValue({'printer_description':four.replace(/^ +/gm, '')})
  }

  brandData() {
    this.service.getBrandDetails().subscribe(result => {
      this.brand = result.body;
    });
  }

  getBranch(brand) {
    this.service.Branches(`?brand_id=${brand}`).subscribe(r => {
      this.branch = r.body;
    });
  }

  isFieldValid(field: string) {
    return (
      (!this.printCategory.get(field).valid && this.printCategory.get(field).touched) ||
      (this.printCategory.get(field).untouched && this.formSubmitAttempt)
    );
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  deletePrinter(data) {
    this.popUp();
    this.printerData = data;
  }

  delete() {
    this.printDetails('delete', this.printerData.printer_id, `?id=`);
  }

  editPrinter(data) {
    this.printId = data.printer_id;
    this.editPrintData = data;
    this.printerEdit.patchValue(data);
  }

  updatePrinter(data) {
    const data1 = data;
    const arrayData = [];
    this.payload = {
      printer_name: data1.printer_name,
      printer_ip: data1.printer_ip,
      printer_description: data1.printer_description,
      printer_status: data1.printer_status,
      id: data1.printer_id
    };
    console.log("data1", data1);
    console.log("editPrintData", this.editPrintData);
    if ((data1.printer_name).toUpperCase().replace(/\s/g, '')
     === (this.editPrintData.printer_name).toUpperCase().replace(/\s/g, '')
      && (data1.printer_ip).toUpperCase().replace(/\s/g, '')
       === (this.editPrintData.printer_ip).toUpperCase().replace(/\s/g, '')) {
      this.printDetails('put', this.payload, '');
      console.log('1');
    } else{
      this.printerDetails.forEach(element => {
        if (element.printer_id !== data.printer_id) {
          arrayData.push(element);
        }
      });
      console.log(arrayData);
      this.printDetails('put', this.payload, '');
      // if (!arrayData.some(r => (r.printer_name).toUpperCase().replace(/\s/g, '')
      //  ===  (data.printer_name).toUpperCase().replace(/\s/g, ''))) {
      //   if (!arrayData.some(r => (r.printer_ip).toUpperCase().replace(/\s/g, '')
      //  === (data.printer_ip).toUpperCase().replace(/\s/g, ''))) {
      //     this.printDetails('put', this.payload, '');
      //     console.log('11111')
      //   } else {
      //     this.service.openSnackBar('Duplicate ip address');
      //   }
      // } else {
      //   this.service.openSnackBar('Duplicate printer name');
      // }
    }
  }

  printDetails1(rule, payload) {
    console.log('payload', payload);
    if (this.printerDetails.length > 0) {
      this.printDetails(rule, payload);
      // if (!this.printerDetails.some(r => (r.printer_name).toUpperCase().replace(/\s/g, '') 
      //    === (payload.printer_name).toUpperCase().replace(/\s/g, ''))) {
      //   if (!this.printerDetails.some(r => (r.printer_ip).toUpperCase().replace(/\s/g, '')
      //      === (payload.printer_ip).toUpperCase().replace(/\s/g, ''))) {
      //     this.printDetails(rule, payload);
      //     console.log('1')
      //   } else {
      //     this.service.openSnackBar('Duplicate ip address');
      //   }
      // } else {
      //   this.service.openSnackBar('Duplicate printer name');
      // }
    } else {
      this.printDetails(rule, payload);
      console.log('2');
    }
  }

  printDetails(rule, payload = '', query = '') {
    this.service.printers(rule, payload, query).subscribe(
      result => {
        if (rule === 'get') {
          this.printerDetails = result.body;
          if (this.printerDetails.length > 0) {
            this.showAvailable = false;
          } else {
            this.showAvailable = true;
          }
        }
        if (rule === 'delete') {
          this.printDetails('get');
          this.service.openSnackBar('Printer Deleted!');
          this.popUpOff();
        }
        if (rule === 'post') {
          this.service.openSnackBar('Printer Added!');
          this.printDetails('get');
          this.printCategory.reset();
          this.printCategory.patchValue({ printer_status: true });
        }
        if (rule === 'put') {
          this.service.openSnackBar('Printer Updated!');
          this.printDetails('get');
          this.printId = '';
        }
      },
      error => {
        this.service.openSnackBar(error.error.message || 'Internal Server Error');
      }
    );
  }
}
