import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { Observable, of } from "rxjs";
import { environment } from "../../environments/environment";
import {
  MatSnackBar,
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { catchError, map, tap, switchAll } from "rxjs/operators";
import {
  CookiesStorageService,
  LocalStorageService,
  SessionStorageService,
  SharedStorageService,
} from "ngx-store";
import { query } from '@angular/core/src/render3';

@Injectable({
  providedIn: "root",
})
export class PosBackOfficeService {
  updatePassword(payload: {
    _id: any; newPassword: any;
    // printers
    oldPassword: any;
  }) {
    throw new Error('Method not implemented.');
  }
  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    public ls: LocalStorageService // public globalDate: any
  ) {
    const i = this.ls.get("token");
  }
  private posBackOfficeApi: string = environment.posBackOfficeServiceUrl;
  private AuthApi: any = environment.authServiceUrl;
  public printApi:any;

  public backgroundClr = this.ls.get("color");
  authHeader = this.updateToken();

  public segmentForm = [
    { name: "Take Out", key: "take_out" },
    { name: "Car Service", key: "car_service" },
    { name: "Staff Meal", key: "staff_meal" },
    { name: "Dine In", key: "dine_in" },
    { name: "Catering", key: "catering" },
    { name: "Delivery", key: "delivery" },
    { name: "CC_Delivery", key: "cc_delivery" },
    { name: "CC_Pickup", key: "cc_pickup" },
  ];
	
  public segments = {
    take_out: false,
    car_service: false,
    staff_meal: false,
    dine_in: false,
    catering: false,
    delivery: false,
  };
  public user = new Subject<object>();
  public colorAccent = new Subject<any>();

  updateToken() {
    return {
      headers: new HttpHeaders({ "Content-Type": "application/json" }).append(
        "Authorization",
        `Bearer ${this.ls.get("token") ? this.ls.get("token") : ""}`
      ),
    };
  }
  getToken() {
    return this.ls.get("token");
  }
  User(val) {
    this.user.next(val);
  }
  accent(val) {
    this.colorAccent.next(val);
  }

  openSnackBar(message) {
    this.snackBar.open(message, "close", {
      duration: 3000,
      verticalPosition: "bottom",
      horizontalPosition: "right",
      panelClass: ["snackbarStyle"],
    });
  }

  /* image */

  upload(data): Observable<any> {
    return this.http.post(this.posBackOfficeApi + `image`, data);
  }

  getImage(id) {
    return this.http.get(this.posBackOfficeApi + `image?id=${id}`);
  }

  /* menu */

  Menu(rule, data: any = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeApi + `menu${query}`);
      case "post":
        return this.http.post(this.posBackOfficeApi + "menu", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "menu", data);
      case "delete":
        return this.http.delete(this.posBackOfficeApi + `menu${query}` + data);
    }
  }

  /* menu */

  newMenu(rule, data: any = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeApi + `menus${query}`);
      case "post":
        return this.http.post(this.posBackOfficeApi + "menus", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "menus", data);
      case "delete":
        return this.http.delete(this.posBackOfficeApi + `menus${query}` + data);
    }
  }

  /*items*/

  Item(rule, data: any = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeApi + `items${query}` + data);
      case "post":
        return this.http.post(this.posBackOfficeApi + "items", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "items", data);
      case "delete":
        return this.http.delete(
          this.posBackOfficeApi +
          `items${query}` +
          data.item_id +
          `&brand_id=` +
          data.brand_id
        );
    }
  }

  /* modifier catalog */

  modifierCatalog(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeApi + "modifiercatalog");
      case "post":
        return this.http.post(this.posBackOfficeApi + "modifiercatalog", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "modifiercatalog", data);
      case "delete":
        return this.http.delete(
          this.posBackOfficeApi + `modifiercatalog${query}` + data
        );
    }
  }

  /* menu item group*/

  MenuItemGroup(rule, data: any = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(
          this.posBackOfficeApi + `menuitemgroups${query}` + data
        );
      case "post":
        return this.http.post(this.posBackOfficeApi + "menuitemgroups", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "menuitemgroups", data);
      case "delete":
        return this.http.delete(
          this.posBackOfficeApi +
          `menuitemgroups${query}` +
          data.group_id +
          `&brand_id=` +
          data.brand_id
        );
    }
  }

  /* modifier group*/

  modifierGroup(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(
          this.posBackOfficeApi + `modifiergroups${query}` + data
        );
      case "post":
        return this.http.post(this.posBackOfficeApi + "modifiergroups", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "modifiergroups", data);
      case "delete":
        return this.http.delete(
          this.posBackOfficeApi + `modifiergroups${query}` + data
        );
    }
  }

  /* discount */

  Discount(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeApi + `discounts${query}`);
      case "post":
        return this.http.post(this.posBackOfficeApi + "discounts", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "discounts", data);
      case "delete":
        return this.http.delete(
          this.posBackOfficeApi + `discounts${query}` + data
        );
    }
  }

  /*Initial Admin Register */
  // Sales reports
  getSalesData(query): Observable<any> {
    // console.log("hit api", this.http.get(this.posBackOfficeApi + `report${query}`))
    return this.http.get(this.posBackOfficeApi + `report${query}`);
  }

  getHourlyReport(query): Observable<any>{
    return this.http.get(this.posBackOfficeApi + `report/hoursReport${query}`)
  }

  reportData(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "report/groups" + query);
  }

  getUserListForManager(query): Observable<any> {
    return this.http.get(this.AuthApi + "users" + query);
  }
  getManagerShiftData(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + 'manager' + query);
  }

  getAllUser(): Observable<any> {
    return this.http.get(this.AuthApi, this.authHeader);
  }

  deleteUser(id): Observable<any> {
    return this.http.delete(this.AuthApi + `?_id=${id}`, this.authHeader);
  }

  adminRegister(payload): Observable<any> {
    return this.http.post(this.AuthApi, payload);
  }
  getAgentUsers(): Observable<any> {
    return this.http.get(this.AuthApi);
  }

  updateUser(payload): Observable<any> {
    return this.http.put(this.AuthApi, payload, this.authHeader);
  }

  login(payload): Observable<any> {
    return this.http.post(this.AuthApi + "login", payload);
  }
  /* items */

  /* brands */
  brands(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeApi + `brands${query}`);
      case "post":
        return this.http.post(this.posBackOfficeApi + "brands", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "brands", data);
      case "delete":
        return this.http.delete(this.posBackOfficeApi + "brands/" + query);
      case "get1":
        return this.http.get(
          this.posBackOfficeApi + "brands/branches/" + query
        );
    }
  }

  /* Branches */
  Branches(query = ""): Observable<any> {
    return this.http.get(this.posBackOfficeApi + `branches${query}`);
  }

  /*Menu Category */
  menuCategory(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeApi + `category${query}`);
      case "post":
        return this.http.post(this.posBackOfficeApi + "category", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "category", data);
      case "delete":
        return this.http.delete(
          this.posBackOfficeApi + `category${query}` + data
        );
    }
  }
  /* Category Catalog */
  menuCategoryCatalog(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(
          this.posBackOfficeApi + `categoryCatalog${query}` + data
        );
      case "post":
        return this.http.post(this.posBackOfficeApi + "categoryCatalog", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "categoryCatalog", data);
      case "delete":
        return this.http.delete(
          this.posBackOfficeApi + `categoryCatalog${query}` + data
        );
    }
  }

  saveBrandDetails(brand): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "brands", brand);
  }

  getBrandDetails(): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "brands");
  }
  getSupplierDetails(data): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "suppliers" + data);
  }
  updateBrandDetails(data): Observable<any> {
    return this.http.put(this.posBackOfficeApi + "brands", data);
  }

  deleteBrandDetails(brandId): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "brands/?id=" + brandId);
  }
  getPaymentData(): Observable<any>{
    return this.http.get(this.posBackOfficeApi + 'cardTypes')
  }
  updatePaymentData(data): Observable<any>{
    return this.http.put(this.posBackOfficeApi + 'cardTypes',data)
  }
  deletePayment(id): Observable<any>{
    return this.http.delete(this.posBackOfficeApi + `cardTypes${id}`)
  }
  cardTypesaddPayment(payload): Observable<any> {
    return this.http.post(this.posBackOfficeApi + 'cardTypes', payload)
  }
  // printers

  printer(payload): Observable<any> {
    return this.http.post(this.printApi + "printers", payload);
  }

  fetchPrinter(): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "printers");
  }

  printers(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeApi + "printers");
      case "post":
        return this.http.post(this.posBackOfficeApi + "printers", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "printers", data);
      case "delete":
        return this.http.delete(
          this.posBackOfficeApi + `printers${query}` + data
        );
    }
  }

  /* branches */
  saveBranchDetails(body): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "branches", body);
  }

  getBranchDetails(query?): Observable<any> {
    return this.http.get(this.posBackOfficeApi + `branches${query}`);
  }

  updateBranchDetails(data): Observable<any> {
    return this.http.put(this.posBackOfficeApi + "branches", data);
  }

  deleteBranchDetails(brandId): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "branches?id=" + brandId);
  }
  branchDetails(data): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "branches?brand_id=" + data);
  }

  getProductMix(param) {
    return this.http.get(this.posBackOfficeApi + "report/products" + param);
  }
  saveTableNames(data): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "tableConfigurations", data);
  }
  getTableNames(branchId): Observable<any> {
    return this.http.get(
      this.posBackOfficeApi + "tableConfigurations?id=" + branchId
    );
  }
  updateTableNames(payload): Observable<any> {
    return this.http.put(
      this.posBackOfficeApi + "tableConfigurations",
      payload
    );
  }
  deleteTableNames(branchId): Observable<any> {
    return this.http.delete(
      this.posBackOfficeApi + "tableConfigurations?id=" + branchId
    );
  }
  saveDeliveryData(data): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "deliveries", data);
  }
  getDeliveryData(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "deliveries" + query);
  }
  updateDeliveryData(payload): Observable<any> {
    return this.http.put(this.posBackOfficeApi + "deliveries", payload);
  }
  deleteDeliveryData(branchId): Observable<any> {
    return this.http.delete(
      this.posBackOfficeApi + "deliveries?id=" + branchId
    );
  }
  saveDeliveryChargesData(data): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "deliveries/charges", data);
  }
  getDeliveryChargesData(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "deliveries/charges" + query);
  }
  updateDeliveryChargesData(body): Observable<any> {
    return this.http.put(this.posBackOfficeApi + "deliveries/charges", body);
  }

  /**area and block */
  getAreaData(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "areas" + query);
  }
  addArea(data): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "areas", data);
  }
  updateArea(data): Observable<any> {
    return this.http.put(this.posBackOfficeApi + "areas", data);
  }
  addBlock(data): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "areas/blocks", data);
  }
  deletearea(area_id): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "areas?area_id=" + area_id);
  }
  deletBlock(block_id): Observable<any> {
    return this.http.delete(
      this.posBackOfficeApi + "areas/blocks?block_id=" + block_id
    );
  }
  updateBlock(data): Observable<any> {
    return this.http.put(this.posBackOfficeApi + "areas/blocks", data);
  }
  /* online setup */

  saveOnlineDeliveryData(data): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "onlineDeliveries", data);
  }
  getOnlineDeliveryData(): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "onlineDeliveries");
  }
  getOnlineDelivery(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "onlineDeliveries" + query);
  }
  getOnlineDeliverySource(): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "onlineDeliveries");
  }
  getonlinesourcePayment(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "onlinesource/paymenttype" + query);
  }
  updateonlinesourcePayment(data): Observable<any> {
    return this.http.patch(this.posBackOfficeApi + "onlinesource/paymenttype", data)
  }
  deleteonlinesourcePayment(query): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "onlinesource/paymenttype" + query)
  }
  postPayment(data): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "onlinesource/paymenttype", data);
  }

  updateOnlineData(payload): Observable<any> {
    return this.http.put(this.posBackOfficeApi + "onlineDeliveries", payload);
  }
  deleteOnlineData(): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "onlineDeliveries");
  }
  removeOnlinePayement(query): Observable<any> {
    return this.http.delete(
      this.posBackOfficeApi + "onlineDeliveries/onlinepayments" + query
    );
  }
  removeOnlineType(query): Observable<any> {
    return this.http.delete(
      this.posBackOfficeApi + "onlineDeliveries/onlinetype" + query
    );
  }

  /* Inventory */

  saveCategoryInventory(payload): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "master/categories", payload);
  }
  getCategoryInventory(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "master/categories" + query);
  }
  updateCategoryInventory(payload): Observable<any> {
    return this.http.put(this.posBackOfficeApi + "master/categories", payload);
  }
  deleteCategoryInventory(query): Observable<any> {
    return this.http.delete(
      this.posBackOfficeApi + "master/categories" + query
    );
  }

  /* items */
  saveItemsInventory(payload): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "master/items", payload);
  }
  updateItemsInventory(payload): Observable<any> {
    return this.http.put(this.posBackOfficeApi + "master/items", payload);
  }
  getItemsInventory(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "master/items" + query);
  }
  deleteItemsInventory(query): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "master/items" + query);
  }
  /* supplier */
  saveSupplierInventory(payload): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "suppliers", payload);
  }
  getSupplierInventory(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "suppliers" + query);
  }
  getSuppliergrv(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "suppliers" + query);
  }
  getitemgrv(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "grvs/report" + query);
  }
  getwastagegrv(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "wastage/report" + query);
  }
  deleteSupplierInventory(query): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "suppliers" + query);
  }
  updateSupplierInventory(payload): Observable<any> {
    return this.http.put(this.posBackOfficeApi + "suppliers", payload);
  }
  getStocks(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "stocks" + query);
  }
  /* branReport */
  getBrandSalesReport(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "report/brands" + query);
  }
  getBrandSalesReportByDate(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "report/brands/dates" + query);
  }

  /* grvreports*/
  getGrvReport(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "grvs" + query);
  }
  /* dashboard */

  dashboard(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "dashboard" + query);
  }

  dashboardGraph(query?: any): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "dashboard/graph" + query);
  }

  dashboardTrends(query?: any): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "dashboard/trends" + query);
  }
  dashboardTrends1(query?: any): Observable<any> {
    return this.http.get(
      this.posBackOfficeApi + "dashboard/trendsyesterday" + query
    );
  }

  dashboardSalesSummary(query?: any): Observable<any> {
    return this.http.get(
      this.posBackOfficeApi + "dashboard/salesSummary" + query
    );
  }

  /* transactionDate */
  transactionDate(payload): Observable<any> {
    return this.http.post(
      this.posBackOfficeApi + "brands/transactions",
      payload
    );
  }

  /*oders */
  getOders(id?: any): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "orders?check=true&id=" + id);
  }

  globalCardTypes(): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "cardTypes");
  }
  getCardTypes(brandId): Observable<any> {
    return this.http.get(this.posBackOfficeApi + 'cardTypes'+(brandId?'?brand_id='+brandId:''))
  }

  getorders1(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "orders" + query);
  }

  getTransactiondate(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "brands/transactions" + query);
  }

  fetch_refund(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "refund" + query);
  }

  refund(payload): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "refund", payload);
  }

  updateOrders(data): Observable<any> {
    return this.http.put(this.posBackOfficeApi + "orders", data);
  }

  getDineInData(query) {
    return this.http.get(this.posBackOfficeApi + "tableConfigurations" + query);
  }

  updateDineInData(payload) {
    return this.http.put(
      this.posBackOfficeApi + "tableConfigurations",
      payload
    );
  }
  branchPrinters(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "branchprinter" + query);
  }
  managerEndShiftData(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + 'endShift/data' + query);
  }
  getParticularSegments(segmentId): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "/segment/" + segmentId);
  }

  getDiscountDetails(data): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "discounts" + data);
  }

  searchMenu(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "menus/search" + query);
  }
  searchRecipe(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "receipe/newrecipe/search" + query);
  }
  itemsearch(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "master/items" + query);
  }

  SFitemsearch(query): Observable<any> {
    return this.http.get(
      this.posBackOfficeApi + "master/items/search/type" + query
    );
  }
  itemCatalogue(query): Observable<any> {
    return this.http.get(
      this.posBackOfficeApi + "receipe/itemcatelog/search" + query
    );
  }

  saveMenuRecipe(payload): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "receipe/newrecipe", payload);
  }
  addMenuRecipe(payload): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "receipe", payload);
  }
  addSFRecipe(payload): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "receipe/item", payload);
  }
  additemRecipe(payload): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "receipe/itemcatelog", payload);
  }
  getMenuRecipe(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "receipe" + query);
  }
   getSFRecipe(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "receipe/item" + query);
  }
  getitemRecipe(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "receipe/itemcatelog" + query);
  }
  deleteMenuRecipe(query): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "receipe" + query);
  }
   deleteSFRecipe(query): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "receipe/item" + query);
  }
  deleteitemRecipe(query): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "receipe/itemcatelog" + query);
  }



  updateMenuRecipe(payload): Observable<any> {
    return this.http.put(this.posBackOfficeApi + "receipe/newrecipe", payload);
  }

  updateItemRecipe(payload): Observable<any> {
    return this.http.patch(this.posBackOfficeApi + "receipe/Item", payload);
  }

  saveItemRecipe(payload): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "receipe/Item", payload);
  }

  deleteMenuRecipes(query): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "receipe/newrecipe" + query);
  }

  deleteSFRecipes(query): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "receipe/Item" + query);
  }

  GetRecipeOfMenu(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "receipe/newrecipe" + query);
  }
  GetRecipeOfSFitem(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "receipe/Item" + query);
  }
  GetItemUnitPrice(query): Observable<any> {
    return this.http.get(
      this.posBackOfficeApi + "receipe/grvitems/unitprice" + query
    );
  }
  foodcost(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "foodcosts/test" + query);
  }
  updateUserDetailsInOrders(payload): Observable<any> {
    return this.http.patch(this.posBackOfficeApi + 'orders/username/update', payload)
  }

  getComplaints(): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "complaints");
  }

  addComplaint(payload): Observable<any> {
    return this.http.post(
      this.posBackOfficeApi + "complaints",
      payload
    );
  }

  deleteComplaint(query): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "complaints" + query);
  }

  updateComplaint(payload): Observable<any> {
    return this.http.patch(this.posBackOfficeApi + "complaints", payload);
  }
  cresteSubcomplaint(body): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "complaints/subcomplaints", body);
  }
  getCreatedSubComplaint(id): Observable<any> {
    return this.http.get(this.posBackOfficeApi + `complaints/subcomplaints?complaint_id=${id}`);
  }
  deletesubCompaint(id): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + `complaints/subcomplaints?subcomplaint_id=${id}`)
  }
  updateSubComplaint(payload): Observable<any> {
    return this.http.patch(this.posBackOfficeApi + 'complaints/subcomplaints', payload)
  }
  updateChangePassword(body): Observable<any> {
    return this.http.put(this.AuthApi + 'changepassword', body)
  }
  transaction(id): Observable<any> {
    return this.http.get(this.posBackOfficeApi + `brands/transactions?branch_id=${id}`)
  }
  endshift(brand_id, branch_id): Observable<any> {
    return this.http.get(this.posBackOfficeApi + `endShift/data?brand_id=${brand_id}&branch_id=${branch_id}`)
  }
  updateTransaction(body): Observable<any> {
    return this.http.put(this.posBackOfficeApi + 'brands/transactions',body)
  }
  updateStatus(body):Observable<any>{
    return this.http.put(this.posBackOfficeApi + 'endShift',body)
  }

  onlineorder(): Observable<any> {
    return this.http.get(this.posBackOfficeApi + `onlineDeliveries`)
  }
  getTransactionDate(): Observable<any> {
    return this.http.get(this.posBackOfficeApi + 'callcenter/transactionDates')
  }
  postwarehouse(body):Observable<any>{
    return this.http.post(this.posBackOfficeApi + 'warehouse',body)
  }
  getwarehouse():Observable<any>{
    return this.http.get(this.posBackOfficeApi + 'warehouse')
  }
  getordersearch(querry):Observable<any>{
    return this.http.get(this.posBackOfficeApi + `callcenter/reports${querry}`)
  }

  getAgentPerformance(querry):Observable<any>{
    return this.http.get(this.posBackOfficeApi + `report/agentPerformance${querry}`)
  }
  getAreas(query): Observable<any> {
    return this.http.get(`${this.posBackOfficeApi}areas${query}`);
  }
  getBlocksbyAreaid(query): Observable<any> {
    return this.http.get(`${this.posBackOfficeApi}areas/blocks${query}`);
  }
  getCustomerReport(query): Observable<any>{
    return this.http.get(this.posBackOfficeApi + `report/customer${query}`)
  }
  getStockReport(query): Observable<any>{
    return this.http.get(this.posBackOfficeApi + 'stock/report' + query)
  }

  putwarehouse(body):Observable<any>{
    return this.http.put(this.posBackOfficeApi + 'warehouse',body)
  }

  getProductFilter(query):Observable<any>{
    return this.http.get(this.posBackOfficeApi + `report/products${query}`)
  }
  
}
