import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  inventoryItem: FormGroup;
  inventoryItemEdit: FormGroup;
  brandData: any;
  formSubmitted: any;
  branches: any;
  selectDisable: Boolean;
  itemID: any;
  dataItems: any;
  itemData1: any;
  measuringUnits = [
    {
      name: 'kg'
    },
    {
      name: 'liters'
    },
    {
      name: 'grams'
    },
    {
      name: 'no'
    }
  ];
  categoryCode: any;
  itemData: any;
  branchID: any;
  showItemData: boolean;
  noData: boolean;
  formSubmitted1: boolean;
  showPagination: boolean;
  editItemData: any;
  supplierData:any;
  p:any;
  constructor(private fb: FormBuilder, public service: PosBackOfficeService, public ls: LocalStorageService) {}

  ngOnInit() {
    this.inventoryItem = this.fb.group({
      brand_id: ['', Validators.required],
      item_name_english: ['', Validators.required],
      item_name_arabic: ['', Validators.required],
      item_code: [''],
      measuring_unit: ['', Validators.required],
      last_price: [0, [Validators.min(0)]],
      average_price: [0, [Validators.min(0)]],
      category_code: ['', Validators.required],
      type:["", Validators.required],
      supplier_id:[""]
    });
    this.inventoryItemEdit = this.fb.group({
      brand_id: [''],
      item_id: [''],
      supplier_id:[''],
      item_name_english: ['',Validators.required],
      item_name_arabic: ['', Validators.required],
      item_code: ['', Validators.required],
      measuring_unit: ['', Validators.required],
      last_price: [0, [Validators.min(0)]],
      average_price: [0, [Validators.min(0)]],
      category_code: ['', Validators.required],
    
    });
    this.getBrandDetails();
  }
  patchman1(){
    const one =this.inventoryItem.get('item_name_english').value;
    this.inventoryItem.patchValue({'item_name_english':one.replace(/^ +/gm, '')});
    
  }

  getBrandDetails() {
    this.service.getBrandDetails().subscribe(
      result => {
        this.brandData = result.body.sort(function(a, b) {
          return a.brand_id - b.brand_id;
        });
      },
      error => {
        console.log(error);
      }
    );
  }
  getSupplierDetails(data) {
    this.service.getSupplierDetails(`?id=${data}`).subscribe(
      result => {
        this.supplierData = result.body
      },
      error => {
        console.log(error);
      }
    );
  }

  getBranch(brand) {
    this.getInventoryCategoryCode(brand);
    this.getSupplierDetails(brand);
  }

  submit() {
    this.selectDisable = true;
    const brandId = this.inventoryItem.value.brand_id;
    if (this.inventoryItem.value.last_price == null) {
      this.inventoryItem.patchValue({last_price: 0});
    }
    if (this.inventoryItem.value.average_price == null) {
      this.inventoryItem.patchValue({average_price: 0});
    }
    console.log("save item",this.inventoryItem.value)
    this.service.saveItemsInventory(this.inventoryItem.value).subscribe(
      res => {
        console.log("submit()",res)
        this.selectDisable = false;
        this.getItemDetails(this.inventoryItem.value.brand_id);
        this.inventoryItem.reset();
        this.inventoryItem.patchValue({ last_price: 0, average_price: 0, brand_id: brandId });
        this.service.openSnackBar('Data Added!');
      },
      error => {
        this.selectDisable = false;
        console.log(error);
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  clear(){
    this.getItemDetails(this.inventoryItem.value.brand_id);
    this.inventoryItem.patchValue({supplier_id:""});
    this.inventoryItem.patchValue({item_name_english:""});
    this.inventoryItem.patchValue({category_code:""});
  }
  submitItemData() {
    if (
      !this.itemData1.some(r => (r.item_name_english).toUpperCase().replace(/\s/g, '')
      === (this.inventoryItem.value.item_name_english).toUpperCase().replace(/\s/g, ''))
    ) {
      if (
        !this.itemData1.some(r => (r.item_name_arabic).replace(/\s/g, '')
        === (this.inventoryItem.value.item_name_arabic).replace(/\s/g, ''))
      ) {
        this.submit();
      } else {
        this.service.openSnackBar('Item arabic name  already exists');
      }
    } else {
      this.service.openSnackBar('Item name  already exists');
    }
  }

  updateItems(data) {
    const itemArrayData = [];
    if (
      this.editItemData.item_name_english === data.item_name_english &&
      this.editItemData.item_name_arabic === data.item_name_arabic
    ) {
      this.updateUniqueData();
    } else {
      this.itemData1.forEach(element => {
        if (element.item_id !== data.item_id) {
          itemArrayData.push(element);
        }
      });
    }
    if (!itemArrayData.some(r => (r.item_name_english).replace(/\s/g, '')
     === (data.item_name_english).toUpperCase().replace(/\s/g, ''))) {
      if (!itemArrayData.some(r => (r.item_name_arabic ).replace(/\s/g, '')
     === (data.item_name_arabic).toUpperCase().replace(/\s/g, ''))) {
        this.updateUniqueData();
      } else {
        this.service.openSnackBar('Item arabic name  already exists');
      }
    } else {
      this.service.openSnackBar('Item name  already exists');
    }
  }

  updateUniqueData() {
    if (this.inventoryItemEdit.value.last_price == null) {
      this.inventoryItemEdit.patchValue({last_price: 0});
    }
    if (this.inventoryItemEdit.value.average_price == null) {
      this.inventoryItemEdit.patchValue({average_price: 0});
    }
    this.service.updateItemsInventory(this.inventoryItemEdit.value).subscribe(
      res => {
        this.getItemDetails(this.inventoryItemEdit.value.brand_id);
        this.itemID = '';
        this.service.openSnackBar('Data Updated!');
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  getItemDetails(data) {
    this.service.getItemsInventory(`?id=${data}`).subscribe((res: any) => {
      this.itemData1 = res.body.itemResponse;
      if (this.itemData1.length > 0) {
        if (this.itemData1.length > 16) {
          this.showPagination = true;
        } else {
          this.showPagination = false;
        }
        this.noData = false;
      } else {
        this.noData = true;
      }
      this.showItemData = true;
      res.body.categoryResponse.map(e => {
        this.itemData1.map(r => {
          if (e.category_code == r.category_code) {
            r.category_name_english = e.category_name_english;
            r.category_name_arabic = e.category_name_arabic;
          }
        });
      });
    });
  }
  getItemDetail() {
    this.service.getItemsInventory(`?id=${this.inventoryItem.value.brand_id}&supplier_id=${this.inventoryItem.value.supplier_id}`).subscribe((res: any) => {
      this.itemData1 = res.body.itemResponse;
      if (this.itemData1.length > 0) {
        if (this.itemData1.length > 16) {
          this.showPagination = true;
        } else {
          this.showPagination = false;
        }
        this.noData = false;
      } else {
        this.noData = true;
      }
      this.showItemData = true;
      res.body.categoryResponse.map(e => {
        this.itemData1.map(r => {
          if (e.category_code == r.category_code) {
            r.category_name_english = e.category_name_english;
            r.category_name_arabic = e.category_name_arabic;
          }
        });
      });
    });
  }
  getCategoryDetail(data) {
    this.service.getItemsInventory(`?id=${this.inventoryItem.value.brand_id}&category_name_english=${data}`).subscribe((res: any) => {
      this.itemData1 = res.body;
      if (this.itemData1.length > 0) {
        if (this.itemData1.length > 16) {
          this.showPagination = true;
        } else {
          this.showPagination = false;
        }
        this.noData = false;
      } else {
        this.noData = true;
      }
      this.showItemData = true;
      res.body.categoryResponse.map(e => {
        this.itemData1.map(r => {
          if (e.category_code == r.category_code) {
            r.category_name_english = e.category_name_english;
            r.category_name_arabic = e.category_name_arabic;
          }
        });
      });
    });
  }
  getDetail(data) {
    this.service.getItemsInventory(`/search?keyword=${this.inventoryItem.value.item_name_english}&searchBy=item_name&brand_id=${this.inventoryItem.value.brand_id}`).subscribe((res: any) => {
      this.itemData1 = res.body;
      if (this.itemData1.length > 0) {
        if (this.itemData1.length > 16) {
          this.showPagination = true;
        } else {
          this.showPagination = false;
        }
        this.noData = false;
      } else {
        this.noData = true;
      }
      this.showItemData = true;
      res.body.categoryResponse.map(e => {
        this.itemData1.map(r => {
          if (e.category_code == r.category_code) {
            r.category_name_english = e.category_name_english;
            r.category_name_arabic = e.category_name_arabic;
          }
        });
      });
    });
  }

  deleteItem(data) {
    console.log(data);
    this.popUp();
    this.branchID = data;
  }

  deleteItemsModal() {
    this.service
      .deleteItemsInventory(
        `?item_id=${this.branchID.item_id}&item_number=${this.branchID.item_code}&id=${this.branchID.brand_id}`
      )
      .subscribe(
        res => {
          this.service.openSnackBar('Data Deleted!');
          this.getItemDetails(this.branchID.brand_id);
          this.popUpOff();
        },
        error => {
          this.service.openSnackBar(error.error.message);
        }
      );
  }

  getItemData(query) {
    this.service.getItemsInventory(`?id=${query}`).subscribe(
      res => {
        // this.itemData = res.body;
      },
      error => {
        console.log(error);
      }
    );
  }

  editItems(data) {
    this.editItemData = data;
    console.log('data', data);
    this.itemID = data.item_id;
    // this.getInventoryCategoryCode(data.branch.brand.brand_id);
    this.inventoryItemEdit.patchValue(data);
  }

  getInventoryCategoryCode(brand) {
    this.service.getCategoryInventory(`?id=${brand}`).subscribe(
      res => {
        this.categoryCode = res.body;
      },
      error => {
        console.log(error);
      }
    );
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  isFieldValid(field: string) {
    return (
      (!this.inventoryItem.get(field).valid && this.inventoryItem.get(field).touched) ||
      (this.inventoryItem.get(field).untouched && this.formSubmitted)
    );
  }

  isFieldValid1(field: string) {
    return (
      (!this.inventoryItemEdit.get(field).valid && this.inventoryItemEdit.get(field).touched) ||
      (this.inventoryItemEdit.get(field).untouched && this.formSubmitted1)
    );
  }
}