import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  orderForm: FormGroup;
  refundData: FormGroup;
  showOrders: boolean = false;
  brand;
  branch;
  transactionDate: any;
  orders: any;
  showorderTable: Boolean = false;
  showsingleOrder: Boolean = false;
  selectedOrder1: any;
  pushedData = [];
  j = 0;
  refund_amount: number = 0;
  showRefund: boolean;
  showRefund1: boolean;
  print_status: boolean = true;
  refundHide: Boolean = true;
  refund_status_bool: boolean = false;
  showRefundBtn: boolean;
  showOrderDetails: boolean;
  refundShow: boolean = false;
  amtGreater: Boolean = false;
  receipt_status: boolean = true;
  refund: boolean;
  local
  totalOrders;
  selectedBranch: any;
  segmenthide: boolean = true;
  branchhide: boolean = true;
  table_data: any = [];
  userhide: boolean = true;
  showPrint: boolean;
  printDataReceipt: any;
  logo;
  systemDate: string;
  segmentlist;
  cancelValue: number = 0;
  userList
  discountData: any;
  brandDiscount: any;
  i: any;
  p: any;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.local = this.ls.get('bouser');
    this.orderForm = this.fb.group({
      segmnetname: [""],
      brand: [""],
      branch: [""],
      branchname: [""],
      username: [""]
      // segment : new FormControlName ('value:""; disabled: this.disabled')
    })


    this.refundData = this.fb.group({
      refund_array: this.fb.array([]),
      remark: ['', [Validators.required]]
    });

    this.brandData()
    //  this.getUserDetails() 

    this.segmentlist = ["Take Out", "Car Services", "Staff Meal", "Dine On", "Catering", "Delivery"]
    console.log("segmental", this.segmentlist);

  }


  brandData() {
    this.service.getBrandDetails().subscribe(result => {
      this.brand = result.body;
    });
  }

  getBranch(brand) {
    this.showorderTable = false
    this.showsingleOrder = false
    this.discountDetails(brand.brand_id)
    this.service.Branches(`?brand_id=${brand.brand_id}`).subscribe(r => {
      this.branch = r.body;
      this.branchhide = false;
      this.getBrandImage(brand.image_id)
    });
  }

  getBrandImage(imageid) {
    this.service.getImage(imageid).subscribe((r: any) => {
      if (r.body.length !== 0) {
        this.logo = r.body[0].image_data;
      }
    });
  }

  getTransactiondate(branchid) {
    this.selectedBranch = branchid
    this.showsingleOrder = false
    this.branchPrinterData()
    this.service.getTransactiondate(`?branch_id=${branchid}`).subscribe(r => {
      let transactionDate = r.body[0].transaction_date;
      this.transactionDate = transactionDate
      this.detailOrders(branchid, transactionDate)
      this.segmenthide = false;

    });
    // console.log("segment",this.brand)
    this.getUserDetails(branchid)
  }

  detailOrders(branch_id, transactiondate) {
    this.service.getorders1(
      `?role=manager&branch_id=${branch_id}&transaction_date=${transactiondate}&status=false`
    )
      .subscribe(result => {
        this.orders = result.body
        this.showorderTable = true;
        if (result.body.length > 0) {
          this.totalOrders = this.orders;
          if (this.orders.length > 0) {
            this.showorderTable = true
          }
          this.fetchTableData();
        }
      });

  }



  numberWithCommas(x) {
    return x.join(', ');
  }
  cardDetails() {
    this.service.globalCardTypes().subscribe(r => {
      console.log("card details", r)
    });
  }


  cancelAmount(i) {
    this.cancelValue = parseInt((<HTMLInputElement>document.getElementById(i + 'i')).value);
    let data: any = this.selectedOrder1.order_details;
    let sub_item_price: number = 0;
    let cost_reduced: number = 0;
    if (data[i].quantity <= this.cancelValue) {
      if (data[i].allsubItems.length > 0) {
        sub_item_price = data[i].allsubItems[0].real_price;
      }
      cost_reduced = (data[i].item_price + sub_item_price) * data[i].quantity;
      data.splice(i, 1);
    } else {
      let { quantity } = data[i];
      data[i].quantity = parseInt(quantity) - this.cancelValue;
      if (data[i].allsubItems.length > 0) {
        sub_item_price = data[i].allsubItems[0].real_price;
      }
      cost_reduced = (data[i].item_price + sub_item_price) * this.cancelValue;
    }
    this.selectedOrder1.total_cost -= cost_reduced;
    this.selectedOrder1.net_cost -= cost_reduced;
    this.selectedOrder1.total_quantity -= this.cancelValue;
    this.service.updateOrders(this.selectedOrder1).subscribe(
      result => {
        // this.detailOrders();
        this.detailOrders(this.selectedBranch, this.transactionDate)
        this.cancelValue = 0;
      },
      error => {
        console.log(error);
      }
    );
  }

  selectedOrder(data) {
    console.log("selected single order", data)
    if (data.discount_id != null) {
      let branddiscount = this.discountData.filter(ele => {
        return (ele.discount_id == data.discount_id)
      })
      console.log("branddiscount", branddiscount)
      this.brandDiscount = branddiscount
    }

    this.selectedOrder1 = data
    this.showsingleOrder = true

    this.refundHide = true;
    this.refundShow = false;
    this.pushedData = [];
    this.refundData.reset();
    this.refund_status_bool = false;
    this.showRefund = false
    this.service
      .fetch_refund(`?refund_status=true&order_id=${data.order_id}`)
      .subscribe(r => {
        if (r.body.length > 0) {
          this.showRefund1 = true
          this.refund_status_bool = r.body[0].refund_status;
          this.receipt_status = false
          this.refund_amount = r.body[0].refund_amount
          console.log("refun amount")
        } else {
          this.showRefund1 = false;
          this.receipt_status = true
        }
      });
    this.refundShow = true;
    this.selectedOrder1 = data;
    let real_price: number = 0;
    this.selectedOrder1.order_details.forEach(element1 => {
      if (element1.allsubItems.length > 0) {
        real_price = 0;
        element1.allsubItems.forEach(element => {
          real_price += parseFloat(element.item_price);
          element1.allsubItems.map(r => {
            r.real_price = real_price;
          });
        });
      }
    });
    if (data.status === 'Encashed') {
      this.refund = true;
    } else {
      this.refund = false;
    }
    this.showOrderDetails = true;
    this.showRefundBtn = false;
  }

  refundOrder(data, index, indexTwo, qty, price) {
    if (price != 0) {
      const itemIndex = index + '.' + indexTwo;
      if (this.pushedData.find(x => x.index === itemIndex)) {
        return true;
      } else {
        if (this.showRefund) {
          this.pushedData.push({ index: itemIndex });
        }
        if (this.showRefund != true) {
          return true;
        }

        let creds = this.refundData.controls.refund_array as FormArray;

        // creds.push(
        //   this.fb.group({
        //     item_name: '',
        //     item_name_arabic: '',
        //     quantity: '',
        //     amount: [0],
        //     qtyHidden: '',
        //     refund_amount: [0],
        //     discountpercent:this.brandDiscount[0].discount_percentage,
        //     indexMain: itemIndex
        //   })
        // );
        if (this.brandDiscount) {
          creds.push(
            this.fb.group({
              item_name: '',
              item_name_arabic: '',
              quantity: '',
              amount: [0],
              qtyHidden: '',
              refund_amount: [0],
              discountpercent: this.brandDiscount[0].discount_percentage,
              indexMain: itemIndex
            })
          );
        } else {
          creds.push(
            this.fb.group({
              item_name: '',
              item_name_arabic: '',
              quantity: '',
              amount: [0],
              qtyHidden: '',
              refund_amount: [0],
              // discountpercent:this.brandDiscount[0].discount_percentage,
              indexMain: itemIndex
            })
          );
        }
        console.log("refundorder function", this.selectedOrder1)
        console.log("refundorder qty", qty)
        console.log("refundorder data", data)
        let multiple = qty * data.item_price


        if (this.selectedOrder1.discount_amount) {
          multiple = multiple - this.selectedOrder1.discount_amount
        }

        let formArray = this.refundData.get('refund_array') as FormArray;
        formArray.controls[this.j].patchValue({
          item_name_arabic: data.menu_name_arabic ? data.menu_name_arabic : data.item_name_arabic,
          item_name: data.menu_name ? data.menu_name : data.item_name,
          quantity: qty,
          qtyHidden: qty,
          refund_amount: multiple.toFixed(3),
          amount: data.item_price
        });
        this.j += 1;
      }
      this.getData1();
    }
  }

  getData1() {
    this.refund_amount = 0;
    console.log("***********", this.refundData.get('refund_array').value)
    this.refundData.get('refund_array').value.forEach(element => {


      if (this.selectedOrder1.discount_amount > 0) {
        console.log("brand percentage ", parseFloat(element.discountpercent))
        console.log("quantity: ", element.quantity, 'amount: ', element.amount)
        let qtyamount: any = parseFloat(element.quantity) * parseFloat(element.amount)
        console.log("totalamount aty*amount", qtyamount)
        let discount: any = parseFloat(qtyamount) * parseFloat(element.discountpercent)
        console.log("discount==", discount)
        let k: any = discount / 100
        console.log("divided by 100==", k)
        let minustotal: any = parseFloat(qtyamount) - parseFloat(k);
        console.log("minus", minustotal)
        this.refund_amount += parseFloat(minustotal);
      } else {
        this.refund_amount += parseFloat(element.refund_amount);
      }
    });
    console.log("this getdata1", this.refund_amount)

    this.print_status = false;
  }




  // discount(data) {
  //   this.orderMenu.patchValue({
  //     cash_amount: 0,
  //     card_amount: 0
  //   });
  //   this.change = 0;
  //   this.appliedDiscount = data;
  //   if (data.discount_percentage > 0) {
  //     this.discountAmount =
  //       this.totalCost *
  //       parseFloat((data.discount_percentage / 100).toFixed(3));
  //     return (this.netCost = parseFloat(
  //       (
  //         this.totalCost -
  //         this.totalCost * (data.discount_percentage / 100)
  //       ).toFixed(3)
  //     ));
  //   }
  //   if (data.discount_price > 0) {
  //     this.discountAmount = data.discount_price;
  //     return (this.netCost = parseFloat(
  //       (this.totalCost - data.discount_price).toFixed(3)
  //     ));
  //   }
  //   this.netCost = parseFloat(this.totalCost.toFixed(3));
  // }

  refundStatus() {
    this.refundHide = false;
    if (this.refund_status_bool === true) {
      return this.service.openSnackBar('Already refunded');
    }
    this.refund_amount = 0;
    this.j = 0;
    this.showRefund = true;
    this.refundShow = true;
    this.refundData.setControl('refund_array', this.fb.array([]));
  }

  get refundForms() {
    return this.refundData.get('refund_array') as FormArray;
  }

  compareQty(qty, index) {
    let formArray = this.refundData.get('refund_array') as FormArray;
    const amount = formArray.controls[index].value['amount'];
    console.log("formamount=", amount)
    console.log("incomingqty=", qty)
    console.log("hiddentqty", formArray.controls[index].value.qtyHidden)
    /**if entered quantity is 0 then patch actual quantity */
    if (formArray.controls[index].value.quantity == 0) {
      formArray.controls[index].patchValue({
        quantity: formArray.controls[index].value.qtyHidden
      });
    }

    if (formArray.controls[index].value.qtyHidden < qty) {
      formArray.controls[index].patchValue({
        quantity: formArray.controls[index].value.qtyHidden,
        refund_amount: amount * formArray.controls[index].value.qtyHidden

      });
    } else {
      formArray.controls[index].patchValue({
        refund_amount: amount * qty
      });
    }
    this.getData1();
  }

  resetRefund(index) {
    let formArray = this.refundData.get('refund_array') as FormArray;
    if (formArray.controls[index].value.refund_amount == null) {
      formArray.controls[index].patchValue({
        refund_amount:
          formArray.controls[index].value.quantity * formArray.controls[index].value.amount
      });
    }
  }

  amtloop() {
    this.amtGreater = false;
    for (let i = 0; i < this.refundForms.controls.length; i++) {
      // console.log('this.refundForms.controls[i].value.amount', this.refundForms.controls[i].value.amount);
      // console.log('this.refundForms.controls[i].value.quantity', this.refundForms.controls[i].value.quantity)

      console.log(
        'p(this.',
        parseFloat(
          (
            this.refundForms.controls[i].value.amount * this.refundForms.controls[i].value.quantity
          ).toFixed(3)
        )
      );
      console.log(
        'parseFloat( (this.refundForms.controls[i].value.refund_amount).toFixed(3))',
        parseFloat(this.refundForms.controls[i].value.refund_amount).toFixed(3));
      if (
        parseFloat(
          (
            this.refundForms.controls[i].value.amount * this.refundForms.controls[i].value.quantity
          ).toFixed(3)
        ) < parseFloat(parseFloat(this.refundForms.controls[i].value.refund_amount).toFixed(3))
      ) {
        this.amtGreater = true;
      }
    }
  }

  submit() {
    this.amtloop();

    if (this.amtGreater || this.refund_amount == 0) {
      return this.service.openSnackBar('refund amount more than net amount or zero');
    }
    if (this.refund_amount.toFixed(3) > this.selectedOrder1.net_cost) {
      return this.service.openSnackBar('refund amount more than net amount');
    }
    let payload = {
      user_id: this.local.id,
      user_name: this.local.name,
      refund_amount: this.refund_amount,
      order_id: this.selectedOrder1.order_id,
      transaction_date: this.transactionDate,
      refund_status: true,
      reason_for_refund: this.refundData.value.remark,
      refund_data: this.refundData.value,
      branch_id: this.selectedBranch
    };
    console.log("@@@@@@@@", this.selectedOrder1)
    let updateOrder = {
      order_id: this.selectedOrder1.order_id,
      net_cost: 0,
      total_cost: 0,
      card_amount: this.selectedOrder1.card_amount,
      discount_amount: this.selectedOrder1.discount_amount
    };

    // if (this.refund_amount <= this.selectedOrder1.net_cost - this.selectedOrder1.card_amount)  {
    //   (updateOrder.net_cost = this.selectedOrder1.net_cost - this.refund_amount),
    //   (updateOrder.total_cost = this.selectedOrder1.total_cost - this.refund_amount);
    // }

    // if (this.refund_amount > this.selectedOrder1.net_cost - this.selectedOrder1.card_amount) {
    //   (updateOrder.net_cost = this.selectedOrder1.net_cost - this.refund_amount),
    //   (updateOrder.total_cost = this.selectedOrder1.total_cost - this.refund_amount),
    //   (updateOrder.card_amount = this.selectedOrder1.card_amount - (this.refund_amount - (this.selectedOrder1.net_cost - this.selectedOrder1.card_amount)));
    // }

    if (this.selectedOrder1.discount_amount > this.selectedOrder1.total_cost) {
      updateOrder.discount_amount = this.selectedOrder1.discount_amount;
    }
    if (this.refund_amount <= this.selectedOrder1.net_cost - this.selectedOrder1.card_amount) {
      (updateOrder.net_cost = this.selectedOrder1.net_cost - this.refund_amount),
        (updateOrder.total_cost = this.selectedOrder1.total_cost - this.refund_amount);
    }

    if (this.refund_amount > this.selectedOrder1.net_cost - this.selectedOrder1.card_amount) {
      (updateOrder.net_cost = this.selectedOrder1.net_cost - this.refund_amount),
        (updateOrder.total_cost = this.selectedOrder1.total_cost - this.refund_amount),
        (updateOrder.card_amount = this.selectedOrder1.card_amount - (this.refund_amount - (this.selectedOrder1.net_cost - this.selectedOrder1.card_amount)));
    }
    if (this.selectedOrder1.total_cost == this.refund_amount) {
      (updateOrder.net_cost = this.selectedOrder1.net_cost - (this.refund_amount + this.selectedOrder1.delivery_amount)),
        (updateOrder.total_cost = this.selectedOrder1.total_cost - this.refund_amount);
    }

    this.service.refund(payload).subscribe(
      result => {
        this.service.updateOrders(updateOrder).subscribe(r => {
          this.detailOrders(this.selectedBranch, this.transactionDate);
        });
        this.print_status = true;
        this.refundHide = true;
        this.receipt_status = false;
        this.showRefund = false;
        this.showRefund1 = true;
        this.refund_status_bool = true;
        this.refundData.setControl('refund_array', this.fb.array([]));
        this.service.openSnackBar('Refund successfully');
      },
      error => {
        this.service.openSnackBar('Refund failed');
      }
    );
  }


  setZero(index, value) {
    let formArray = this.refundData.get('refund_array') as FormArray;

    if (formArray.controls[index].value.quantity == null) {
      formArray.controls[index].patchValue({
        quantity: formArray.controls[index].value.qtyHidden
      });
    }
    this.getData1();
  }

  remove_refund_amount(i, data) {
    this.pushedData.splice(
      this.pushedData.indexOf(this.pushedData.find(x => x.index === data.value.indexMain), 1)
    );
    this.refundForms.removeAt(i);
    this.j -= 1;
    this.getData1();
    if (this.refundData.get('refund_array').value.length < 1) {
      this.print_status = true;
      this.receipt_status = true;
    }
  }

  applyFilter(filter: String) {
    this.totalOrders = this.orders.filter(item => {
      if (
        item.receipt_manual_id
          .toString()
          .toLowerCase()
          .indexOf(filter.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
  }

  fetchTableData() {
    this.service
      .getDineInData(`?id=${this.selectedBranch}`)
      .subscribe((r: any) => {
        this.table_data = r.body[0];
      });
  }

  cancelAll() {
    this.selectedOrder1.status = 'Cancelled';
    if (this.selectedOrder1.segment_key == 'dine_in') {
      let A: any = [];
      A = this.selectedOrder1.table_no;
      A.forEach(r => {
        this.table_data.table_data.forEach(e => {
          if (r == e.table_Name) {
            e.table_Status = false;
          }
        });
      });
      this.service.updateDineInData(this.table_data).subscribe(
        r => { },
        error => {
          console.log('error', error);
        }
      );
    }
    this.service.updateOrders(this.selectedOrder1).subscribe(
      result => {
        this.detailOrders(this.selectedBranch, this.transactionDate);
      },
      error => {
        console.log(error);
      }
    );
  }

  branchPrinterData() {
    // this.printerDetails = [];
    this.service
      .branchPrinters(`?id=${this.selectedBranch}`)
      .subscribe(
        r => {
          if (r.body.length > 0) {
            // this.printerDetails = r.body;
            // this.receiptPrinter.patchValue({
            //   printer_name: this.printerDetails[0].printer_name,
            //   printer_ip: this.printerDetails[0].printer_ip
            // });
            // this.localStorage.set('cashier_printer', r.body[0].printer_name);
            // this.localStorage.set('printerIp', r.body[0].printer_ip);
            // this.service.printApi = `http://${r.body[0].printer_ip}:5000/`;
          } else {
            // this.printerDetails.push({ printer_name: '', printer_ip: '' });
          }
        },
        error => {
          this.service.openSnackBar(error.error.message);
        }
      );
  }

  print(data) {
    console.log("&&&&&&&&&&&&&print", data)
    let real_price: number = 0;
    data.order_details.forEach(element1 => {
      if (element1.allsubItems.length > 0) {
        real_price = 0;
        element1.allsubItems.forEach(element => {
          real_price += parseFloat(element.item_price);
          element1.allsubItems.map(r => {
            r.real_price = real_price;
          });
        });
      }
    });
    this.showPrint = true;
    this.printDataReceipt = data;
    const today = Date.now();
    const todayDate = this.datePipe.transform(today, 'dd-MM-yyyy HH:mm');
    this.systemDate = todayDate;
    const date = this.datePipe.transform(data.transaction_date, 'dd-MM-yyyy');
    // this.transactionDate = date;
    const imgData = '<img src="' + this.logo + '" style="height:70px; width:70px;" alt="Logo">';
    let name = data.customer_name;
    if (name === '') {
      name = 'NA';
    }
    // setTimeout(() => {
    //   console.log("setTimeout")
    //   // this.cashierPrint();
    // }, 1000);

    setTimeout(() => {
      this.printReceipt('cashier_print');
    }, 500);

    return;

  }

  printReceipt(div): void {
    let printContents, popupWin;
    printContents = document.getElementById(div).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title></title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  refundPrint() {
    console.log("print refund", this.refund_amount)
    const today = Date.now();
    const todayDate = this.datePipe.transform(today, 'dd-MM-yyyy HH:mm');
    const myWindow = window.open('', '', 'style="width:900",height=700');
    myWindow.document.write(`
    <div>
    <table>
    <tr style="width:100%;">
    <td style="text-align:center; width:10%;">
    <label style="font-size:25px;">Refund Receipt
    </label>
    <br>
    <br>
    <label style="font-size:20px;"><label>
    </td>
    <br>
    </tr>
    </table>
    </div>
    `);

    myWindow.document.write(
      '<table style="width:100%"><tr><td><label></label>' +
      `${this.selectedOrder1.segment_name}` +
      '</td><td style="text-align:right;"><label>Order No#<label>' +
      `${this.selectedOrder1.receipt_manual_id}` +
      '</td></table>'
    );
    myWindow.document.write(
      '<table style="width:100%"><tr><td><label></label>' +
      '</td><td style="text-align:right;"><label><label>' +
      // `${this.service.globalDate}` +
      '</td></table>'
    );
    myWindow.document.write('<hr>');

    myWindow.document.write(
      '<table style="width:100%"><tr><td><label>Net</label>' +
      '</td><td style="text-align:right;"><label><label>' +
      `${this.selectedOrder1.net_cost.toFixed(3)}` +
      '</td></table>'
    );
    myWindow.document.write(
      '<table style="width:100%"><tr><td><label>Refund</label>' +
      '</td><td style="text-align:right;"><label><label>' +
      `${this.refund_amount.toFixed(3)}` +
      '</td></table>'
    );

    myWindow.document.write('<hr>');

    myWindow.document.write(
      '<table style="width:100%"><tr><td>' +
      '</td><td style="text-align:left;"><label><label>' +
      this.local.name +
      '</td></table>'
    );
    myWindow.document.write(
      '<table style="width:100%"><tr><td><label></label>' +
      '</td><td style="text-align:left;"><label><label>' +
      todayDate +
      '</td><td style="text-align:left;"><label>Note:<label>' +
      this.refundData.value.remark +
      '</td></table>'
    );



    myWindow.print();
    setTimeout(() => myWindow.close(), 99);
  }

  // getdisable(){
  //   this.orderForm.get("segment").enable()
  //   this.orderForm.get("user").enable()
  // }


  getSegmentValue(data) {
    console.log("data2", data)
    let a = this.totalOrders.filter(e => e.segment_name == data)
    this.orders = a
    console.log("segementname", this.orders)
    console.log("this.userList", this.userList)
    this.userhide = false;

  }

  // user

  getUserDetails(data) {
    // console.log("userdata",)
    this.service.getUserListForManager(`?role=manager&branch_id=${this.selectedBranch}`)
      .subscribe(res => {
        this.userList = res;
        console.log("userlist", this.userList)
      });
  }


  discountDetails(id) {
    this.service
      .getDiscountDetails(`?id=${id}&status=true`)
      .subscribe(result => {
        this.discountData = result.body;
      });
  }

}
