import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService } from 'ngx-store';

@Component({
  selector: 'app-modifier-catalog',
  templateUrl: './modifier-catalog.component.html',
  styleUrls: ['./modifier-catalog.component.scss']
})
export class ModifierCatalogComponent implements OnInit {
  modifierCatalog: FormGroup;
  editModifierCatalog: FormGroup;
  formSubmitAttempt: Boolean;
  i: Number;
  textAlign: string;
  brand: any;
  modifierCatalogData: any;
  modifierData1: any;
  modifierID: '';
  showAvailable: Boolean = true;
  p: Number = 1;
  constructor(
    private fb: FormBuilder,
    public ls: LocalStorageService,
    public service: PosBackOfficeService
  ) {}

  ngOnInit() {
    this.modifierCatalog = this.fb.group({
      modifier_name: ['', Validators.required],
      brand_id: ['', [Validators.required]],
      modifier_name_arabic: ['', Validators.required]
    });
    this.editModifierCatalog = this.fb.group({
      modifier_name: ['', Validators.required],
      brand_id: ['', [Validators.required]],
      modifier_name_arabic: ['', Validators.required],
      modifier_id: ''
    });
    this.inputAlignment();
    this.brandData();
    // this.modifierCatalog1('get');
  }

  inputAlignment() {
    const lang = this.ls.get('language');
    if (lang === 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }

  deleteModifierCatalog(data) {
    this.modifierData1 = data;
    this.popUp();
  }

  delete() {
    this.modifierCatalog1('delete', this.modifierData1.modifier_id, '?id=');
  }

  editModCatalog(data) {
    this.modifierID = data.modifier_id;
    this.editModifierCatalog.patchValue(data);
  }

  modifierCatalog1(rule, payload = '', query = '') {
    console.log("payload",payload);
    this.service.modifierCatalog(rule, payload, query).subscribe(res => {
      if (rule === 'get') {
        this.modifierCatalogData = res.body;
        if (this.modifierCatalogData.length > 0) {
          this.showAvailable = false;
        } else {
          this.showAvailable = true;
        }
      }
      if (rule === 'post') {
        this.modifierCatalog.reset();
        this.modifierCatalog1('get');
        this.service.openSnackBar('Item Added!');
      }
      if (rule === 'delete') {
        this.modifierCatalog1('get');
        this.popUpOff();
        this.service.openSnackBar('Item Deleted!');
      }
      if (rule === 'put') {
        this.modifierCatalog1('get');
        this.modifierID = '';
        this.service.openSnackBar('Item Updated!');
      }
    });
  }

  brandData() {
    this.service.getBrandDetails().subscribe(result => {
      this.brand = result.body;
    });
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  isFieldValid(field: string) {
    return (
      (!this.modifierCatalog.get(field).valid && this.modifierCatalog.get(field).touched) ||
      (this.modifierCatalog.get(field).untouched && this.formSubmitAttempt)
    );
  }
}
