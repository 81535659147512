import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { DateAdapter } from '@angular/material/core';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
@Component({
  selector: 'app-product-mix-report',
  templateUrl: './product-mix-report.component.html',
  styleUrls: ['./product-mix-report.component.scss']
})
export class ProductMixReportComponent implements OnInit {
  menuItems: any;
  brands: any;
  branches: any;
  fromdate;
  todate;
  maxToDate = new Date();
  maxFromDate = new Date();
  minToDate: any;
  maxDate = new Date();
  enableBranch: Boolean = false;
  prodMixReport: FormGroup;
  res: any;
  showReport: Boolean = false;
  showData: boolean;
  fullQuery;
  showReport1: boolean;
  areaName: string;
  areas: any;
  categoryData: any;
  itemData: any;
  queryParams = {
    brand_id: 0,
    branch_id: 0,
    segment_id: 0,
    segment_name: '',
    online_type: 0,
    onlinetypeNaame: '',
    category_id: ''
  };
  onlineresorce: any[];
  constructor(
    public service: PosBackOfficeService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private adapter: DateAdapter<any>,
    public ls: LocalStorageService
  ) { }

  ngOnInit() {
    this.prodMixReport = this.fb.group({
      brand: '',
      branch: '',
      fromDate: [new Date().toISOString().slice(0, 10)],
      toDate: [new Date().toISOString().slice(0, 10)],
      menuCategory: '',
      menuItem: '',
      segment: '',
      onlineType: ''
    });
    this.getBrand();
    this.dateAdapter();
    this.dateValidation();
    this.getAreas();
    this.onlineSourceApi();
  }

  dateValidation() {
    this.minToDate = this.prodMixReport.value.fromDate;
    this.maxFromDate = this.prodMixReport.value.toDate;
  }
  dateAdapter() {
    this.adapter.setLocale('en-GH');
  }

  getBranch(brand) {
    this.queryParams.brand_id = 0;
    this.queryParams.brand_id = brand;
    this.searchProduct();
    this.prodMixReport.patchValue({
      branch: ''
    });
    this.enableBranch = true;
    this.service.Branches(`?brand_id=${this.prodMixReport.value.brand}`).subscribe(r => {
      this.branches = r.body;
    });
    //for category dropdown
    this.service
      .menuCategoryCatalog('get', '', `?verbose=true&brand_id=${brand}&category_status=both`)
      .subscribe(
        result => {
          console.log("result", result)
          this.categoryData = result.body;
        },
        error => {
          console.log('error', error);
        }
      );
    // for item dropdown
    // this.ItemDetails(brand)
  }
  // ItemDetails(brand_id) {
  //   this.service.Item('get', brand_id, '?verbose=true&brand_id=').subscribe(
  //     r => {
  //       if (r.status) {
  //         if (r.body.length > 0) {
  //           r.body.sort(function (a, b) {
  //             var nameA = a.item_name.toLowerCase(), nameB = b.item_name.toLowerCase()
  //             if (nameA < nameB) //sort string ascending
  //               return -1
  //             return 0 //default return value (no sorting)
  //           })
  //           this.itemData = r.body;
  //         }
  //       }

  //       // this.showMenuData = true;
  //       // this.showMenuData1 = false;
  //     },
  //     error => {
  //       console.log('error', error);
  //     }
  //   );
  // }

  getBrand() {
    this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;
      },
      error => {
        console.log('error', error);
      }
    );
  }

  getSum(index, array): number {
    let sum = 0;
    let calcArray = array;
    if (calcArray) {
      console.log("calcArray", calcArray)
      for (let i = 0; i < calcArray.length; i++) {
        sum += calcArray[i][index];
      }
      console.log('sum', sum);
      return sum;
    }
  }

  sortDate() {
    this.dateValidation();
    this.fromdate =
      this.datePipe.transform(this.prodMixReport.value.fromDate, 'yyyy-MM-dd') ||
      this.datePipe.transform(this.prodMixReport.value.toDate, 'yyyy-MM-dd') ||
      '';
    this.todate =
      this.datePipe.transform(this.prodMixReport.value.toDate, 'yyyy-MM-dd') || this.fromdate || '';
    const queryBranch = this.prodMixReport.value.branch || '';
    const defaultQuery = `?branch_id=${queryBranch}`;
    console.log("defaultQuery", defaultQuery)
    this.fullQuery = `${defaultQuery}&brand_id=${this.prodMixReport.value.brand}&fromDate=${this.fromdate}&toDate=${this.todate}`;
    console.log("this.fullQuery", this.fullQuery)
    // this.getReport();
    this.service.getProductMix(this.fullQuery).subscribe(res => {
      this.res = res;
      this.menuItems = this.res.body;
      if (this.menuItems.length > 0) {
        this.showReport1 = true;
      } else {
        this.showReport1 = false;
      }
      this.showReport = true;
    });
    this.searchProduct()
  }

  getProductDetails(id) {
    console.log("id", id)
    this.fromdate =
      this.datePipe.transform(this.prodMixReport.value.fromDate, 'yyyy-MM-dd') ||
      this.datePipe.transform(this.prodMixReport.value.toDate, 'yyyy-MM-dd') ||
      '';
    this.todate =
      this.datePipe.transform(this.prodMixReport.value.toDate, 'yyyy-MM-dd') || this.fromdate || '';
    this.showData = true;
    const queryBranch = this.prodMixReport.value.branch || '';
    const defaultQuery = `?branch_id=${queryBranch}`;
    console.log({ defaultQuery })
    this.service.getProductMix(
      `${defaultQuery}&brand_id=${this.prodMixReport.value.brand}&fromDate=${this.fromdate}&toDate=${this.todate}`
    )
      .subscribe(res => {
        this.res = res;
        this.menuItems = this.res.body;
        if (this.menuItems.length > 0) {
          this.showReport1 = true;
        } else {
          this.showReport1 = false;
        }
        this.showReport = true;
      });
    this.queryParams.branch_id = 0
    this.queryParams.branch_id = id;
    this.searchProduct();
  }
  getAreas = () => {
    console.log("english", this.ls.get('language'));
    if (this.ls.get('language') == 'en') {
      this.areaName = 'area_name_english_ASC'
    } else {
      this.areaName = 'area_name_arabic_ASC'
    }
    const quey = `?area_id=all&sort=${this.areaName}`;
    this.service.getAreas(quey).subscribe((area) => {
      this.areas = area.body;
      console.log(this.areas)
    });
  };
  segment(value) {
    this.queryParams.segment_id = 1
    this.queryParams.segment_name = value;
    this.searchProduct();
  }
  onlineSourceApi() {
    let abc = [];
    this.service.onlineorder().subscribe(res => {
      console.log(res.body.onlinetypes)
      res.body.onlinetypes.forEach(ele => {
        console.log(ele);
        if (ele.online_status == true) {
          abc.push(ele)
        }
      })
      this.onlineresorce = abc;
    });
  }
  selectOnlineType(value) {
    console.log("value", value)
    this.queryParams.online_type = 1;
    this.queryParams.onlinetypeNaame = value.onlinetype_id;
    console.log("1")
    this.searchProduct();
  }
  categoryName(value) {
    this.queryParams.category_id = value;
    this.searchProduct();
  }
  searchProduct() {
    console.log("2",new Date())
    let date, todate;
    if (this.fromdate || this.todate) {
      date = this.fromdate
      todate = this.todate
    } else {
      date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      todate = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    }
let query;
if(this.queryParams.category_id){
  // let data = ``
   query = `?filterbyBrand=${this.queryParams.brand_id !== 0 ? 1 : 0}&brandId=${this.queryParams.brand_id}&filterbyBranch=${this.queryParams.branch_id !== 0 ? 1 : 0}&branchId=${this.queryParams.branch_id}&fromdate=${date}&todate=${todate}&filterbySegment=${this.queryParams.segment_id}&segment=${this.queryParams.segment_name}&filterbyOnlineType=${this.queryParams.online_type}&onlineType=${this.queryParams.onlinetypeNaame}&category_id=${this.queryParams.category_id}`
}else{
   query = `?filterbyBrand=${this.queryParams.brand_id !== 0 ? 1 : 0}&brandId=${this.queryParams.brand_id}&filterbyBranch=${this.queryParams.branch_id !== 0 ? 1 : 0}&branchId=${this.queryParams.branch_id}&fromdate=${date}&todate=${todate}&filterbySegment=${this.queryParams.segment_id}&segment=${this.queryParams.segment_name}&filterbyOnlineType=${this.queryParams.online_type}&onlineType=${this.queryParams.onlinetypeNaame}`
}
    
    this.service.getProductFilter(query).subscribe(res => {
      console.log("res", res)
      // res.body.forEach(ele=>{
      //   this.menuItems =  ele.itemDetails
      // })
      // console.log("data",data)
      this.menuItems = res.body;
      if (this.menuItems.length > 0) {
        this.showReport1 = true;
      } else {
        this.showReport1 = false;
      }
    })
  }
}

