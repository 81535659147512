import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MatDatepickerInputEvent } from '@angular/material';
import { LocalStorageService } from 'ngx-store';
import { PosBackOfficeService } from '../providers/pos-back-office.service';

@Component({
  selector: 'app-grv-by-item',
  templateUrl: './grv-by-item.component.html',
  styleUrls: ['./grv-by-item.component.scss']
})
export class GrvByItemComponent implements OnInit {
@Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
grvbyItem:FormGroup;
systemDate: string;
brands: any;
branches: any;
brandName: any;
supplierName: any;
maxDate = new Date();
maxToDate = new Date();
maxFromDate = new Date();
minToDate: any;
fromdate;
todate;
itemReport:any = [];
p:any;
showReports:boolean=true;
showReport:boolean=false;
showPrint:boolean=false;
showPrint1:boolean=false;
selecteditem:any;
showOrderDetails:boolean=false;
totalAmountItem:any;
totalQuantityItem:any;
branchName:any;
totalitemAmount:any;
totalitemQuantity:any;
totalitemPrice:any;
totalPriceItem:any;
suppllier:any;
itemtotal:any;
itemunitprice:any;
filteredNames:any;


constructor(
  private fb: FormBuilder,
  private adapter: DateAdapter<any>,
  public service: PosBackOfficeService,
  private datePipe: DatePipe,
  public ls: LocalStorageService
) { }

  ngOnInit() {
    this.grvbyItem = this.fb.group({
      fromDate: [new Date().toISOString().slice(0, 10)],
      toDate: [new Date().toISOString().slice(0, 10)],
      brand: '',
      branch: '',
      suppliers:'',
      item_name_english: [''],
      
    });
    this.dateAdapter();
    this.getBrand();
    this.systemDate = this.datePipe.transform(this.maxDate, 'yyyy-MM-dd HH:mm');
    this.dateValidation();
  }
  searchByName() {
    if(this.grvbyItem.value.brand == undefined ||this.grvbyItem.value.brand == null || this.grvbyItem.value.brand == "" ){
      this.service.openSnackBar("Select the brand");
    }else{
     
    let query = `?id=${this.grvbyItem.value.brand}`
        this.service.itemsearch(query).subscribe(res => {
          this.filteredNames = res.body.itemResponse;
          console.log("this.filteredNames", this.filteredNames)
        });
      }
  }
  selecteditem_code(data){
    // this.grvbyItem.patchValue({item_name_english:data.item_name_english});
    this.itemunitprice = 0;
    this.itemtotal = 0;
    let sum1 =0;
    let sum2 =0;
    if(this.grvbyItem.value.suppliers == "All"){
      this.suppllier = 0;
    }else{
      this.suppllier = 1;
    }
    this.service.getitemgrv(`?brand_id=${this.grvbyItem.value.brand}&branch_id=${this.grvbyItem.value.branch}&fromdate=${this.fromdate}&todate=${this.todate}&filterbysupplier=${this.suppllier}&supplier_id=${this.grvbyItem.value.suppliers}&item_id=${data.item_id}`).subscribe(
      res => {
        this.itemReport = res.body;
        this.branchName =this.grvbyItem.value.branch;
        this.showReports = false;
        this.showReport = true;
        
        for (var i=0; i<res.body.length; i++){
          for(var j=0; j<res.body[i].item.length; j++){
            sum1 += parseFloat(res.body[i].item[j].unit_price)
            sum2 += parseFloat(res.body[i].item[j].total_amount)
            this.itemunitprice = sum1;
            this.itemtotal = sum2;
            
          }
        }

      },
      error => {
        console.log(error.error.message);
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  clear(){
    this.getitem();
    this.grvbyItem.patchValue({item_name_english:""});
  }
  getBranch(brand, brandName) {
    this.brandName = brandName;
    this.service.Branches(`?brand_id=${brand}`).subscribe(r => {
      this.branches = r.body;
      this. searchByName();
    });
  }
  getBranchName(data) {
    let returnBranch = 'NA';
    const queryBranch = this.branches.filter(res => res.branch_id == data);
    if (queryBranch.length !== 0) {
      returnBranch = queryBranch[0].branch_name;
    }
    return returnBranch;
  }

  getBrand() {
    this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;
      },
      error => {
        console.log('error', error);
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  dateValidation() {
    this.minToDate = this.grvbyItem.value.fromDate;
    this.maxFromDate = this.grvbyItem.value.toDate;
  }
  dateAdapter() {
    this.adapter.setLocale('en-GB');
  }
  getSupplierNames(brandId) {
    this.service.getSuppliergrv(`?id=${brandId}`).subscribe(
      res => {
        this.supplierName = res.body;
      },
      error => {
        console.log(error.error.message);
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  getitem() {
    this.itemunitprice = 0;
    this.itemtotal = 0;
    let sum1 =0;
    let sum2 =0;
    if(this.grvbyItem.value.suppliers == "All"){
      this.suppllier = 0;
    }else{
      this.suppllier = 1;
    }
    this.service.getitemgrv(`?brand_id=${this.grvbyItem.value.brand}&branch_id=${this.grvbyItem.value.branch}&fromdate=${this.fromdate}&todate=${this.todate}&filterbysupplier=${this.suppllier}&supplier_id=${this.grvbyItem.value.suppliers}`).subscribe(
      res => {
        this.itemReport = res.body;
        this.branchName =this.grvbyItem.value.branch;
        this.showReports = false;
        this.showReport = true;
        for (var i=0; i<res.body.length; i++){
          for(var j=0; j<res.body[i].item.length; j++){
            sum1 += parseFloat(res.body[i].item[j].unit_price)
            sum2 += parseFloat(res.body[i].item[j].total_amount)
            this.itemunitprice = sum1;
            this.itemtotal = sum2;            
          }
        }
        this.grvbyItem.patchValue({item_name_english:""});

      },
      error => {
        console.log(error.error.message);
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  getSum(index, array): any {
    let sum = 0;
    let calcArray = array;
    if (calcArray) {
      console.log("calcArray", calcArray)
      for (let i = 0; i < calcArray.length; i++) {
        sum += parseFloat(calcArray[i][index]);
      }
      console.log('sum', sum);
      return sum;
    }
  }

  sortDate() {
    this.dateValidation();
      this.fromdate =
      this.datePipe.transform(this.grvbyItem.value.fromDate, 'yyyy-MM-dd') ||
      this.datePipe.transform(this.grvbyItem.value.toDate, 'yyyy-MM-dd') ||
      '';
    this.todate =
      this.datePipe.transform(this.grvbyItem.value.toDate, 'yyyy-MM-dd') || this.fromdate || '';  
}
print() {
  setTimeout(() => {
    this.printReceipt('item_list_print');
  }, 500);
  this.showPrint = true;
}
printReceipt(div): void {
  let printContents, popupWin;
  printContents = document.getElementById(div).innerHTML;
  popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  popupWin.document.open();
  popupWin.document.write(`
    <html>
      <head>
        <title>Sales Report</title>
        <style>
        //........Customized style.......
        </style>
      </head>
  <body onload="window.print();window.close()">${printContents}</body>
    </html>`);
  popupWin.document.close();
}

}