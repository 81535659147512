import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService } from 'ngx-store';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  menuItem: FormGroup;
  editMenuItem: FormGroup;
  resMenuItem: any;
  saveItems: [];
  brand: any;
  i: Number;
  p;
  formSubmitAttempt: Boolean;
  rule: any;
  payload: any;
  selected
  query: any;
  index: any;
  itemID: '';
  showAvailable: Boolean = true;
  textAlign: string;
  textRight: string;
  resMenuItemBackup: any;
  itemData: any;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) {}

  ngOnInit() {
    this.menuItem = this.fb.group({
      item_name: ['',[Validators.required]],
      brand_id: ['', [Validators.required]],
      item_name_arabic: ['', [Validators.required]]
    });

    this.editMenuItem = this.fb.group({
      item_name: ['', [Validators.required]],
      // fitem_price: ['', [Validators.required, Validators.pattern('^[0-9]*')]],
      brand_id:'',
      item_id: [''],
      item_name_arabic: ['', [Validators.required]]
    });
    // this.getAllItems();
    this.brandData();
    this.inputAlignment();
  }
  patchman1(){
    const one =this.menuItem.get('item_name').value;
    this.menuItem.patchValue({'item_name':one.replace(/^ +/gm, '')});
  }
  inputAlignment() {
    this.textRight = 'textRight';
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  brandData() {
    this.service.getBrandDetails().subscribe(result => {
      this.brand = result.body;
      console.log('brand',result);
    });
  }

  edit(index, item) {
    this.itemData = item;
    this.itemID = item.item_id;
    this.editMenuItem.patchValue({
      item_id: item.item_id,
      item_name: item.item_name,
      item_name_arabic: item.item_name_arabic,
      brand_id:item.brand_id
    });
  }

  delete(rule, payload, query, i) {
    this.rule = rule;
    this.payload = payload;
    this.query = query;
    this.index = i;
    this.popUp();
  }
  deleteMenuItem() {
    this.Item(this.rule, this.payload, this.query, this.index);
    this.popUpOff();
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }
  updateItem(rule, body, query, index){
    if (this.itemData.item_name === this.editMenuItem.value.item_name
      && this.itemData.item_name_arabic === this.editMenuItem.value.item_name_arabic){
        this.Item(rule, body, query, index);
    } else {
      const arrayData = [];
      this.resMenuItem.map(res => {
        if (res.item_id !== this.itemData.item_id) {
          arrayData.push(res);
        }
      });

      if (!arrayData.some(
        res => (res.item_name).toUpperCase().replace(/\s/g,'')
         === (this.editMenuItem.value.item_name).toUpperCase().replace(/\s/g,'')
      ) 
    ) {
      if (!arrayData.some(
        res => (res.item_name_arabic).toUpperCase().replace(/\s/g,'')
         === (this.editMenuItem.value.item_name_arabic).toUpperCase().replace(/\s/g,'')
      ) 
    ) {
      this.Item(rule, body, query, index);
    } else {
      this.service.openSnackBar('Item arabic name already exists!');
    }
    } else {
      this.service.openSnackBar('Item name already exists!');
    }
    }
  }
  postItem(rule, value){

    if (!this.resMenuItem.some(
      res => (res.item_name).toUpperCase().replace(/\s/g,'')
       === (this.menuItem.value.item_name).toUpperCase().replace(/\s/g,'')
    ) 
  ) {
    if (!this.resMenuItem.some(
      res => (res.item_name_arabic).toUpperCase().replace(/\s/g,'')
       === (this.menuItem.value.item_name_arabic).toUpperCase().replace(/\s/g,'')
    ) 
  ) {
   this.Item(rule, value);
  } else {
    this.service.openSnackBar('Item arabic name already exists!');
  }
  } else {
    this.service.openSnackBar('Item name already exists!');
  }
  }
  Item(rule, payload = '', query = '', index = 1) {
    console.log('rule payload,query',rule, payload, query)
    const brandID = this.menuItem.value.brand_id;
    this.service.Item(rule, payload, query).subscribe(
      result => {
        if (rule === 'get') {
          this.resMenuItem = result.body;
          this.resMenuItemBackup = result.body;
        }
        if (rule === 'delete') {
          this.service.openSnackBar('Item Removed!');
          this.Item('get', this.menuItem.value.brand_id, '?verbose=true&brand_id=');
        }
        if (rule === 'post') {
          this.Item('get', this.menuItem.value.brand_id, '?verbose=true&brand_id=');
          this.service.openSnackBar('Item Added');
          this.menuItem.reset();
          this.menuItem.patchValue({brand_id: brandID});
        }
        if (rule === 'put') {
          this.service.openSnackBar('Item Updated!');
          const response = result.body[1];
          this.itemID = '';
          this.Item('get', this.menuItem.value.brand_id, '?verbose=true&brand_id=');
        }
      },
      error => {
        this.service.openSnackBar(error.error.message || 'Internal Server Error');
      }
    );
  }

  isFieldValid(field: string) {
    return (
      (!this.menuItem.get(field).valid && this.menuItem.get(field).touched) ||
      (this.menuItem.get(field).untouched && this.formSubmitAttempt)
    );
  }

  isFieldValid1(field: string) {
    return (
      (!this.editMenuItem.get(field).valid && this.editMenuItem.get(field).touched) ||
      (this.editMenuItem.get(field).untouched && this.formSubmitAttempt)
    );
  }
  getAllItems() {
    this.Item('get');
  }

  applyFilter(filter: String) {
    this.resMenuItem = this.resMenuItemBackup.filter(item => {
      if (
        item.item_name
          .toString()
          .toLowerCase()
          .indexOf(filter.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
  }
}
