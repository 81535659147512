import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { MatDatepickerInputEvent } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
@Component({
  selector: 'app-payment-reports',
  templateUrl: './payment-reports.component.html',
  styleUrls: ['./payment-reports.component.scss']
})
export class PaymentReportsComponent implements OnInit {
  @ViewChild('myCanvas')
  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;
  public chartType = 'line';
  public chartData: any = [];
  public chartLabels: any = ['19-08', '20-08', '21-08', '22-08', '23-08'];
  public chartColors: any = [];
  public finalChartData: any = [];
  brands: any;
  branches: any;
  maxToDate = new Date();
  maxFromDate = new Date();
  minToDate: any;
  paymentReport: FormGroup;
  paymentReports: any;
  payment_report_duplicate: any;
  totalAmount;
  totalDiscount;
  maxDate = new Date();
  totalCardAmount;
  totalCashAmount;
  showReport: boolean = false;
  card_types = [];
  enableBranch;
  p: number = 1;
  defaultQuery;
  fromdate;
  todate;
  card_filter;
  showReport1: boolean;
  printArray: any = [];
  todayDate;
  showPrint: boolean = false;
  showPrint1: boolean = false;
  brandID: any;
  branchID: any;
  card_type: any;
  cards: boolean = false;
  cardss: boolean = false;
  nocards: boolean = false;
  cashAmount:any;
  itemcash:any;
  itemcard:any;
  itemnet:any;
  itemdiscount:any;


  queryParams = {
    Brand: 0,
    Branch: 0,
    cardType: 1,
  };
  constructor(
    public service: PosBackOfficeService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private adapter: DateAdapter<any>,
    public ls: LocalStorageService
  ) { }

  ngOnInit() {
    this.paymentReport = this.fb.group({
      brand: '',
      branch: '',
      fromDate: [new Date().toISOString().slice(0, 10)],
      toDate: [new Date().toISOString().slice(0, 10)],
      select_card_type: '',
    });
    this.getBrand();
    this.dateAdapter();
    this.chartData = [{ data: [65, 59, 80, 81, 56, 55, 40], label: 'Sales', fill: false }];

    this.chartColors = [
      {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderColor: ' gray'
      }
    ];
    this.dateValidation();
  }
  dateAdapter() {
    this.adapter.setLocale('en-GB');
  }
  dateValidation() {
    this.minToDate = this.paymentReport.value.fromDate;
    this.maxFromDate = this.paymentReport.value.toDate;
  }
  getBranch(brand) {
    this.fromdate = this.datePipe.transform(this.paymentReport.value.fromDate, 'yyyy-MM-dd');
    this.todate = this.datePipe.transform(this.paymentReport.value.toDate, 'yyyy-MM-dd');
    this.paymentReport.patchValue({
      branch: ''
    });
    this.enableBranch = true;
    this.service.Branches(`?brand_id=${brand}`).subscribe(r => {
      this.branches = r.body;
    });
    this.paymentTypes(brand);
    this.getReport(
      this.defaultQuery = `?filterbyBrand=${this.queryParams.Brand}&brandId=${this.paymentReport.value.brand}&filterbyBranch=${this.queryParams.Branch}&branchId=${this.paymentReport.value.branch}&fromDate=${this.fromdate}&toDate=${this.todate}&filterbycardType=1&cardType=${this.card_type_id}`
    );
  }

  getBrand() {
    this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;
      },
      error => {
        console.log('error', error);
      }
    );
  }
  card_type_id: any;
  cardType(value) {
    this.paymentReports = this.payment_report_duplicate;
    if (value === 1) {
      this.card_type_id = null;
      this.cards = true;
      this.nocards = false;
      this.cardss = false;
      // this.findTotal(this.paymentReports);
      return true;
    }
    else {
      this.nocards = true;
      this.cards = false;
      this.cardss = false;

    }
    this.card_type_id = value.payment_type_english;
    // this.card_filter = this.paymentReports.filter(r => r.payment_id == value.payment_id);
    // this.paymentReports = this.card_filter;
    // this.findTotal(this.paymentReports);
    this.getReport(
      this.defaultQuery = `?filterbyBrand=${this.queryParams.Brand}&brandId=${this.paymentReport.value.brand}&filterbyBranch=${this.queryParams.Branch}&branchId=${this.paymentReport.value.branch}&fromDate=${this.fromdate}&toDate=${this.todate}&filterbycardType=1&cardType=${this.card_type_id}`
    );

  }

  getReport(value) {
    this.card_type = this.paymentReport.value.select_card_type;
    this.fromdate = this.datePipe.transform(this.paymentReport.value.fromDate, 'yyyy-MM-dd');
    this.todate = this.datePipe.transform(this.paymentReport.value.toDate, 'yyyy-MM-dd');
    const queryBrand = this.paymentReport.value.brand || '';
    const queryBranch = this.paymentReport.value.branch || '';
    const querycardType = this.paymentReport.value.branch || '';
    let query = '?'

    if (value == 'brand' || this.paymentReport.value.brand) {
      this.queryParams.Brand = 1;
      query = query + `filterbyBrand=${this.queryParams.Brand !== 0 ? 1 : 0}&brandId=${queryBrand}&`
    }
    if (value == 'branch' || this.paymentReport.value.branch) {
      this.queryParams.Branch = 1;
      query = query + `filterbyBranch=${this.queryParams.Branch != 0 ? 1 : 0}&branchId=${queryBranch}&`
    }
    if (value == 'select_card_type' || this.paymentReport.value.select_card_type) {
      this.queryParams.cardType = 1;
      query = query + `filterbycardType=${this.queryParams.cardType != 0 ? 1 : 0}&cardType=${querycardType}&`
    }
    
    query = `${value}`
    console.log("query", query.slice(0, -1))
    this.defaultQuery = `?filterbyBrand=${this.queryParams.Brand}&brandId=${this.paymentReport.value.brand}&filterbyBranch=${this.queryParams.Branch}&branchId=${queryBranch}&fromDate=${this.fromdate}&toDate=${this.todate}&filterbycardType=1&cardType=${this.card_type_id}`;
    if (!query) {
      query = this.defaultQuery;
    }
    this.paymentReports = [];
    this.cashAmount = [];
    this.service.getSalesData(query).subscribe(r => {
      if (r.body) {
        this.paymentReports = r.body;
        let sum1=0;
        let sum2=0;
        let sum3=0;
        let sum4=0;

        for (var i=0; i<r.body.length; i++){
          for(var j=0; j<r.body[i].card.length; j++){     
            sum1 += parseFloat(r.body[i].card[j].discount_amount)
            sum2 += parseFloat(r.body[i].card[j].net_cost)
            sum3 += parseFloat(r.body[i].card[j].card_amount)
            sum4 += parseFloat(r.body[i].card[j].cash_amount)
            this.itemdiscount = sum1;
            this.itemnet = sum2;
            this.itemcard = sum3;
            this.itemcash = sum4;
            
          }
        }
        if (this.paymentReports.length > 0) {
          this.showReport1 = true;

        } else {
          this.showReport1 = false;
        }
        this.payment_report_duplicate = this.paymentReports;
        this.brandID = this.paymentReport.value.brand;
        this.branchID = this.paymentReport.value.branch;
        // this.findTotal(this.paymentReports);
        this.showReport = true;
        if (value === 1) {
          this.cardss = false;
        } else {
          this.cardss = true;
    
        }

      }
    });
  }
  getSum(index, array): number {
    let sum = 0;
    let calcArray = array;
    if (calcArray) {
      console.log("calcArray", calcArray)
      for (let i = 0; i < calcArray.length; i++) {
        sum += calcArray[i][index];
      }
      console.log('sum', sum);
      return sum;
    }
  }
  



  filterByDate() {
    this.branchPrinterData();
    this.fromdate =
      this.datePipe.transform(this.paymentReport.value.fromDate, 'yyyy-MM-dd') ||
      this.datePipe.transform(this.paymentReport.value.toDate, 'yyyy-MM-dd') ||
      '';
    this.todate =
      this.datePipe.transform(this.paymentReport.value.toDate, 'yyyy-MM-dd') || this.fromdate || '';
    this.getReport(
      this.defaultQuery = `?filterbyBrand=${this.queryParams.Brand}&brandId=${this.paymentReport.value.brand}&filterbyBranch=${this.queryParams.Branch}&branchId=${this.paymentReport.value.branch}&fromDate=${this.fromdate}&toDate=${this.todate}&filterbycardType=${this.queryParams.cardType}&cardType=${this.card_type_id}`
    );

  }
  

  // findTotal(data) {
  //   this.totalAmount = data.reduce(function (cnt, o) {
  //         return cnt + o.net_cost;
  //       }, 0);
    
  //   this.totalDiscount = data.reduce(function (cnt, o) {
  //     return cnt + o.discount_amount;
  //   }, 0);
  //   this.totalCardAmount = data.reduce(function (cnt, o) {
  //     return cnt + o.card_amount;
  //   }, 0);
  //   this.totalCashAmount = data.reduce(function (cnt, o) {
  //     return cnt + o.cash_amount;
  //   }, 0);
  // }



  getBranchName(data) {
    let returnBranch = 'NA';
    const queryBranch = this.branches.filter(res => res.branch_id == data);
    if (queryBranch.length !== 0) {
      returnBranch = queryBranch[0].branch_name;
    }
    return returnBranch;
  }
  getBrandName(data) {
    let returnBrand = 'NA';
    const queryBrand = this.brands.filter(res => res.brand_id == data);
    if (queryBrand.length !== 0) {
      returnBrand = queryBrand[0].brand_name;
    }
    return returnBrand;

  }

  paymentTypes(data) {
    this.card_types = [];
    this.service.getCardTypes(data).subscribe(r => {
      this.card_types = r.body;
      // this.card_types.push({ payment_type_english: 'All', payment_type_arabic: 'All' });
    });
  }
  print() {
    const today = Date.now();
    this.todayDate = this.datePipe.transform(today, "dd-MM-yyyy HH:mm");
    setTimeout(() => {
      this.printReceipt('credit_card_report');
    }, 1000);
    this.showPrint = true;
  }
  printReceipt(div): void {
    let printContents, popupWin;
    printContents = document.getElementById(div).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Sales Report</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
    let payload = {
      printer_name: this.ls.get("manager_printer"),
      printer_data: printContents,
    };
    this.printArray.push(payload);
    this.service.printer(this.printArray).subscribe(
      res => {
        this.printArray = [];
      },
      err => {
        this.printArray = [];
        console.log("error", err);
      });
  }
  print1() {
    const today = Date.now();
    this.todayDate = this.datePipe.transform(today, "dd-MM-yyyy HH:mm");
    setTimeout(() => {
      this.printReceipt1('credit_card_report_all');
    }, 1000);
    this.showPrint1 = true;
  }
  printReceipt1(div): void {
    let printContents, popupWin;
    printContents = document.getElementById(div).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Sales Report</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
    let payload = {
      printer_name: this.ls.get("manager_printer"),
      printer_data: printContents,
    };
    this.printArray.push(payload);
    this.service.printer(this.printArray).subscribe(
      res => {
        this.printArray = [];
      },
      err => {
        this.printArray = [];
        console.log("error", err);
      });
  }
  branchPrinterData() {
    this.service
      .branchPrinters(`?id=${this.paymentReport.value.branch}`)
      .subscribe(
        r => {
          if (r.body.length > 0) {
            console.log("print api res", r);
            this.ls.set("manager_printer", r.body[0].printer_name);
            this.ls.set("printerIp", r.body[0].printer_ip);
            this.service.printApi = `http://${r.body[0].printer_ip}:5000/`;
          }
        },
        error => {
          this.service.openSnackBar(error.error.message);
        }
      );
  }
}
