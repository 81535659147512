import { Component, OnInit, EventEmitter } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';

@Component({
  selector: 'app-branch-setup',
  templateUrl: './branch-setup.component.html',
  styleUrls: ['./branch-setup.component.scss']
})
export class BranchSetupComponent implements OnInit {
  textAlign: string;
  printerDetails: any;
  printer_id: any;
  textRight: string;
  backupItems: any;
  branches;
  minValueGreterMaxValue = true;
  noImageshow: boolean;
  searchCategoty: boolean;
  disableCreateMenu: boolean;
  deleteIndex: any;
  editMenu: any;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) {
    this.listOfSegments = service.segmentForm;
  }
  menuItemMaster: FormGroup;
  categoryData: [];
  itemGroup: [];
  itemData: [];
  menuData: any;
  brandData: any;
  submitStatus: Boolean = true;
  allBranches: any = [];
  formSubmitAttempt: Boolean;
  branchData: any = [];
  allDataUnique = new Set();
  allData: any = [];
  updateStatus: Boolean = false;
  showsubmit: Boolean = true;
  menuId: Object;
  showMenuData: Boolean = false;
  showItemsData: Boolean = false;
  showMenuData1: Boolean = false;
  clickedOnEdit = false;
  collapse: boolean = true;

  logo: any = './../../assets/images/uploadimage.jpg';
  showPdfErrorMsg: Boolean;
  imagedata: String = '';

  public uploader: FileUploader = new FileUploader({});
  listOfSegments = [];

  checkBoxFalse(data, form) { }

  ngOnInit() {
    this.menuCRUD('get');
    this.menuItemMaster = this.fb.group({
      menu_price: [0, [Validators.required, Validators.min(0)]],
      menu_name: [''],
      menu_name_arabic: ['',],
      category_id: ['', Validators.required],
      category_name: ['', Validators.required],
      menu_data: [[]],
      brand_id: [''],
      take_out: [false],
      car_service: [false],
      staff_meal: [false],
      dine_in: [false],
      catering: [false],
      delivery: [false],
      itemPrice: [0, [Validators.min(0)]],
      menu_status: [true, Validators.required],
      menu_item_status: [true],
      printer_id: [''],
      printer_name: [''],
      searchCategory: [''],
      menu_order: ['', [Validators.required, Validators.min(1)]],
      minQuantity: ['', Validators.pattern('^[0-9]$')],
      maxQuantity: ['', Validators.pattern('^[0-9]$')],
      image_id: null
    });
    this.inputAlignment();
    for(var t=0;t<this.listOfSegments.length;t++){
      this.menuItemMaster.addControl(this.listOfSegments[t].key, new FormControl(''));
    }
  }

  inputAlignment() {
    this.textRight = 'textRight';
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  patchman() {
    const one = this.menuItemMaster.get('menu_name').value;
    this.menuItemMaster.patchValue({ 'menu_name': one.replace(/^ +/gm, '') })
  }

  ItemDetails(brand_id) {
    this.service.Item('get', brand_id, '?verbose=true&brand_id=').subscribe(
      r => {
        if (r.status) {
          if (r.body.length > 0) {
            r.body.sort(function (a, b) {
              var nameA = a.item_name.toLowerCase(), nameB = b.item_name.toLowerCase()
              if (nameA < nameB) //sort string ascending
                return -1
              return 0 //default return value (no sorting)
            })
            this.itemData = r.body;
          }
        }

        // this.showMenuData = true;
        // this.showMenuData1 = false;
      },
      error => {
        console.log('error', error);
      }
    );
  }

  dummyGroup = { items: [], group_name: 'Individual items' };
  dummyGroupItemsUnique = new Set();

  itemToMenu(item) {
    this.dummyGroupItemsUnique.add(item);
    const items = Array.from(this.dummyGroupItemsUnique);

    // find individual item's existance
    const findIndividualItems = this.allData.find(item => !item.group_id);
    // find individual item's existance

    this.dummyGroup.items = items;
    this.allDataUnique.add(this.dummyGroup);

    // condition for inidvidual items existance
    if (findIndividualItems) {
      // find the index of the individual Items object
      const singleItemindex = this.allData.indexOf(findIndividualItems);
      // find the index of the individual Items object

      if (!this.allData[singleItemindex].items.find(indexTwo => indexTwo.item_id == item.item_id)) {
        this.allData[singleItemindex].items.push(item);
        return true;
      }
    } else if (this.allData.find(item => item.group_id)) {
      this.allData.push({ items: [item], group_name: 'Individual items' });
      return true;
    } else if (this.allData <= 0) {
      this.allData.push({ items: [item], group_name: 'Individual items' });
    }
  }

  categoryName(data) {
    this.menuItemMaster.patchValue({
      category_name: data
    });
  }

  categorySearch(data) {
    this.menuItemMaster.patchValue({
      category_name: data
    });
    this.service
      .newMenu('get', '', `?verbose=true&category_id=${this.menuItemMaster.value.searchCategory}`)
      .subscribe(
        r => {
          this.menuData = r.body;
        },
        error => {
          this.service.openSnackBar('Error in fetching menu data');
        }
      );
  }

  async submit() {
    this.disableCreateMenu = true;
    const allBranches = [];

    this.branchData.map(r => {
      if (r.checked) {
        delete r.checked;
        allBranches.push(r);
      }
    });

    this.menuItemMaster.patchValue({ menu_data: this.allData });
    if (this.menuItemMaster.value.menu_data.length > 0) {
      this.menuItemMaster.value.menu_data.forEach(element => {
        if (element.group_name != 'Individual items') {
          const minQty = element.min_qty;
          const maxQty = element.max_qty;
          if (minQty <= maxQty) {
            this.minValueGreterMaxValue = true;
          } else {
            this.minValueGreterMaxValue = false;
          }
        }
      });
    }
    if (this.minValueGreterMaxValue) {
      if (this.menuItemMaster.value.menu_data.length == 0) {
        if (this.menuItemMaster.value.menu_price === 0) {
          this.service.openSnackBar('Add menu price');
          this.disableCreateMenu = false;
          return;
        }
      }
      console.log('this.menuItemMaster.value', this.menuItemMaster.value);
      const formData = this.menuItemMaster.value;
      let Obj: any;
      Obj = {
        menu_price: this.menuItemMaster.value.menu_price,
        menu_name: this.menuItemMaster.value.menu_name,
        menu_name_arabic: this.menuItemMaster.value.menu_name_arabic,
        category_id: this.menuItemMaster.value.category_id,
        category_name: this.menuItemMaster.value.category_name,
        menu_data: this.menuItemMaster.value.menu_data,
        brand_id: this.menuItemMaster.value.brand_id,
        take_out: this.menuItemMaster.value.take_out,
        car_service: this.menuItemMaster.value.car_service,
        staff_meal: this.menuItemMaster.value.staff_meal,
        dine_in: this.menuItemMaster.value.dine_in,
        catering: this.menuItemMaster.value.catering,
        delivery: this.menuItemMaster.value.delivery,
        itemPrice: this.menuItemMaster.value.itemPrice,
        menu_status: this.menuItemMaster.value.menu_status,
        menu_item_status: this.menuItemMaster.value.menu_item_status,
        // printer_id: this.menuItemMaster.value.printer_id,
        // printer_name: this.menuItemMaster.value.printer_name,
        searchCategory: this.menuItemMaster.value.searchCategory,
        menu_order: this.menuItemMaster.value.menu_order,
        minQuantity: this.menuItemMaster.value.minQuantity,
        maxQuantity: this.menuItemMaster.value.maxQuantity,
        image_id: this.menuItemMaster.value.image_id
      }
      if (formData.printer_id) {
        Obj = {
          menu_price: this.menuItemMaster.value.menu_price,
          menu_name: this.menuItemMaster.value.menu_name,
          menu_name_arabic: this.menuItemMaster.value.menu_name_arabic,
          category_id: this.menuItemMaster.value.category_id,
          category_name: this.menuItemMaster.value.category_name,
          menu_data: this.menuItemMaster.value.menu_data,
          brand_id: this.menuItemMaster.value.brand_id,
          take_out: this.menuItemMaster.value.take_out,
          car_service: this.menuItemMaster.value.car_service,
          staff_meal: this.menuItemMaster.value.staff_meal,
          dine_in: this.menuItemMaster.value.dine_in,
          catering: this.menuItemMaster.value.catering,
          delivery: this.menuItemMaster.value.delivery,
          itemPrice: this.menuItemMaster.value.itemPrice,
          menu_status: this.menuItemMaster.value.menu_status,
          menu_item_status: this.menuItemMaster.value.menu_item_status,
          printer_id: this.menuItemMaster.value.printer_id,
          printer_name: this.menuItemMaster.value.printer_name,
          searchCategory: this.menuItemMaster.value.searchCategory,
          menu_order: this.menuItemMaster.value.menu_order,
          minQuantity: this.menuItemMaster.value.minQuantity,
          maxQuantity: this.menuItemMaster.value.maxQuantity,
          image_id: this.menuItemMaster.value.image_id
        }
      }


      this.service.newMenu('post', Obj).subscribe(r => {
        const { brand_id } = r.body;
        this.populateMenuDetails(brand_id);
        this.allData = [];
        this.allDataUnique.clear();
        this.menuItemMaster.reset();
        this.menuItemMaster.patchValue({
          menu_price: 0,
          menu_status: true,
          searchCategory: '',
          take_out: true,
          car_service: true,
          staff_meal: true,
          dine_in: true,
          catering: true,
          delivery: true
        });
        this.logo = './../../assets/images/uploadimage.jpg';
        this.disableCreateMenu = false;
        this.service.openSnackBar('Menu Created!');
      });
    } else {
      this.service.openSnackBar('Min Qty Should Be Equal Or Less Than Max Qty');
    }
  }

  async menuCRUD(rule, payload = '', query = '', index = 1) {
    this.service.brands(rule, payload, query).subscribe(
      async result => {
        if (rule === 'get') {
          this.brandData = await result.body;
        }
        if (rule === 'get1') {
          this.branchData = this.allCheckFalse(result.body);
        }
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  cancelUpdate() {
    this.clickedOnEdit = false;
    this.allData = [];
    this.submitStatus = true;
    this.updateStatus = false;
    this.logo = './../../assets/images/uploadimage.jpg';
  }

  reset() {
    this.menuItemMaster.setValue({
      menu_price: 0,
      menu_name: '',
      menu_name_arabic: '',
      category_id: '',
      category_name: '',
      printer_name: '',
      menu_data: [],
      brand_id: '',
      printer_id: '',
      image_id: null,
      searchCategory: '',
      menu_order: '',
      minQuantity: 0,
      maxQuantity: 0,
      itemPrice: 0,
      menu_status: true,
      menu_item_status: true,
      ...this.service.segments
    });
  }

  groupData(id) {
    this.showMenuData1 = true;
    this.showItemsData = false;
    this.showMenuData = true;
    this.clickedOnEdit = false;
    // this.updateStatus = false;
    // this.reset();
    this.menuItemMaster.patchValue({
      menu_status: true
    });
    this.menuItemMaster.patchValue({
      menu_price: 0
    });
    this.menuItemMaster.patchValue({
      brand_id: id
    });
    this.menuItemMaster.patchValue({
      menu: ''
    });

    this.populateMenuDetails(id);
    this.fetchPrinterDetails();

    this.service.MenuItemGroup('get', '', `?brand_id=${id}`).subscribe(
      result => {
        if (result.status) {
          if (result.body.length > 0) {
            result.body.sort((a, b) => {
              var nameA = a.group_name.toLowerCase(), nameB = b.group_name.toLowerCase()
              if (nameA < nameB) //sort string ascending
                return -1
              return 0 //default return value (no sorting)
            })
            this.itemGroup = result.body;
          }
        }

      },
      error => {
        console.log('error', error);
      }
    );
    this.service
      .menuCategoryCatalog('get', '', `?verbose=true&brand_id=${id}&category_status=both`)
      .subscribe(
        result => {
          this.categoryData = result.body;
        },
        error => {
          console.log('error', error);
        }
      );
    this.getBranch(id);
  }

  fetchPrinterDetails() {
    this.service.fetchPrinter().subscribe(r => {
      this.printerDetails = r.body;

    }); 
  }

  printerName(data) {
    console.log("print data", data)
    this.menuItemMaster.patchValue({
      printer_name: data
    });
  }

  async viewDetails(data) {
    if (data.items.length === 0) {
      this.service.openSnackBar('Menu item group empty. Please add items');
    } else {
      delete data['brand'];
      this.allDataUnique.add(data);
      if (!this.allData.some(r => r.group_id === data.group_id)) {
        this.allData.push(data);
      } else {
        this.service.openSnackBar('Menu item group already added');
      }
    }
  }

  priceMapping(value, groupIndex, itemIndex) {
    if (value) {
      this.allData[groupIndex].items[itemIndex].item_price = parseFloat(value);
      console.log('notr');
    } else {
      this.allData[groupIndex].items[itemIndex].item_price = 0;
      console.log('empty');
    }

  }
  maxMapping(max, data) {
    data.max_qty = parseInt(max);
  }
  minMapping(min, data) {
    data.min_qty = parseInt(min);
  }
  deleteItemGroup(group, i) {
    // this.allDataUnique.delete(group);

    // if (group.group_name === 'Individual items') {
    //   this.dummyGroupItemsUnique.clear();
    // }
    this.popUp();
    this.deleteIndex = i;
  }

  deleteMenuData1() {
    this.allData.splice(this.deleteIndex, 1);
    this.popUpOff();
  }

  rm(array, key, findData) {
    //  removes found element
    return array.filter(ele => ele[key] !== findData);
  }

  populateMenuDetails(brand_id) {
    this.service.newMenu('get', '', `?verbose=true&brand_id=${brand_id}`).subscribe(
      r => {
        if (r.body.length > 0) {
          this.noImageshow = false;
          this.searchCategoty = true;
        } else {
          this.noImageshow = true;
          this.searchCategoty = false;
        }

        this.menuItemMaster.patchValue({ brand_id });
        this.menuData = r.body;
        console.log("this.menuData", this.menuData)
        this.backupItems = r.body;
      },
      error => {
        this.service.openSnackBar('Error in fetching menu data');
      }
    );
  }

  editMenuData(data) {
    console.log('data', data);
    this.editMenu = data;
    this.showMenuData1 = false;
    this.showItemsData = true;
    this.clickedOnEdit = true;
    this.submitStatus = false;
    this.updateStatus = true;
    this.menuId = { menu_id: data.menu_id };
    this.menuItemMaster.patchValue(data);
    this.allData = data.menu_data;
    this.service.getImage(data.image_id).subscribe(
      (r: any) => {
        if (r.body.length !== 0) {
          this.logo = r.body[0].image_data;
        }
      },
      error => {
        this.logo = './../../assets/images/uploadimage.jpg';
      }
    );
  }
  deleteMenuData(data) {
    this.service.newMenu('delete', data.menu_id, '?id=').subscribe(
      result => {
        this.populateMenuDetails(data.brand_id);
        this.service.openSnackBar('Menu Deleted');
      },
      error => {
        this.service.openSnackBar('Unable to delete selected menu');
      }
    );
  }

  clickOnBranch(checked, data, i) {
    if (checked) {
      this.populateMenuDetails(data.brand_id);
      this.branchData[i].checked = true;
    } else {
      this.branchData[i].checked = false;
      this.menuData = null;
    }
  }

  clickOnSegments(checked, data, i, segmentName?) {
    if (checked) {
      return (this.branchData[i][segmentName] = true);
    } else {
      return (this.branchData[i][segmentName] = false);
    }
  }

  allCheckFalse(array) {
    array.map(r => {
      r.checked = false;
    });
    return array;
  }

  isFieldValid(field: string) {
    return (
      (!this.menuItemMaster.get(field).valid && this.menuItemMaster.get(field).touched) ||
      (this.menuItemMaster.get(field).untouched && this.formSubmitAttempt)
    );
  }
  update() {
    this.menuItemMaster.patchValue({ menu_data: this.allData });
    if (this.menuItemMaster.value.menu_data.length > 0) {
      this.menuItemMaster.value.menu_data.forEach(element => {
        if (element.group_name != 'Individual items') {
          const minQty = element.min_qty;
          const maxQty = element.max_qty;
          if (minQty <= maxQty) {
            this.minValueGreterMaxValue = true;
          } else {
            this.minValueGreterMaxValue = false;
          }
        }
      });
    }
    if (this.minValueGreterMaxValue) {
      this.service.newMenu('put', { ...this.menuItemMaster.value, ...this.menuId }).subscribe(
        r => {
          this.clickedOnEdit = false;
          this.submitStatus = true;
          const { brand_id } = r.body[1][0];
          // this.populateMenuDetails(brand_id);
          this.updateStatus = false;
          this.updateStatus = false;
          this.allData = [];
          this.allDataUnique.clear();
          // this.reset();
          this.logo = './../../assets/images/uploadimage.jpg';
          this.menuItemMaster.patchValue({
            menu_price: 0
          });
          this.service.openSnackBar('Menu updated');
          this.stayCategoryNames();
        },
        error => {
          this.updateStatus = true;
          this.service.openSnackBar('error happend while updating menu');
        }
      );
      console.log(this.menuItemMaster.value.searchCategory)
    } else {
      this.service.openSnackBar('Min Qty Should Be Equal Or Less Than Max Qty');
    }
  }
  stayCategoryNames() {
    this.service
      .newMenu('get', '', `?verbose=true&category_id=${this.menuItemMaster.value.searchCategory}`)
      .subscribe(
        r => {
          this.menuData = r.body;
        },
        error => {
          this.service.openSnackBar('Error in fetching menu data');
        }
      );

  }

  inputChangeEvent(e: String) {
    this.menuItemMaster.patchValue({ searchCategory: '' });
    const checkForNumber = /[0-9]/g;
    if (e.match(checkForNumber)) {
      return;
    }
    if (!/[^a-zA-Z0-9]/.test(e.toLowerCase())) {
      return this.searchForaName(e);
    }
    return;
  }

  getBranch(brand) {
    const brandSegment = {
      take_out: false,
      car_service: false,
      staff_meal: false,
      dine_in: false,
      catering: false,
      delivery: false
    };

    this.service.Branches(`?brand_id=${brand}`).subscribe(r => {
      this.branches = r.body;

      for (let branch of this.branches) {
        branch.take_out == true ? (brandSegment.take_out = true) : null;
        branch.car_service == true ? (brandSegment.car_service = true) : null;
        branch.staff_meal == true ? (brandSegment.staff_meal = true) : null;
        branch.dine_in == true ? (brandSegment.dine_in = true) : null;
        branch.catering == true ? (brandSegment.catering = true) : null;
        branch.delivery == true ? (brandSegment.delivery = true) : null;
      }

      this.menuItemMaster.patchValue({
        take_out: brandSegment.take_out,
        car_service: brandSegment.car_service,
        staff_meal: brandSegment.staff_meal,
        dine_in: brandSegment.dine_in,
        catering: brandSegment.catering,
        delivery: brandSegment.delivery
      });
    });
  }

  searchForaName(e) {
    const makeQuery = (property, regexp) => (elem, index, array) =>
      elem[property].search(regexp) !== -1;
    if (e) {
      this.menuData = this.backupItems;
      const i = new RegExp('^' + e, 'i');
      const q = makeQuery('menu_name', i);
      this.menuData.filter(q);
      return (this.menuData = this.menuData.filter(q));
    }
    return (this.menuData = this.backupItems);
  }
  createMenu() {
    this.showItemsData = true;
    this.showMenuData1 = false;
    this.updateStatus = false;
    this.submitStatus = true;
    this.allData = [];
  }
  cancelMenu() {
    this.showItemsData = false;
    this.showMenuData1 = true;
    this.logo = './../../assets/images/uploadimage.jpg';
  }
  // image function
  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    if (
      file.type == 'image/jpeg' ||
      file.type == 'image/png' ||
      file.type == 'image/jpg' ||
      file.type == 'image/JPEG' ||
      file.type == 'image/PNG' ||
      file.type == 'image/JPG'
    ) {
      this.showPdfErrorMsg = false;
    } else {
      this.showPdfErrorMsg = true;
    }
    const FileSize = file.size / 1024 / 1024;

    if (FileSize > 0.5) {
      return this.service.openSnackBar(`File size exceeds 500KB`);
    }
    this.imagedata = file.name;
    const formData = new FormData();
    formData.append('file', file);
    this.service.upload(formData).subscribe(
      result => {
        this.menuItemMaster.patchValue({ image_id: result.body.image_id });
        this.service.getImage(result.body.image_id).subscribe(
          (r: any) => {
            this.logo = r.body[0].image_data;
          },
          error => {
            this.logo = './../../assets/images/uploadimage.jpg';
          }
        );
      },
      error => {
        this.logo = './../../assets/images/uploadimage.jpg';
      }
    );
  }

  itemStatusMapping(data, event) {
    if (event.checked) {
      return (data.item_status = true);
    }
    data.default_status = false;
    data.item_status = false;
  }

  defaultStatusMapping(data, event, element) {
    if (event.checked && data.item_status == true) {
      data.default_status = true;
    } else {
      data.default_status = false;
      event.source._checked = false;
    }
    let item_default_count = [];
    element.items.forEach(r => {
      if (r.default_status == true) {
        item_default_count.push(1);
      }
    });

    if (element.max_qty != 0) {
      if (element.max_qty < item_default_count.length) {
        event.source._checked = false;
        data.default_status = false;
        return this.service.openSnackBar('Default items cannot be more than maximum quantity');
      }
    }
  }
  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  submitMenu() {
    // console.log("this.masterrrrrrrr", this.menuData[0].menu_name_arabic.toUpperCase(), "aa",this.menuItemMaster.value.menu_name_arabic.toUpperCase());
  //  if(this.menuData.some(res => (res.menu_name)) !== ''){

  //  }
    if ((!this.menuData.some(res => (res.menu_name) === (this.menuItemMaster.value.menu_name))) || this.menuItemMaster.value.menu_name == '') {
      if ((!this.menuData.some(res => (res.menu_name_arabic) === (this.menuItemMaster.value.menu_name_arabic))) || this.menuItemMaster.value.menu_name_arabic == '') {
        this.submit();
      } else {
        this.service.openSnackBar('Menu arabic name already exists!');
      }
    } else {
      this.service.openSnackBar('Menu name already exists!');
    }
  }

  updateMenu() {
    const categoryDataArray = [];
    if (this.editMenu.menu_order == this.menuItemMaster.value.menu_order
      && this.editMenu.menu_name == this.menuItemMaster.value.menu_name
      && this.editMenu.menu_name_arabic == this.menuItemMaster.value.menu_name_arabic) {
      return this.update();
    } else {
      this.menuData.forEach(element => {
        if (element.menu_id !== this.editMenu.menu_id) {
          categoryDataArray.push(element);
        }
      });
    }

    if ((!categoryDataArray.some(res => (res.menu_name) === (this.menuItemMaster.value.menu_name))
    ) || this.menuItemMaster.value.menu_name == '') {
      if ((!categoryDataArray.some(
        res => (res.menu_name_arabic)
          === (this.menuItemMaster.value.menu_name_arabic)
      )
      ) || this.menuItemMaster.value.menu_name_arabic == '') {
        // if (!categoryDataArray.some(res => (res.menu_order === this.menuItemMaster.value.menu_order)
        // )) {
        this.update();
        // } else {
        //   this.service.openSnackBar('Menu order already exists!');
        // }
      } else {
        this.service.openSnackBar('Menu arabic name already exists!');
      }
    } else {
      this.service.openSnackBar('Menu name already exists!');
    }
  }

}
