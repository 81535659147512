import { Component, OnInit, HostListener,EventEmitter } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService } from 'ngx-store';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';


import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
@Component({
  selector: 'app-menu-category',
  templateUrl: './menu-category.component.html',
  styleUrls: ['./menu-category.component.scss']
})
export class MenuCategoryComponent implements OnInit {
  p: Number = 1;
  category: any = [];
  showAddButton: Boolean = true;
  rule: any;
  payload: any;
  query: any;
  categoryId: '';
  i: Number;
  index: any;
  menuCategory: FormGroup;
  menuCategoryEdit: FormGroup;
  formSubmitAttempt1: Boolean;
  formSubmitAttempt: Boolean;
  brand: any;
  showAdd = true;
  textAlign: string;
  textRight: string;
  showAvailable: Boolean = true;
  categoryBackup: any;
  buttonClick: Boolean = false;
  showCreateMenu: Boolean = false;
  showPagination: boolean;
  showCategory: boolean;
  imagedata: String = '';
  logo: any = './../../assets/images/upload.jpg';
  


    showPdfErrorMsg:boolean;
  public uploader: FileUploader = new FileUploader({});
  categoryEditData: any;


  // @HostListener('click', ['$event'])
  // onClick(e) {
  //   console.log('e',e);
  //   if (e.target.id) {

  //   } else if (e.target.className == "mat-checkbox-inner-container mat-checkbox-inner-container-no-side-margin") {

  //   } else {
  //     this.categoryId = '';
  //   }
  // }
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) { 
    
  }

  ngOnInit() {
    this.menuCategory = this.fb.group({
      category_name: ['', Validators.required],
      brand_id: ['', Validators.required],
      category_id: [''],
      category_order: ['', [Validators.required, Validators.min(1)]],
      category_status: [true],
      category_name_arabic: ['', Validators.required],
      image_id: [null],
    });
    this.menuCategoryEdit = this.fb.group({
      category_name: ['', Validators.required],
      brand_id: ['', Validators.required],
      category_id: [''],
      category_order: ['', [Validators.required, Validators.min(1)]],
      category_status: [true],
      image_id: [null],
      category_name_arabic: ['', Validators.required]
    });
    this.brandData();
    this.inputAlignment();
  }
  inputAlignment() {
    this.textRight = 'textRight';
    const lang = this.ls.get('language');
    if (lang === 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  brandData() {
    this.service.getBrandDetails().subscribe(result => {
      this.brand = result.body;
    });
  }

  menuCategoryDelete(data) {
    this.payload = data;
    this.popUp();
  }

  deleteCategory() {
    this.menuCategoryCatalog('delete', this.payload.category_id, '?id=');
  }

  createMenuData() {
    this.showCreateMenu = true;
    this.showAddButton = false;
  }

  submitMenuCategory(rule, body, query) {

    if (!this.category.some(res => (res.category_name).toUpperCase().replace(/\s/g,'') 
    === (body.category_name).toUpperCase().replace(/\s/g,'')
    )) {
      if (!this.category.some(res => (res.category_name_arabic).toUpperCase().replace(/\s/g,'') 
      === (body.category_name_arabic).toUpperCase().replace(/\s/g,'')
      )) {
            if (!this.category.some(res => (res.category_order === body.category_order)
        )) {
          this.menuCategoryCatalog(rule, body, query);
        } else {
          this.service.openSnackBar('Category order already exists!');
        }
      } else {
        this.service.openSnackBar('Category arabic name already exists!');
      }
  
    } else {
      this.service.openSnackBar('Category name already exists!');
    }

  }

  updateMenuCategory(rule, body, query) {
    const categoryDataArray = [];
    console.log('body', body);
    if (this.categoryEditData.category_order == body.category_order 
      && this.categoryEditData.category_name == body.category_name
      && this.categoryEditData.category_name_arabic == body.category_name_arabic) {
      this.menuCategoryCatalog(rule, body, query);
    } else {
      this.category.forEach(element => {
        if(element.category_id !== body.category_id){
          categoryDataArray.push(element);
        }
      });
    }

    if (!categoryDataArray.some(res => (res.category_name).toUpperCase().replace(/\s/g,'')
     === (body.category_name).toUpperCase().replace(/\s/g,'')
    )) {
      if (!categoryDataArray.some(res => (res.category_name_arabic).toUpperCase().replace(/\s/g,'')
       === (body.category_name_arabic).toUpperCase().replace(/\s/g,'')
      )) {
        if (!categoryDataArray.some(res => (res.category_order === body.category_order)
        )) {
          this.menuCategoryCatalog(rule, body, query);
        } else {
          this.service.openSnackBar('Category order already exists!');
        }
      } else {
        this.service.openSnackBar('Category arabic name already exists!');
      }
    } else {
      this.service.openSnackBar('Category name already exists!');
    }
  }

   menuCategoryCatalog(rule, payload = '', query = '', index = 1) {
     const brandID = this.menuCategory.value.brand_id;
     this.showCategory = true;
    this.service.menuCategoryCatalog(rule, payload, query).subscribe(
      result => {
        if (rule === 'post') {
          this.menuCategory.reset();
          this.menuCategory.patchValue({ category_status: true, brand_id: brandID });
          this.menuCategoryCatalog(
            'get',
            result.body.brand_id,
            '?verbose=true&category_status=both&brand_id='
          );
          this.logo = './../../assets/images/upload.jpg';
          this.service.openSnackBar('Category Added!');
        }
        if (rule === 'get') {
          this.category = result.body;
          console.log('this.category', this.category);
          this.categoryBackup = result.body;
          this.category.map(element => {
            if (element.image_id == null) {
              element.logo = './../../assets/images/noImage123.jpg';
            } else {
              this.service.getImage(element.image_id).subscribe((r: any) => {
                if (r.body.length !== 0) {
                  element.logo = r.body[0].image_data;
                }
              });
            }

          });
          this.buttonClick = true;
            if (this.category.length > 10) {
              this.showPagination = true;
            } else {
              this.showPagination = false;
            }
        }
        if (rule === 'delete') {
          this.popUpOff();
          this.service.openSnackBar('Category Deleted!');
          this.menuCategoryCatalog('get', this.payload.brand_id, '?verbose=true&brand_id=');
        }
        if (rule === 'put') {
          this.service.openSnackBar('Category Updated!');
          this.menuCategoryCatalog(
            'get',
            this.menuCategoryEdit.value.brand_id,
            '?verbose=true&category_status=both&brand_id='
          );
          this.showAdd = true;
          this.menuCategory.reset();
          this.categoryId = '';
          this.menuCategory.patchValue({
            category_status: true, brand_id: brandID
          });
        }
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );

  }
  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  edit(data, index) {
    this.categoryEditData = data;
    console.log('body', data);
    this.categoryId = data.category_id;
    // this.showAdd = false;
    this.menuCategoryEdit.patchValue(data);
  }

  isFieldValid(field: string) {
    return (
      (!this.menuCategory.get(field).valid && this.menuCategory.get(field).touched) ||
      (this.menuCategory.get(field).untouched && this.formSubmitAttempt)
    );
  }

  applyFilter(filter: String) {
    this.category = this.categoryBackup.filter(item => {
      if (
        item.category_name
          .toString()
          .toLowerCase()
          .indexOf(filter.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
  }
   // image function
   public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.showPdfErrorMsg = false;
      console.log('no');
    } else {
      this.showPdfErrorMsg = true;
      console.log('yes');
    }
    const FileSize = file.size / 1024 / 1024;

    if (FileSize > 0.5) {
      return this.service.openSnackBar(`File size exceeds 500KB`);
    }

    console.log(file);
    this.imagedata = file.name;
    const formData = new FormData();
    formData.append('file', file);
    console.log('form data', formData);
    this.service.upload(formData).subscribe(
      result => {
        this.menuCategory.patchValue({ image_id: result.body.image_id });
        this.service.getImage(result.body.image_id).subscribe((r: any) => {
          this.logo= r.body[0].image_data;
        });
        console.log("this is image",result);
      },
      error => error
    );
  }
  public onFileSelected1(event: EventEmitter<File[]>,i) {
    const file: File = event[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.showPdfErrorMsg = false;
      console.log('no');
    } else {
      this.showPdfErrorMsg = true;
      console.log('yes');
    }
    const FileSize = file.size / 1024 / 1024;

    if (FileSize > 0.5) {
      return this.service.openSnackBar(`File size exceeds 500KB`);
    }

    console.log(file);
    this.imagedata = file.name;
    const formData = new FormData();
    formData.append('file', file);
    console.log('form data', formData);
    this.service.upload(formData).subscribe(
      result => {
        this.menuCategoryEdit.patchValue({ image_id: result.body.image_id });
        this.service.getImage(result.body.image_id).subscribe((r: any) => {
          this.category[i].logo = r.body[0].image_data;
        });
      },
      error => error
    );
  }
}
