import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { MatDatepickerInputEvent } from '@angular/material';
import { DatePipe } from '@angular/common';
import { LocalStorageService } from 'ngx-store';
@Component({
  selector: 'app-inventory-reports',
  templateUrl: './inventory-reports.component.html',
  styleUrls: ['./inventory-reports.component.scss']
})
export class InventoryReportsComponent implements OnInit {
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  inventoryReports: FormGroup;
  completeInventory: any;
  showPagination: Boolean;
  p: any;
  totalAmountItem: number;
  showPrint1: Boolean;
  showPrint: Boolean;
  totalQuantityItem: number;
  maxDate = new Date();
  maxToDate = new Date();
  maxFromDate = new Date();
  minToDate: any;
  showReport: Boolean;
  totalIrvAmount: Number;
  brands: any;
  branches: any;
  irvReportData: any = [];
  selectInvData: any;
  showOrderDetails: boolean;
  brandName: any;
  systemDate: string;
  supplierName: any;
  noAvailableData: boolean;
  suppllier:any;
  fromdate: any;
  todate: any;
  constructor(
    private fb: FormBuilder,
    private adapter: DateAdapter<any>,
    public service: PosBackOfficeService,
    private datePipe: DatePipe,
    public ls: LocalStorageService
  ) {}

  ngOnInit() {
    this.inventoryReports = this.fb.group({
      fromDate: [new Date().toISOString().slice(0, 10)],
      toDate: [new Date().toISOString().slice(0, 10)],
      brand: '',
      branch: '',
      suppliers:''
    });
    this.dateAdapter();
    this.getBrand();
    this.systemDate = this.datePipe.transform(this.maxDate, 'yyyy-MM-dd HH:mm');
    this.dateValidation();
  }

  getBranch(brand, brandName) {
    this.brandName = brandName;
    this.service.Branches(`?brand_id=${brand}`).subscribe(r => {
      this.branches = r.body;
    });
  }

  getBrand() {
    this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;
      },
      error => {
        console.log('error', error);
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  dateValidation() {
    this.minToDate = this.inventoryReports.value.fromDate;
    this.maxFromDate = this.inventoryReports.value.toDate;
  }
  dateAdapter() {
    this.adapter.setLocale('en-GB');
  }

  getGrvReports() {
    if(this.inventoryReports.value.suppliers == "All"){
      this.suppllier = 0;
    }else{
      this.suppllier = 1;
    }
    this.service.getGrvReport(`?id=${this.inventoryReports.value.branch}&fromdate=${this.fromdate}&todate=${this.todate}&filterbysupplier=${this.suppllier}&supplier_id=${this.inventoryReports.value.suppliers}`).subscribe(
      res => {
        this.irvReportData = res.body;
        console.log('this.irvReportData', this.irvReportData);
        if (this.irvReportData.length > 0) {
          if (this.irvReportData.length > 9) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
          this.showReport = true;
          this.noAvailableData = true;
        } else {
          this.showPagination = false;
          this.showReport = false;
          this.noAvailableData = false;
        }
        this.completeInventory = this.irvReportData;
        this.calculateTotal(this.irvReportData);
        this.sortDate();
      },
      error => {
        console.log('error', error);
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  calculateTotal(data) {
    this.totalIrvAmount = 0;
    data.forEach(element => {
      this.totalIrvAmount += element.total_amount;
    });
  }
  selectData(data) {
    this.selectInvData = data;
    this.showOrderDetails = true;
    this.totalItems(data);
  }

  totalItems(data) {
    this.totalAmountItem = 0;
    this.totalQuantityItem = 0;
    data.stock_data.forEach(data => {
      this.totalAmountItem += parseFloat(data.total_amount);
      this.totalQuantityItem += parseFloat(data.quantity);
    });
  }

  print1() {
    setTimeout(() => {
      this.printReceipt1('inventory_list_print');
    }, 500);
    this.showPrint1 = true;
  }
  printReceipt1(div): void {
    let printContents, popupWin;
    printContents = document.getElementById(div).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Sales Report</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  print() {
    setTimeout(() => {
      this.printReceipt('inventory_print');
    }, 500);
    this.showPrint = true;
  }
  printReceipt(div): void {
    let printContents, popupWin;
    printContents = document.getElementById(div).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Sales Report</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  sortDate() {
    this.dateValidation();   

    this.fromdate =
      this.datePipe.transform(this.inventoryReports.value.fromDate, 'yyyy-MM-dd') ||
      this.datePipe.transform(this.inventoryReports.value.toDate, 'yyyy-MM-dd') ||
      '';
    this.todate =
      this.datePipe.transform(this.inventoryReports.value.toDate, 'yyyy-MM-dd') || this.fromdate || '';
    this.irvReportData = this.completeInventory.filter((item: any) => {
      this.calculateTotal(this.irvReportData);
      return (
        new Date(item.transaction_date).getTime() >= new Date(this.fromdate).getTime() &&
        new Date(item.transaction_date).getTime() <= new Date(this.todate).getTime()
      );
    });
    if (this.irvReportData.length == 0) {
      this.showPagination = false;
      this.showReport = false;
    } else {
      if (this.irvReportData.length > 9) {
        this.showPagination = true;
      }
      this.showReport = true;
    }
    this.calculateTotal(this.irvReportData);
  }

  applyFilter(filter: any) {
   // alert(JSON.stringify(this.inventoryReports.value.suppliers))
    this.irvReportData = this.completeInventory.filter(item => {
      if (item.supplier_id == filter) {
        return item;
      }
    });
    this.calculateTotal(this.irvReportData);

  }

  getSupplierNames(brandId) {
    this.service.getSupplierInventory(`?id=${brandId}`).subscribe(
      res => {
        this.supplierName = res.body;
      },
      error => {
        console.log(error.error.message);
        this.service.openSnackBar(error.error.message);
      }
    );
  }
}
