import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { AuthService } from './auth/auth.service';
import { hammerjs } from 'node_modules/hammerjs';
import { UserIdleService } from 'angular-user-idle';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { PosBackOfficeService } from '../app/providers/pos-back-office.service';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  hammerjs=hammerjs;
  title = "posbackoffice";
  router;
  idleState: string;
  timedOut: boolean;
  lastPing?: Date = null;
  constructor(public Router: Router, public auth: AuthService,
    private userIdle: UserIdleService,
    public route: Router,
    public idle: Idle,
    public keepalive: Keepalive,
    public service : PosBackOfficeService) {
    this.router = this.Router;

    idle.setIdle(600);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      if (this.route.url !== '/login') {
        this.service.openSnackBar('session expired!');
        this.route.navigate(['/login']);
      }
    });
    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe((countdown) => {
      if (this.route.url !== '/login') {
        this.service.openSnackBar('You will time out in ' + countdown + ' seconds!');
        this.idleState = 'You will time out in ' + countdown + ' seconds!';
      }
    });
    // sets the ping interval to 15 seconds
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
