import { LocalStorageService } from 'ngx-store';
import { Component, OnInit } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  allUsers = [];
  currentUser: String;
  role: FormGroup;
  roleEdit: FormGroup;
  showRole: Boolean = true;
  textAlign: string;
  constructor(
    public ls: LocalStorageService,
    public service: PosBackOfficeService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    const { name } = this.ls.get('bouser');
    this.currentUser = name;

    this.role = this.fb.group({
      email: ['', Validators.required],
      role: ['', Validators.required],
      domin: ['', Validators.required]
    });

    this.roleEdit = this.fb.group({
      email: ['', Validators.required],
      role: ['', Validators.required],
      domin: ['', Validators.required],
      _id: ['', Validators.required]
    });

    this.getUserDetails();
    this.inputAlignment();
  }

  inputAlignment() {   
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  deleteSameUser(name, index) {
    // console.log(name, index , this.currentUser,this.currentUser == name );
    // if (this.currentUser == name) {
    //   this.allUsers.splice(index, 1);
    // }
    // return true;
  }

  editRole(item) {
    console.log("data",item)
    const { email, role, domin, _id } = item;
    this.selectDomin(domin);
    this.roleEdit.patchValue({ email, role, domin, _id });
    this.popUp();
  }

  delete(id, index) {
    this.service.deleteUser(id).subscribe(
      result => {
        this.allUsers.splice(index, 1);
        this.service.openSnackBar('User Deleted');
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  getUserDetails() {
    this.service.getAllUser().subscribe(
      result => {
        this.allUsers = result;
        console.log("responsedata", this.allUsers)
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  selectDomin(value) {
    if (value === 'backOffice') {
      this.role.patchValue({ role: 'bo' });
      this.roleEdit.patchValue({ role: 'bo' });
      this.showRole = false;
    }
    if (value === 'pos') {
      console.log("2", value);
      this.roleEdit.patchValue({ role: null });
      this.showRole = true;
    }
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  update() {
    this.service.updateUser(this.roleEdit.value).subscribe(
      result => {
        this.popUpOff();
        this.getUserDetails();
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }
}
