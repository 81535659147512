import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { PosBackOfficeService } from "../providers/pos-back-office.service";
import { DatePipe } from "@angular/common";
// import { Label } from 'ng2-charts';

@Component({
  selector: 'app-hours-report',
  templateUrl: './hours-report.component.html',
  styleUrls: ['./hours-report.component.scss']
})
export class HoursReportComponent implements OnInit {

  public barChartType: string = 'bar';
  public barChartLabels: any[] = [];
  // public barChartLabels: any[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];

  public barChartLegend: boolean = true;

  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Series A' },
    { data: [], label: 'Series B' }
  ];
  public barOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            stepSize: 1,
          },
        },
      ],
    }
  };
  brands: any;
  allBranch: any;
  onlineresorce: any[];
  order_fromDate: any = new Date().toISOString();
  order_toDate: any = new Date().toISOString();
  chartData = [];
  chartDataOne = [];
  dates: any;
  chartLabels = [];
  area: any;
  brandId: any;
  branchId: any;
  onlineType: any;
  segment: any;
  areaId: boolean;
  // public barChartOptions: ChartOptions = {
  //   responsive: true,
  // };

  // public barChartLegend = true;
  // public barChartPlugins = [];
  constructor(public service: PosBackOfficeService, public datePipe: DatePipe) { }

  ngOnInit() {
    this.getBrand();
    this.onlineSourceApi();
    this.houryReport('fromDate', this.order_fromDate);
    this.areaName()
  }
  areaName(){
    this.service.getAreaData('?area_id=all').subscribe(res=>{
      console.log("res for area",res)
      this.area = res.body
    })
  }
  getBrand() {
    this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;
      },
      error => {
        console.log('error', error);
      }
    );
  }
  getBranch(brandId) {
    console.log("brandId", brandId);
    this.brandId = brandId
    this.service.Branches(`?brand_id=${brandId}`).subscribe(
      (data) => {
        if (data.status) {
          this.allBranch = data.body;
          console.log("thia.allBrabch", this.allBranch)
        }
      },
      (error) => {
        console.log("error in getBranchByBrandId", error);
      }
    );
    this.houryReport('brand', brandId)
  }
  callcenterBranch(key, type) {
    console.log("key",key, "type",type)
    this.branchId = key
    this.houryReport(type, key)
  }
  ccsegment(key, type) {
    this.segment = type
    this.houryReport(type, key)
  }
  onlineSourceApi() {
    let abc = [];
    this.service.onlineorder().subscribe(res => {
      console.log(res.body.onlinetypes)
      res.body.onlinetypes.forEach(ele => {
        console.log(ele);
        if (ele.online_status == true) {
          abc.push(ele)
        }
      })
      this.onlineresorce = abc;
    });
  }
  fromDate(event) {
    console.log("from event", event.value)
    this.order_fromDate = new Date(event.target.value).toISOString();
    // this.ordersearchGet();
    this.houryReport('fromdate', this.order_fromDate)
  }
  toDate(event) {
    console.log("to event", event.value)
    this.order_toDate = new Date(event.target.value).toISOString();
    // this.ordersearchGet();
    this.houryReport('todate', this.order_toDate)
  }
  selectedOnlineType(key, value) {
    this.onlineType = value
    this.houryReport(key, value)
  }
  selectedArea(key, value){
    console.log("area key",key,"area value",value)
    this.areaId = value
    this.houryReport(key, value)
  }
  houryReport(key, value) {
    console.log("value", value)
    let brandid: any = '', brandNum = 0, branch: any = '', branchNum = 0, segment = 0, segmentName: any = '',
      onlineType = 0, onlineTypeName: any = '',areaType = 0, areaTypeName:any = '';

    if (this.order_fromDate) {

    }
    if (key == 'brand' || this.brandId) {
      brandNum = 1
      brandid = this.brandId
    }
    if (key == 'branch' || this.branchId) {
      branchNum = 1
      branch = this.branchId
    }
    if (key == 'segment' || this.segment) {
      segment = 1
      segmentName = this.segment
      // if (value == '1') {
      //   segmentName = 'All'
      // } else if (value == '2') {
      //   segmentName = 'pick_up'
      // } else if (value == '3') {
      //   segmentName = 'delivery'
      // }
    }
    if (key == 'onlineType' || this.onlineType) {
      onlineType = 1
      onlineTypeName = this.onlineType
    }
    if(key == 'area' || this.areaId){
      areaType = 1
      areaTypeName = this.areaId
    }
    
    const querry = `?fromdate=${this.order_fromDate}&todate=${this.order_toDate}&filterbyBrand=${brandNum}&brandId=${brandid}&filterbyBranch=${branchNum}&branchId=${branch}&filterbySegment=${segment}&segment=${segmentName}&filterbyOnlineType=${onlineType}&onlineType=${onlineTypeName}&filterbyArea=${areaType}&areaid=${areaTypeName}`
    console.log({ querry })
    // let chartData = [];
    this.chartLabels = [];
    this.chartData = [];
    this.barChartLabels = [];
    this.chartDataOne = [];
    this.service.getHourlyReport(querry).subscribe(res => {
      console.log("res", res);
      res.body.forEach(ele => {
        console.log("ele", ele)
        let abc = ele.netSales;
        let totaSales = ele.totalSales;
        let hr = ele.hour
        console.log("hr",hr)
        this.dates = this.datePipe.transform(hr, "hh:mm");
        this.chartLabels.push(this.dates);
        // this.barChartLabels = ['2006', '2007', '2008', '2009', '2010', '2011', '2012']
        this.barChartLabels = this.chartLabels;
        console.log("1",this.barChartLabels);
        console.log("length",this.barChartLabels.length)
        this.chartData.push(abc);
        this.chartDataOne.push(totaSales)
        this.barChartData[0].data = this.chartData;
        this.barChartData[1].data = this.chartDataOne;
        this.barChartData = [
          {
            data: this.chartData,
            label: 'netSales'
          },
          {
            data: this.chartDataOne,
            label: 'totalSales'
          }
        ]
      })
    })
  }
}
