import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { LocalStorageService } from 'ngx-store';
import { PosBackOfficeService } from "../providers/pos-back-office.service";
@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit {
  paymentTypeAdding: FormGroup;
  paymentNames: any;
  brand: any;
  ShowEdit: any;
  paymentTypeAddingEdit: FormGroup;

  constructor(public ls: LocalStorageService, public fb: FormBuilder, public service: PosBackOfficeService) { }

  ngOnInit() {
    this.paymentTypeAdding = this.fb.group({
      payment_type_english: ['', Validators.required],
      payment_type_arabic: ['', Validators.required],
      brand: ['', Validators.required]
    });
    this.paymentTypeAddingEdit = this.fb.group({
      payment_type_english: ['', Validators.required],
      payment_type_arabic: ['', Validators.required],
      brand: ['', Validators.required],
      payment_id: [''],
    });
    this.addedPaymentNames();
    this.brandData();
  }
  brandData() {
    this.service.getBrandDetails().subscribe(result => {
      this.brand = result.body;
    });
  }

  addedPaymentNames() {
    this.service.getPaymentData().subscribe(res => {
      console.log("res", res)
      this.paymentNames = res.body;
    })
  }

  createPayment() {
    const payload = {
      payment_type_english: this.paymentTypeAdding.value.payment_type_english,
      payment_type_arabic: this.paymentTypeAdding.value.payment_type_arabic,
      brand_id: this.paymentTypeAdding.value.brand
    }
    console.log("clicked");
    if (this.paymentTypeAdding.valid) {
      this.service.cardTypesaddPayment(payload).subscribe(res => {
        console.log("res", res);
        if (res.status) {
          this.service.openSnackBar("Payment Type Added Successfully");
          this.addedPaymentNames();
          this.paymentTypeAdding.reset();
        }
      })
    }else{
      this.service.openSnackBar("Please Fill All the Fields")
    }
  }

  editData(data) {
    // this.paymentTypeAddingEdit.patchValue({ brand:''})
    this.ShowEdit = data.payment_id;
    this.paymentTypeAddingEdit.patchValue({
      payment_id: data.payment_id,
      payment_type_english: data.payment_type_english,
      payment_type_arabic: data.payment_type_arabic,
      brand: data.brand.brand_id
    })

  }

  updatePayment() {
    const payload = {
      payment_id: this.paymentTypeAddingEdit.value.payment_id,
      payment_type_english: this.paymentTypeAddingEdit.value.payment_type_english,
      payment_type_arabic: this.paymentTypeAddingEdit.value.payment_type_arabic,
      brand_id: this.paymentTypeAddingEdit.value.brand
    }
    console.log("payload", payload)
    if (this.paymentTypeAddingEdit.valid) {
      this.service.updatePaymentData(payload).subscribe(res => {
        console.log("res",)
        if (res.status) {
          this.service.openSnackBar("Updated Successfully");
          this.addedPaymentNames();
          this.ShowEdit = ''
        }
      })
    } else {
      this.service.openSnackBar("Please fill all the form")
    }
  }
  deletePayment(data) {
    const id = `?id=${data.payment_id}`
    this.service.deletePayment(id).subscribe(res => {
      this.service.openSnackBar("Payment Deleted Successfully");
      this.addedPaymentNames();
    })
  }

}
