import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import { PosBackOfficeService } from "../providers/pos-back-office.service";
import { LocalStorageService, CookiesStorageService } from "ngx-store";
@Component({
  selector: "app-area-setup",
  templateUrl: "./area-setup.component.html",
  styleUrls: ["./area-setup.component.scss"],
})


export class AreaSetupComponent implements OnInit {
  areaSetUp: FormGroup;
  areaSetUpModal: FormGroup;
  blockSetUp: FormGroup;
  blockSetUpModal: FormGroup;
  area: FormGroup;
  formAttemptSubmit: boolean;
  areaData: any = [];
  blockdata: any;
  isareaSelected: boolean;
  areaId: any;
  blockId: any;
  blocksToDisplay: any = [];
  areaListId: any;
  brand: any = [];
  branch: any = [];
  areaSelected: any;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) {}

  ngOnInit() {
    this.areaSetUp = this.fb.group({
      // brand: ["", Validators.required],
      // branch: ["", Validators.required],
      area: ["", Validators.required],
      area_arabic: ["", Validators.required],
    });
    this.areaSetUpModal = this.fb.group({
      area_name_english: ["", Validators.required],
      area_name_arabic: ["", Validators.required],
      area_id: "",
    });
    this.blockSetUp = this.fb.group({
      block_setup_array: this.fb.array([]),
    });
    this.blockSetUpModal = this.fb.group({
      area_id: [""],
      block: ["", Validators.required],
      block_id: "",
    });
    this.area = this.fb.group({
      areaList: "",
    });
    this.isareaSelected = false;
    // this.brandData();
    this.getAreasData();
  }
  patchman1() {
    const one = this.areaSetUp.get("area").value;
    this.areaSetUp.patchValue({ area: one.replace(/^ +/gm, "") });
  }
  addBlockData() {
    (<FormArray>this.blockSetUp.get("block_setup_array")).push(
      this.blockList()
    );
  }
  blockList() {
    return this.fb.group({
      area_id: this.area.value.areaList,
      block: ["", Validators.required],
    });
  }
  isFieldValid(field: string) {
    return (
      (this.areaSetUp.get(field).invalid &&
        this.areaSetUp.get(field).touched) ||
      (this.areaSetUp.get(field).untouched && this.formAttemptSubmit)
    );
  }

  getAreasData() {
    let query = `?area_id=${"all"}`;
    this.service.getAreaData(query).subscribe(async (res) => {
      this.areaData = res.body;

      if (this.isareaSelected) {
        console.log(this.areaSelected);
        const areaObj = await this.search(
          this.areaSelected.area_id,
          this.areaData
        );
        this.blocksToDisplay = areaObj.blocks;
      }
    });
  }

  isFieldValid1(field: string) {
    return (
      (this.blockSetUp.get(field).invalid &&
        this.blockSetUp.get(field).touched) ||
      (this.blockSetUp.get(field).untouched && this.formAttemptSubmit)
    );
  }

  search(area_id, areaArray) {
    for (var i = 0; i < areaArray.length; i++) {
      if (areaArray[i].area_id === area_id) {
        return areaArray[i];
      }
    }
  }

  brandData() {
    this.service.getBrandDetails().subscribe((result) => {
      this.brand = result.body.sort(function (a, b) {
        return a.brand_id - b.brand_id;
      });
    });
  }

  getBranch(brand) {
    // this.selectedBrand=brand
    this.service.Branches(`?brand_id=${brand.brand_id}`).subscribe((r) => {
      this.branch = r.body;
      console.log("b", this.branch);
    });
  }

  addArea() {
    const body = {
      // brand_id: this.areaSetUp.value.brand,
      // branch_id: this.areaSetUp.value.branch,
      area_name_english: this.areaSetUp.value.area,
      area_name_arabic: this.areaSetUp.value.area_arabic,
    };
    this.service.addArea(body).subscribe(
      (res) => {
        if (res.status) {
          // this.getAreasData("all");
          this.areaSetUp.controls.area_arabic.reset();
          this.areaSetUp.controls.area.reset();
          this.getAreasData();
        }
      },
      (err) => {
        console.log(err);
        if (err.error.body == "Validation error") {
          this.service.openSnackBar("Area is already added");
        }
      }
    );
  }

  addMoreBlocks() {
    this.addBlockData();
  }
  deleteAreaList(data) {
    this.areaListId = data;
    this.popUp();
  }
  deletearea() {
    console.log("deleteareadeletearea", this.areaListId);
    this.service.deletearea(this.areaListId).subscribe((res) => {
      if (res.status) {
        this.getAreasData();
        this.slectedarea();
        this.popUpOff();
      }
    });
  }

  deleteBlock(block) {
    console.log("block_id deleteBlock", block.block_id);
    this.service.deletBlock(block.block_id).subscribe((res) => {
      if (res.status) {
        this.getAreasData();
        const index = this.blocksToDisplay.indexOf(block);
        if (index > -1) {
          this.blocksToDisplay.splice(index, 1);
        }
      }
    });
  }

  slectedarea() {
    (<FormArray>this.blockSetUp.get("block_setup_array")).controls = [];
    this.addMoreBlocks();
    const query = `?area_id=${this.area.value.areaList}`;
    this.service.getAreaData(query).subscribe((res) => {
      if (res.body.length > 0) {
        if (res.body[0].blocks.length > 0) {
          let block = {
            area_name: res.body[0].area_name_english,
            block: res.body[0].blocks,
          };
          this.blockdata = block;
          this.isareaSelected = true;
        } else {
          // this.isareaSelected = false;
          this.service.openSnackBar(
            "there is no block for this area, please add or switch to other area"
          );
        }
      } else {
        // this.isareaSelected = false;
      }
    });
  }
  addBlock() {
    // let body= [{
    //   "area_id":this.blockSetUp.value.area_list,
    //   "block":this.blockSetUp.value.block_name
    // ,
    // }]
    const data = this.blockSetUp.value;
    data.block_setup_array.map((block: any) => {
      // block["brand_id"] = this.areaSetUp.value.brand;
      // block["branch_id"] = this.areaSetUp.value.branch;
    });
    console.log("data", data);
    this.service.addBlock(data.block_setup_array).subscribe((res) => {
      if (res.status) {
        this.getAreasData();
        this.slectedarea();
        (<FormArray>this.blockSetUp.get("block_setup_array")).controls = [];
        this.addMoreBlocks();
      }
    });
  }

  getBlocks = (data) => {
    this.isareaSelected = true;
    console.log(data);
    this.areaSelected = data;
    this.blocksToDisplay = data.blocks;
  };

  updateAreaSetup() {
    this.service.updateArea(this.areaSetUpModal.value).subscribe(
      (res) => {
        this.getAreasData();
      },
      (error) => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  editData(data) {
    console.log(data);
    this.areaId = data.area_id;
    console.log("ddd", this.areaId);
    console.log("222", data.area_id);
    this.areaSetUpModal.patchValue(data);
    console.log("areid",this.areaSetUpModal)
  }
  editBlockDetails(blockData) { 
    this.blockId = blockData.block_id;
    this.blockSetUpModal.patchValue({
      area_id: this.areaSelected.area_id, 
      block: blockData.block,
      block_id: blockData.block_id,
    });
  }
  updateBlockSetup() {
    this.service.updateBlock(this.blockSetUpModal.value).subscribe((res) => {
      this.getAreasData();
      this.blockId = "";
    });
  }
  popUp() {
    document.getElementById("id01").style.display = "block";
  }

  popUpOff() {
    document.getElementById("id01").style.display = "none";
  }
  removeBlock(i) {
    (<FormArray>this.blockSetUp.get("block_setup_array")).removeAt(i);
  }
}
