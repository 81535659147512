import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { Routes, Router, RouterModule } from '@angular/router';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { error } from '@angular/compiler/src/util';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';


@Component({
  selector: 'app-main-registration',
  templateUrl: './main-registration.component.html',
  styleUrls: ['./main-registration.component.scss']
})
export class MainRegistrationComponent implements OnInit {
  hide = true;
  hide1 = true;
  mainRegistration: FormGroup;
  showRole: Boolean = true;
  formSubmitAttempt: boolean;
  textAlign: string;
  constructor(private fb: FormBuilder, public service: PosBackOfficeService, public ls: LocalStorageService,
    private router: Router) {}

  ngOnInit() {
    this.mainRegistration = this.fb.group({
      name: ['', Validators.required],
      nameArabic: ["", [Validators.required]],
      email: [
        '',
        [
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9w.#&()_*-]+@[a-zA-Z0-9]+(.)?[a-zA-Z]{2,4}$')
        ]
      ],
      userName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]{0,50}$')]],
      password: ['', Validators.required],
      secretKey: ['', Validators.required]
    });
    this.inputAlignment();
  }

    inputAlignment() {
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  selectDomin(value) {
    if (value === 'backOffice') {
      this.mainRegistration.patchValue({ role: 'bo' });
      this.showRole = false;
    }
    if (value === 'pos') {
      this.showRole = true;
    }
  }

  isFieldValid(field: string) {
    return (
      (!this.mainRegistration.get(field).valid && this.mainRegistration.get(field).touched) ||
      (this.mainRegistration.get(field).untouched && this.formSubmitAttempt)
    );
  }

  submit() {
    this.mainRegistration.value.role = `bo`;
    this.mainRegistration.value.domin = `backOffice`;

    this.service.adminRegister(this.mainRegistration.value).subscribe(
      result => {
        this.service.openSnackBar('Admin Registered');
        this.mainRegistration.reset();
        this.showRole = true;
        this.router.navigate(['/login']);
      },
      error => {
        this.service.openSnackBar('Unauthorized');
      }
    );
    this.formSubmitAttempt = true;
  }
}
