import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {
  brands: any;
  warehouse: FormGroup;
  branches: any;
  warehouseList: any;
  edit;
  warehouseEdit: FormGroup;
  warehouseid: any;
  constructor(private fb: FormBuilder, public service: PosBackOfficeService,
    public ls: LocalStorageService) { }

  ngOnInit() {
    this.warehouse = this.fb.group({
      brand: ['', Validators.required],
      // branch: ['', Validators.required],
      warehouseName: ['', Validators.required],
      warehouseArabicName: ['', Validators.required]
    });
    this.warehouseEdit = this.fb.group({
      brand: ['', Validators.required],
      // branch: ['', Validators.required],
      warehouseName: ['', Validators.required],
      warehouseArabicName: ['', Validators.required]
    })
    this.getBrand();
    this.displayWarehouse();
  }

  getBrand() {
    this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;
      },
      error => {
        console.log('error', error);
      }
    );
  }
  getBranch(brand) {
    this.service.Branches(`?brand_id=${brand}`).subscribe((r) => {
      this.branches = r.body;
      console.log(this.branches)
    });
  }

  addWarehouse() {
    const payload = {
      brand_id: this.warehouse.value.brand,
      warehouse_name: this.warehouse.value.warehouseName,
      warehouse_name_arabic: this.warehouse.value.warehouseArabicName
    }
    console.log("payload", payload)
    let branchTest;
    // this.warehouseList.forEach(element => {
    //   if (element.branch.branch_id == this.warehouse.value.branch) {
    //     console.log("123")
    //     branchTest = true
    //   } else {
    //     console.log("456")
    //     branchTest = false
    //   }
    // })
    if (this.warehouse.valid) {
      if (branchTest == true) {
        this.service.openSnackBar("Branch Name already Assigned")
      } else {
        this.service.postwarehouse(payload).subscribe(res => {
          console.log("warehouse resp", res)
          if (res.status) {
            this.warehouse.reset();
            this.displayWarehouse();
            this.service.openSnackBar("Warehouse Added Successfully")
          }
        })
      }
    } else {
      this.service.openSnackBar("Please Fill all the Fields")
    }
  }

  displayWarehouse() {
    this.service.getwarehouse().subscribe(res => {
      console.log("res", res)
      this.warehouseList = res.body.rows;
    })
  }
  editWarehouse(data, i) {
    // this.getBranch(data.brand.brand_id)
    console.log(data, i);
    this.warehouseid = data.warehouse_id
    this.edit = i;
    this.warehouseEdit.patchValue({
      warehouseName: data.warehouse_name,
      warehouseArabicName: data.warehouse_name_arabic
    })
  }
  update() {
    console.log('123')
    const payload = {
      warehouse_name: this.warehouseEdit.value.warehouseName,
      warehouse_name_arabic: this.warehouseEdit.value.warehouseArabicName,
      warehouse_id: this.warehouseid
    }
    console.log(payload)
    this.service.putwarehouse(payload).subscribe(res => {
      console.log(res)
      if (res.status) {
        this.edit = null;
        this.displayWarehouse();
        this.service.openSnackBar("Updated Sucessfully")
      }
    })
  }

}
