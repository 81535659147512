import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
@Component({
  selector: 'app-table-setup',
  templateUrl: './table-setup.component.html',
  styleUrls: ['./table-setup.component.scss']
})
export class TableSetupComponent implements OnInit {
  tableSetUp: FormGroup;
  brands = [];
  branches = [];
  tableDataValue: Boolean;
  formAttemptSubmit: boolean;
  showTableNames: boolean;
  tableNames = [];
  showTableNamesBtn: boolean;
  duplicateData: Boolean;
  table_array = [];
  duplicateArray: any;
  branchId: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) {}

  ngOnInit() {
    this.tableSetUp = this.fb.group({
      brand_id: ['', Validators.required],
      branch_id: ['', Validators.required],
      table_no: ['', [Validators.required, Validators.min(1)]],
      table_data: this.fb.array([])
    });
    this.getBrand();
  }

  addTableNumbers() {
    (<FormArray>this.tableSetUp.get('table_data')).push(this.tableDataControls());
  }
  tableDataControls() {
    return this.fb.group({
      table_Name: ['', Validators.required],
      table_Status: false
    });
  }
  mapTableDataControls(data) {
    const control = new FormGroup({
      table_Name: new FormControl(data.table_Name),
      table_Status: new FormControl(data.table_Status)
    });
    (<FormArray>this.tableSetUp.get('table_data')).push(control);
  }
  getBrand() {
    this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;
      },
      error => {
        console.log('error', error);
      }
    );
  }
  getBranch(brand) {
    this.service.Branches(`?brand_id=${brand}`).subscribe(r => {
      this.branches = r.body;
      this.showTableNames = false;
    });
  }
  getTableData(branch_id) {
    this.branchId = branch_id;
    const control = <FormArray>this.tableSetUp.get(['table_data']);
    control.removeAt(0);
    control.controls = [];
    this.showTableNames = false;
    this.service.getTableNames(branch_id).subscribe((res: any) => {
      if (res.body.length > 0) {
        this.tableNames = res.body[0].table_data;
        this.tableSetUp.patchValue({
          table_no: res.body[0].table_no
        });
        this.showTableNames = true;
        this.showTableNamesBtn = true;
        this.tableNames.forEach(element => {
          this.mapTableDataControls(element);
        });
      } else {
        this.showTableNames = false;
      }
    });
  }

  submitData() {
    if ((<FormArray>this.tableSetUp.get('table_data')).valid) {
      this.duplicateArray = <FormArray>this.tableSetUp.get('table_data').value;
      const valueArr = this.duplicateArray.map(function(item) {
        return item.table_Name;
      });
      const isDuplicate = valueArr.some(function(item, idx) {
        return valueArr.indexOf(item) !== idx;
      });
      if (isDuplicate) {
        this.service.openSnackBar('Duplicate table data');
      } else {
        this.tableSetUp.patchValue({ table_no: this.tableSetUp.value.table_data.length });
        this.service.saveTableNames(this.tableSetUp.value).subscribe(
          res => {
            this.getTableData(res.body.branch_id);
            this.service.openSnackBar('Table configuration done');
          },
          error => {
            console.log('error', error);
          }
        );
      }
    } else {
      this.service.openSnackBar('Table number required');
    }
  }

  updateData() {
    this.tableDataValue = false;
    this.tableSetUp.value.table_data.forEach(element => {
      if (element.table_Name == '') {
        this.tableDataValue = true;
      }
    });

    if (this.tableDataValue === true) {
      this.service.openSnackBar('Table number cannot be blank');
      return false;
    }

    console.log('table data', <FormArray>this.tableSetUp.get('table_data').value);
    if ((<FormArray>this.tableSetUp.get('table_data')).valid) {
      this.duplicateArray = <FormArray>this.tableSetUp.get('table_data').value;
      const valueArr = this.duplicateArray.map(function(item) {
        return item.table_Name;
      });
      const isDuplicate = valueArr.some(function(item, idx) {
        return valueArr.indexOf(item) !== idx;
      });
      if (isDuplicate) {
        this.service.openSnackBar('Duplicate table data');
      } else {
        this.tableSetUp.patchValue({ table_no: this.tableSetUp.value.table_data.length });
        this.service.updateTableNames(this.tableSetUp.value).subscribe(
          res => {
            this.service.openSnackBar('Table data updated');
          },
          error => {
            this.service.openSnackBar(error.error.message);
            console.log('error', error);
          }
        );
      }
    } else {
      this.service.openSnackBar('Table number required');
    }
  }

  addTables() {
    const control = <FormArray>this.tableSetUp.get(['table_data']);
    control.removeAt(0);
    control.controls = [];
    const tableCount = parseInt(this.tableSetUp.value.table_no);
    if (this.tableSetUp.valid) {
      for (let i = 0; i < tableCount; i++) {
        this.addTableNumbers();
      }
      this.showTableNames = true;
      this.showTableNamesBtn = false;
    } else {
      this.service.openSnackBar('Input fields cannot be blank');
    }
  }

  updateTables() {
    this.table_array = [];
    const body = {
      table_Name: '',
      table_Status: ''
    };
    const tableCount = parseInt(this.tableSetUp.value.table_no);
    for (var i = 0; i < tableCount; i++) {
      this.table_array.push(body);
    }
    this.table_array.forEach(element => {
      this.mapTableDataControls(element);
    });
    this.tableSetUp.patchValue({ table_no: ' ' });
  }

  isFieldValid(field: string) {
    return (
      (this.tableSetUp.get(field).invalid && this.tableSetUp.get(field).touched) ||
      (this.tableSetUp.get(field).untouched && this.formAttemptSubmit)
    );
  }
  removeTable(i) {
    console.log('index', i);
    (<FormArray>this.tableSetUp.get('table_data')).removeAt(i);
  }
  clearTableData() {
    (<FormArray>this.tableSetUp.get('table_data')).controls = [];
    this.tableSetUp.value.table_data.forEach(element => {
      this.clearAll();
    });
  }
  clearAll() {
    const control = new FormGroup({
      table_Name: new FormControl('')
      // table_Status: new FormControl()
    });
    (<FormArray>this.tableSetUp.get('table_data')).push(control);
  }
  popUp() {
    document.getElementById('id01').style.display = 'block';
  }
  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }
  deleteBrandModal() {
    // (<FormArray>this.tableSetUp.get('table_data')).controls = [];
    this.service.deleteTableNames(this.branchId).subscribe(
      (res: any) => {
        this.service.openSnackBar('Deleted Table Data!');
        this.getTableData(this.branchId);
        this.popUpOff();
      },
      error => {
        console.log('error', error);
      }
    );
  }
}
