import { BrowserModule } from '@angular/platform-browser';
import { NgModule,enableProdMode } from '@angular/core';
import { WebStorageModule } from 'ngx-store';
import { PosBackOfficeService } from '../app/providers/pos-back-office.service';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe } from '@angular/common';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { TokenInterceptorService } from '../app/providers/token-interceptor.service';
import { UserIdleModule } from 'angular-user-idle';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { Idle } from '@ng-idle/core';
// import {enableProdMode} from '@angular/core';
// import 'polyfills.ts';
import {
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule
} from '@angular/material';
import { ChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeftNavbarComponent } from './left-navbar/left-navbar.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuGroupComponent } from './menu-group/menu-group.component';
import { MenuMasterComponent } from './menu-master/menu-master.component';
import { BrandComponent } from './brand/brand.component';
import { BranchComponent } from './branch/branch.component';
import { LoginComponent } from './login/login.component';
import { MainRegistrationComponent } from './main-registration/main-registration.component';
import { BranchSetupComponent } from './branch-setup/branch-setup.component';
import { RegistrationComponent } from './registration/registration.component';
import { RolesComponent } from './roles/roles.component';
import { MenuCategoryComponent } from './menu-category/menu-category.component';
import { MenuItemCategoryComponent } from './menu-item-category/menu-item-category.component';
import { DiscountComponent } from './discount/discount.component';
import { PaymentTypeComponent } from './payment-type/payment-type.component';
import { ModifierCatalogComponent } from './modifier-catalog/modifier-catalog.component';
import { ModifierGroupComponent } from './modifier-group/modifier-group.component';
import { FileUploadModule } from 'ng2-file-upload';
import { PrinterComponent } from './printer/printer.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SalesReportComponent } from './sales-report/sales-report.component';
import { PaymentReportsComponent } from './payment-reports/payment-reports.component';
import { ProductMixReportComponent } from './product-mix-report/product-mix-report.component';
import { DiscountReportComponent } from './discount-report/discount-report.component';
import { TableSetupComponent } from './table-setup/table-setup.component';
import { DeliverySetupComponent } from './delivery-setup/delivery-setup.component';
import { AreaSetupComponent } from './area-setup/area-setup.component';
import { OnlineSetupComponent } from './online-setup/online-setup.component';
import { SupplierComponent } from './supplier/supplier.component';
import { ItemComponent } from './item/item.component';
import { InventoryCategoryComponent } from './inventory-category/inventory-category.component';
import { InventoryReportsComponent } from './inventory-reports/inventory-reports.component';
import { SalesSummaryComponent } from './sales-summary/sales-summary.component';
import { SalesSearchComponent } from './sales-search/sales-search.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import {WebComponentModule} from './web-components/web-components.module';
import { OrdersComponent } from './orders/orders.component';
import { RecipemanagementComponent } from './recipemanagement/recipemanagement.component';
import { FoodcostComponent } from './foodcost/foodcost.component';
import { SemifinishComponent } from './semifinish/semifinish.component';
import { ComplaintsComponent } from './complaints/complaints.component';
import { TransactionDateComponent } from './transaction-date/transaction-date.component';
import { OrderSearchComponent } from './order-search/order-search.component';
import { AgentPerformanceComponent } from './agent-performance/agent-performance.component';
import { HoursReportComponent } from './hours-report/hours-report.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { CustomerReportComponent } from './customer-report/customer-report.component';
import { AddPaymentComponent } from './add-payment/add-payment.component';
import { DailySalesReportComponent } from './daily-sales-report/daily-sales-report.component';
import { GrvByItemComponent } from './grv-by-item/grv-by-item.component';
import { WastageSummaryComponent } from './wastage-summary/wastage-summary.component';
import { StockReportComponent } from './stock-report/stock-report.component';
enableProdMode();
@NgModule({
  declarations: [
    AppComponent,
    LeftNavbarComponent,
    MenuItemComponent,
    MenuGroupComponent,
    MenuMasterComponent,
    BrandComponent,
    BranchComponent,
    LoginComponent,
    MainRegistrationComponent,
    BranchSetupComponent,
    RegistrationComponent,
    RolesComponent,
    MenuCategoryComponent,
    MenuItemCategoryComponent,
    DiscountComponent,
    PaymentTypeComponent,
    ModifierCatalogComponent,
    ModifierGroupComponent,
    PrinterComponent,
    SalesReportComponent,
    PaymentReportsComponent,
    ProductMixReportComponent,
    DiscountReportComponent,
    TableSetupComponent,
    DeliverySetupComponent,
    AreaSetupComponent,
    OnlineSetupComponent,
    SupplierComponent,
    ItemComponent,
    InventoryCategoryComponent,
    InventoryReportsComponent,
    SalesSummaryComponent,
    SalesSearchComponent,
    SalesDashboardComponent,
    OrdersComponent,
    RecipemanagementComponent,
    FoodcostComponent,
    SemifinishComponent,
    ComplaintsComponent,
    TransactionDateComponent,
    OrderSearchComponent,
    AgentPerformanceComponent,
    HoursReportComponent,
    WarehouseComponent,
    CustomerReportComponent,
    AddPaymentComponent,
    DailySalesReportComponent,
    GrvByItemComponent,
    WastageSummaryComponent,
    StockReportComponent
  ],
  imports: [
    UserIdleModule.forRoot({idle: 3, timeout: 3, ping: 1}),
    // Idle, DEFAULT_INTERRUPTSOURCES,
    // Keepalive,
    NgIdleKeepaliveModule.forRoot(),
    FormsModule,
    NgxPaginationModule,
    WebStorageModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    FileUploadModule,
    Ng2OrderModule,
    ChartsModule,
    WebComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    PosBackOfficeService,
    DatePipe,
    Idle
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
