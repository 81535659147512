import { Component, OnInit } from '@angular/core';
import { PosBackOfficeService } from '../../app/providers/pos-back-office.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray,
  FormArrayName
} from '@angular/forms';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
import { elementAttribute } from '@angular/core/src/render3';

@Component({
  selector: 'app-menu-group',
  templateUrl: './menu-group.component.html',
  styleUrls: ['./menu-group.component.scss']
})
export class MenuGroupComponent implements OnInit {
  showItemDetails: boolean;
  groupName: any;
  brandName: any;
  i: Number;
  p: Number = 1;
  menuItemGroup1: any;
  group: any;
  textAlign: string;
  textRight: string;
  showAvailable: Boolean = true;
  itemDataBackup: any;
  showAdd: Boolean = true;
  showPagination: boolean;
  menuGroupData: any;
  menugrp: any;
  filteredData: any[];
  updateMenu: any;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) { }
  menuItemGroup: FormGroup;
  menuGroupModal: FormGroup;
  showItem: Boolean;
  rule: any;
  payload: any;
  query: any;
  index: any;
  brand: any;
  formSubmitAttempt: Boolean;
  selectedMenu: any = [];
  menuItemGroupInfo: any = [];
  AddItemsMenu = [];
  LocalItems = [];
  itemData: any = [];
  groupID: '';
  buttonClick: Boolean = false;
  showAddGroup: Boolean = false;
  menuName: boolean = false;

  localIndex;
  async ngOnInit() {
    this.menuItemGroup = this.fb.group({
      group_name: this.fb.array[''],
      group_name_arabic: this.fb.array[''],
      brand_id: ['', Validators.required]
    });
    this.menuGroupModal = this.fb.group({
      brand: [''],
      group_name: ['', Validators.required],
      group_name_arabic: ['', Validators.required],
      group_id: ['']
    });
    this.brandData();
    this.inputAlignment();
  }
  inputAlignment() {
    this.textRight = 'textRight';
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  item(rule, payload, query) {
    this.service.Item(rule, payload, query).subscribe(
      result => {
        this.itemData = result.body;
        this.itemDataBackup = result.body;
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  viewItemDetails(item, index) {
    this.brandName = item.brand.brand_name;
    this.menuItemGroup1 = item.group_name;
    this.groupName = item.group_name;
    this.localIndex = index;
    this.unselectAll();
    this.showItem = true;
    this.showItemDetails = true;
  }

  viewItemDetails1(index, item) {
    this.MenuItemGroupCRUD('get', this.menuItemGroup.value.brand_id, '?brand_id=');
    this.viewItemDetails(item, index);
    this.groupName = item.group_name;
    this.localIndex = index;
    this.unselectAll();
    this.showItemDetails = true;
  }

  activateAdd() {
    this.buttonClick = true;
    this.showAdd = true;
    // this.menuName = true;
    this.showAddGroup = false;
    this.showItem = false;
    this.showItemDetails = false;
  }

  clear() {
    this.menuItemGroup.patchValue({ group_name: null, group_name_arabic: null });
  }

  createMenuGroup() {
    this.showAddGroup = true;
    this.showAdd = false;
  }

  checkBoxData(event, item) {
    // Adds items if checked
    if (event.checked) {
      // Returns undefined on not found ;
      const result = this.menuItemGroupInfo[this.localIndex].items.find(
        data => data.item_id === item.item_id
      );

      if (!result) {
        return this.LocalItems.unshift(item);
      }
      event.source._checked = false;
      return this.service.openSnackBar('Item already added');
    }

    // Removes items if unchecked
    for (let i = 0; i < this.LocalItems.length; i++) {
      if (this.LocalItems[i].item_id === item.item_id) {
        this.LocalItems.splice(i, 1);
        break;
      }
    }
  }

  async addItems() {
    if (this.LocalItems.length <= 0) {
      return;
    }
    this.menuItemGroupInfo[this.localIndex].items.unshift(...this.LocalItems);
    console.log('this.LocalItems', this.LocalItems)
    await this.unselectAll();
    this.MenuItemGroupCRUD('put', this.menuItemGroupInfo[this.localIndex]);
    this.service.openSnackBar('Items Added');
    // this.menu()
  }

  updateGroup(i) {
    if (
      this.menuGroupModal.value.group_name === '' ||
      this.menuGroupModal.value.group_name === null ||
      this.menuGroupModal.value.group_name === undefined
    ) {
      this.service.openSnackBar('Please enter menu item group');
    } else {
      if (
        this.menuGroupModal.value.group_name_arabic === '' ||
        this.menuGroupModal.value.group_name_arabic === null ||
        this.menuGroupModal.value.group_name_arabic === undefined
      ) {
        this.service.openSnackBar('Please enter arabic menu item group');
      } else {
        if (
          this.menuGroupData.group_name === this.menuGroupModal.value.group_name &&
          this.menuGroupData.group_name_arabic === this.menuGroupModal.value.group_name_arabic
        ) {
          console.log('true');
          this.MenuItemGroupCRUD('put', this.menuGroupModal.value, '', i, () => {
            this.MenuItemGroupCRUD('get', this.menuItemGroup.value.brand_id, '?brand_id=');
          });
        } else {
          const arrayData = [];
          this.menuItemGroupInfo.map(res => {
            if (res.group_id !== this.menuGroupData.group_id) {
              arrayData.push(res);
            }
          });

          if (
            !arrayData.some(
              res =>
                res.group_name.toUpperCase().replace(/\s/g, '') ===
                this.menuGroupModal.value.group_name.toUpperCase().replace(/\s/g, '')
            )
          ) {
            if (
              !arrayData.some(
                res =>
                  res.group_name_arabic.toUpperCase().replace(/\s/g, '') ===
                  this.menuGroupModal.value.group_name_arabic.toUpperCase().replace(/\s/g, '')
              )
            ) {
              this.MenuItemGroupCRUD('put', this.menuGroupModal.value, '', i, () => {
                this.MenuItemGroupCRUD('get', this.menuItemGroup.value.brand_id, '?brand_id=');
              });
            } else {
              this.service.openSnackBar('Menu item group arabic already exists!');
            }
          } else {
            this.service.openSnackBar('Menu item group already exists!');
          }
        }
      }
    }
  }

  closeItemDetails() {
    this.showItemDetails = false;
  }

  deleteItem(i) {
    this.menuItemGroupInfo[this.localIndex].items.splice(i, 1);
    this.MenuItemGroupCRUD('put', this.menuItemGroupInfo[this.localIndex]);
    this.service.openSnackBar('Item Deleted');
  }

  deleteMenuGroup(rule, payload, query, index) {
    this.rule = rule;
    this.payload = payload;
    this.query = query;
    this.index = index;
    this.popUp();
    this.showItemDetails = false;
  }

  deleteMenuGroup1() {
    this.MenuItemGroupCRUD(this.rule, this.payload, this.query, this.index);
  }

  unselectAll() {
    this.LocalItems = [];
    this.itemData.map((data: any) => {
      data.checked = false;
    });
  }

  menuItemGroupPost(rule, payload) {
    if (
      this.menuItemGroup.value.group_name === '' ||
      this.menuItemGroup.value.group_name === null ||
      this.menuItemGroup.value.group_name === undefined
    ) {
      this.service.openSnackBar('Please enter menu item group');
    } else {
      if (
        this.menuItemGroup.value.group_name_arabic === '' ||
        this.menuItemGroup.value.group_name_arabic === null ||
        this.menuItemGroup.value.group_name_arabic === undefined
      ) {
        this.service.openSnackBar('Please enter arabic menu item group');
      } else {
        if (
          !this.menuItemGroupInfo.some(
            res =>
              res.group_name.toUpperCase().replace(/\s/g, '') ===
              this.menuItemGroup.value.group_name.toUpperCase().replace(/\s/g, '')
          )
        ) {
          if (
            !this.menuItemGroupInfo.some(
              res =>
                res.group_name_arabic.toUpperCase().replace(/\s/g, '') ===
                this.menuItemGroup.value.group_name_arabic.toUpperCase().replace(/\s/g, '')
            )
          ) {
            this.MenuItemGroupCRUD(rule, payload);
          } else {
            this.service.openSnackBar('Menu item group arabic already exists!');
          }
        } else {
          this.service.openSnackBar('Menu item group already exists!');
        }
      }
    }
  }

  MenuItemGroupCRUD(rule, payload = '', query = '', index = 1, callback?) {
    console.log("Payload 123", payload)
    this.service.MenuItemGroup(rule, payload, query).subscribe(
      result => {
        if (rule === 'get') {
          this.menuItemGroupInfo = result.body;
          if (this.menuItemGroupInfo.length > 0) {
            if (this.menuItemGroupInfo.length > 14) {
              this.showPagination = true;
            } else {
              this.showPagination = false;
            }
            this.showAvailable = false;
          } else {
            this.showAvailable = true;
            this.showPagination = false;
          }
        }
        if (rule === 'delete') {
          this.menuItemGroupInfo.splice(index, 1);
          this.MenuItemGroupCRUD('get', this.menuItemGroup.value.brand_id, '?brand_id=');
          this.popUpOff();
          this.service.openSnackBar('Menu Item Group Deleted');
        }
        if (rule === 'post') {
          this.MenuItemGroupCRUD('get', this.menuItemGroup.value.brand_id, '?brand_id=');
          this.menuItemGroup.patchValue({ group_name: '', group_name_arabic: '' });
          this.service.openSnackBar('Menu Item Group Added');
        }
        if (rule === 'put') {
          this.groupID = '';
          this.MenuItemGroupCRUD('get', this.menuItemGroup.value.brand_id, '?brand_id=');
        }
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );

  }
  grp(i) {
    console.log(i)
  }
  // menu() {

  //   this.service.newMenu('get', '', `?verbose=true&brand_id=${this.menuItemGroup.value.brand_id}`).subscribe(res => {
  //     console.log("res for menu", res)
  //     this.menugrp = res.body;
  //     this.menuName = true;
  //     this.filteredData = this.menugrp.filter((ele: any) => {
  //       if (ele.menu_data.length > 0) {
  //         return ele
  //       }
  //     })
  //     let item = { item_name: "abc" };
  //     console.log("this.updateMenu", this.updateMenu)

  //     for (let i = 0; i < this.filteredData.length; i++) {
  //       console.log("entry for i", this.filteredData[i])
  //       for (let j = 0; j < this.filteredData[i].menu_data.length; j++) {
  //         console.log("entry for j")
  //         if (this.filteredData[i].menu_data[j].group_name == this.updateMenu.group_name) {
  //           console.log("entry")
  //           // var ids = new Set(this.filteredData[i].menu_data[j].items.map(d => d.item_id));
  //           // var merged = [...this.filteredData[i].menu_data[j].items, ...this.updateMenu.items.filter(d => !ids.has(d.item_id))];
  //           // this.filteredData[i].menu_data[j].items.push(item)
  //           this.updateMenu.items.forEach(ele => {
  //             let duplicate = this.filteredData[i].menu_data[j].items.filter(obj => {
  //               return obj.item_id == ele.item_id;
  //             });
  //             if (duplicate.length > 0) {
  //             } else {
  //               this.filteredData[i].menu_data[j].items.push(ele);
  //             }
  //             console.log("duplicate 123", this.filteredData[i].menu_data[j].items);
  //           })

  //       }
  //     }
  //       }
  //       console.log("item", this.filteredData)


  //       // if (this.menugrp.length > 0) {
  //       //   const arr = [];
  //       //   this.menugrp.forEach(ele => {
  //       //     if (ele.menu_data.length > 0) {
  //       //       arr.push(ele);
  //       //       console.log("arr", arr);
  //       //       this.filteredData = arr.filter(ele => {
  //       //         console.log(ele);
  //       //         if (ele.group_name = "Individual items") {
  //       //           return ele;
  //       //         }
  //       //       });
  //       //     }
  //       //   });
  //       //   console.log("filteredData",this.filteredData)
  //       // } else {

  //       // }
  //     }, error => {
  //       this.service.openSnackBar('Error in fetching menu data');
  //     }
  //   );
  // }

  getItem() {
    this.service.Item('get').subscribe(
      result => {
        this.itemData = result.body;
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  edit(data, index) {
    this.menuGroupData = data;
    this.groupID = data.group_id;
    this.menuGroupModal.patchValue(data);
  }

  isFieldValid(field: string) {
    return (
      (!this.menuItemGroup.get(field).valid && this.menuItemGroup.get(field).touched) ||
      (this.menuItemGroup.get(field).untouched && this.formSubmitAttempt)
    );
  }

  brandData() {
    this.service.getBrandDetails().subscribe(result => {
      this.brand = result.body;
    });
  }

  applyFilter(filter: String) {
    this.itemData = this.itemDataBackup.filter(item => {
      if (
        item.item_name
          .toString()
          .toLowerCase()
          .indexOf(filter.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
  }
}
