// const url = "http://3.92.189.126:8547/";
// const url = 'http://62.215.168.194:5001/';
// // const url = 'http://localhost:3000/';
// const url = 'http://localhost:3000/';
// const url = "http://3.92.189.126:8545/api/bo/";
//const url = "http://52.14.34.120/api/bo/"
// const url = 'http://localhost/api/bo';
// const url = "https://pointof-sale.herokuapp.com"
// const url = "http://a06ed8732784.ngrok.io/";
// const url = "http://192.168.1.39:3000/"
// var url = "http://"+ window.location.hostname;
// const auth = 'http://62.215.168.194:5002/';
// const auth = "https://auth-ccs.herokuapp.com/";
// const auth = "http://3.92.189.126:8545/api/auth/";
// const url = "http://641de5b28c0b.ngrok.io/";
//const auth = "http://52.14.34.120/api/auth/"

// const auth = "http://035a8003.ngrok.io/";
let host:any =  window.location.hostname;
const url:any = 'http://43.204.180.83/backoffice/'
const auth = 'http://43.204.180.83/auth/'

const envObject = {
  posBackOfficeServiceUrl: url,
  authServiceUrl: auth,
};

export const environment = {
  production: false,
  posBackOfficeServiceUrl: envObject.posBackOfficeServiceUrl,
  authServiceUrl: envObject.authServiceUrl,
};
